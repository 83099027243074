import * as main from './online'
import * as kovan from './kovan';
import { TConfig } from '../components'

// Todo: 这个无效
const CHAIN_ENV = process.env.REACT_APP_CHAIN_ID === '1'
  ? 'main' : 'kovan';
// const CHAIN_ENV = 'kovan'
console.log('CHAIN_ENV: ', process.env.REACT_APP_CHAIN_ID);
const output: {
  [key: string]: TConfig
} = {
  main: main,
  kovan: kovan
}

export const {
  // SASHIMI_ADDRESS,
  EXCHANGE_DELEGATE_ADDRESS,
  LP_BLOCK_TAG,
  POOLS_INFO,
  LP_DECIMAL_TENTH_POWER
} = output[CHAIN_ENV];
