import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { Button, InputNumber, notification, Spin } from 'antd'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import Card from '../../../../components/Card'
import UnlockWallet from '../../../../components/UnlockWallet'
import CoinLogo from '../../../../components/CoinLogo'
import useTokenBalance from '../../../../hooks/useTokenBalance'
import { useWeb3React } from '@web3-react/core'
import { useTokenContract, useVaultContract } from '../../../../hooks/useContract'
import { useContractHandler } from '../../../../hooks/useSashimi'
import BigNumber from 'bignumber.js'
import { VaultApyInfo, VaultInfo, VaultStrategyType } from '../../../../state/vault/actions'
import useVaultContractHandler from '../../../../state/vault/hooks'
import BalanceUtils from '../../../../fuck/formatBalance'
import { formattedPercent } from '../../../../utils'
import { LogoUtils } from '../../../../fuck/logoUtils'
import { useEtherPrice } from '../../../../state/global/hooks'
import { useBlockNumber } from '../../../../state/application/hooks'

const ButtonStyle = {
  borderRadius: '10px',
  // width: '186px',
  height: '46px',
  fontSize: '16px',
}

interface TokenPanelProps {
  vault: VaultInfo
  apyInfo: VaultApyInfo
}

const TokenPanel: React.FC<TokenPanelProps> = ({ vault, apyInfo }: TokenPanelProps) => {
  const vaultToken =
    vault.strategyType === VaultStrategyType.VAULT_STRATEGY_UNI ? vault.tokenName : `sv${vault.tokenName}`
  const vaultTokenUnit = vault.strategyType === VaultStrategyType.VAULT_STRATEGY_UNI ? 'svUNI-V2' : ''

  const lpToken = vault.tokenName
  const lpTokenUnit = vault.strategyType === VaultStrategyType.VAULT_STRATEGY_UNI ? 'UNI-V2 LP' : ''

  const { account } = useWeb3React()
  const vaultContract = useVaultContract(vault.vaultIdAddr)
  const vaultContractHandler = useVaultContractHandler(vaultContract)
  const decimals: number = vault.decimals

  const lpContract = useTokenContract(vault.lpAddress)
  const lpContractHandler = useContractHandler(lpContract)

  const block = useBlockNumber()

  // 1 tokenName == ? vaultTokenName
  const [ratio, setRatio] = useState<number>(1)
  useEffect(() => {
    if (!lpContract || !vaultContract) return

    Promise.all([vaultContract.totalSupply(), vaultContract.balance()]).then((data) => {
      const [totalSupplyOfVault, lpBalanceOfVault] = data.map((item) => new BigNumber(item.toString()))
      const ratio = lpBalanceOfVault.div(totalSupplyOfVault)
      setRatio(ratio.toNumber())
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultContract])

  // 可使用的[lp]配额是否为0
  const [allowanceIsZero, setAllowanceState] = useState<boolean>(true)
  useEffect(() => {
    lpContractHandler
      .allowance(vaultContract)
      .then((allowance) => setAllowanceState(allowance.eq(0)))
      .catch(console.error)
  }, [lpContractHandler, vaultContract])
  // 申请[lp]额度回调
  const onApproveCallback = useCallback(() => lpContractHandler.approve(vaultContract), [
    vaultContract,
    lpContractHandler,
  ])

  // 最大可充值数量、当前账户持有的 [lp] 数量
  const balanceOfLp = useTokenBalance(vault.lpAddress)
  const maximumDeposit: BigNumber = useMemo(() => {
    return balanceOfLp.div(new BigNumber(10).pow(decimals))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceOfLp, decimals, block])
  const [depositShowValue, setDepositShowValue] = useState<number>(0)

  // 最大可提取数量、当前账户持有的 [vault] 数量
  const [maximumWithdraw, setMaximumWithdraw] = useState<BigNumber>(new BigNumber(0))
  useEffect(() => {
    vaultContractHandler.balanceOf(account, decimals).then((balance) => {
      setMaximumWithdraw(balance)
    })
  }, [vaultContractHandler, account, decimals, block])
  const [withdrawValue, setWithdrawValue] = useState<number>(0)

  // 按钮状态
  const [depositButtonLoading, setDepositButtonLoading] = useState<boolean>(false)
  const [withdrawButtonLoading, setWithdrawButtonLoading] = useState<boolean>(false)

  const { now } = useEtherPrice()

  let apy = '0.00%'
  const locked = BalanceUtils.formattedNum((apyInfo?.valueInEth ?? 0) * now, true)
  let extraApy = '0.00%'
  if (apyInfo) {
    apy = formattedPercent(apyInfo.apy).value
    extraApy = formattedPercent(apyInfo.apy2).value
  }
  const below576 = useMedia('(max-width:576px)')

  return (
    <ListItem>
      <div className="coin-info">
        <CoinLogo size={48} url={vault.icon.map((address) => LogoUtils.getTokenLogo(address))} />
        <div className="name">
          <h2>{vault.tokenName} Vault</h2>
          <p>
            {vault.tokenName} {vaultTokenUnit}
          </p>
        </div>
      </div>

      <div className="help">
        <p>
          1 {vaultToken} {vaultTokenUnit} = {ratio.toFixed(8)} {lpToken} {lpTokenUnit}.
        </p>
        <p>
          Hold the left and earn more right.{' '}
          <a href="https://docs.sashimi.cool" target="_blank" rel="noopener noreferrer">
            How it works?
          </a>
        </p>
      </div>

      <div className="ipt-list">
        <div className="ipt-item">
          <div className="balance">
            My {lpToken} {lpTokenUnit}：<strong>{maximumDeposit.toFixed(8)}</strong>
          </div>
          {/* 充款输入字段 */}
          <div className="ipt-wrap">
            <InputNumber
              className="input-number"
              placeholder="0"
              max={maximumDeposit.toNumber()}
              min={0}
              value={depositShowValue}
              onChange={(value: any) => setDepositShowValue(parseFloat(value) || 0)}
            />
            <i onClick={() => setDepositShowValue(maximumDeposit.toNumber())}>MAX</i>
          </div>
          {/* 充提按钮 */}
          <div className="btn-group">
            {!account ? (
              <>
                <UnlockWallet icon={false} width={below576 ? 120 : 186} />
                <UnlockWallet icon={false} width={below576 ? 120 : 186} />
              </>
            ) : (
              <>
                {/* deposit */}
                <Spin spinning={depositButtonLoading}>
                  <Button
                    type="primary"
                    style={{
                      ...ButtonStyle,
                      width: `${below576 ? '120px' : '186px'}`,
                    }}
                    disabled={depositShowValue === 0}
                    onClick={async () => {
                      try {
                        setDepositButtonLoading(true)
                        // 如果没有额度, 去申请
                        if (allowanceIsZero) {
                          const result = await onApproveCallback()
                          if (!result) {
                            notification.error({
                              message: 'Approved failed!',
                            })
                          }
                        }

                        let depositAmount = depositShowValue.toString()

                        if (new BigNumber(depositShowValue).gt(maximumDeposit)) {
                          depositAmount = maximumDeposit.toString()
                        }

                        await vaultContractHandler.deposit(depositAmount, decimals)
                        setDepositShowValue(0)
                      } catch (e) {
                        console.log('deposit error: ', e)
                      } finally {
                        setDepositButtonLoading(false)
                      }
                    }}
                  >
                    <DownloadOutlined style={{ fontSize: '20px' }} />
                    <i>Deposit</i>
                  </Button>
                </Spin>
                {/* withdraw */}
                <Spin spinning={withdrawButtonLoading}>
                  <Button
                    className="fees"
                    type="primary"
                    style={ButtonStyle}
                    disabled={withdrawValue === 0}
                    onClick={async () => {
                      try {
                        setWithdrawButtonLoading(true)
                        if (allowanceIsZero) {
                          const result = await onApproveCallback()
                          if (!result) {
                            notification.error({
                              message: 'Approved failed!',
                            })
                          }
                        }

                        let withdrawAmount = withdrawValue.toString()
                        if (new BigNumber(withdrawValue).gt(maximumWithdraw)) {
                          withdrawAmount = maximumWithdraw.toString()
                        }

                        await vaultContractHandler.withdraw(withdrawAmount, decimals)
                        setWithdrawValue(0)
                      } catch (e) {
                        console.log('withdraw error: ', e)
                      } finally {
                        setWithdrawButtonLoading(false)
                      }
                    }}
                  >
                    <UploadOutlined style={{ fontSize: '20px' }} />
                    <div>
                      Withdraw<p>(0.5% fee)</p>
                    </div>
                  </Button>
                </Spin>
              </>
            )}
          </div>
        </div>
        {/* 提款输入字段 */}
        <div className="ipt-item" style={{ marginTop: '20px' }}>
          <div className="balance">
            My {vaultToken} {vaultTokenUnit}：<strong>{maximumWithdraw.toFixed(8)}</strong>
          </div>
          <div className="ipt-wrap">
            <InputNumber
              className="input-number"
              placeholder="0"
              max={maximumWithdraw.toNumber()}
              min={0}
              value={withdrawValue}
              onChange={(value: any) => {
                setWithdrawValue(parseFloat(value) || 0)
              }}
            />
            <i onClick={() => setWithdrawValue(maximumWithdraw.toNumber())}>MAX</i>
          </div>
        </div>
      </div>

      <div className="detailed-lists">
        <div className="item">
          <p>Total Value Locked</p>
          <h2>{locked}</h2>
        </div>
        <div className="item">
          <p>APY</p>
          <h2>
            <span>
              <img src={LogoUtils.getTokenLogo(vault.apyIcon[0])} alt="" />
              {apy}
            </span>
            <img src={LogoUtils.getTokenLogo(vault.apyIcon[1])} alt="" />
            <strong>{extraApy}</strong>
          </h2>
        </div>
      </div>
    </ListItem>
  )
}

const ListItem = styled(Card)`
  box-sizing: border-box;
  padding: 26px 20px 22px 20px;
  /* margin: 0 17px 30px 17px; */
  /* min-width: 446px; */
  /* flex: 1; */
  height: 500px;
  flex: initial;
  .coin-info {
    display: flex;
    align-items: center;
    .name {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      h2 {
        color: #333;
        font-size: 18px;
        line-height: 24px;
      }
      p {
        margin-top: 4px;
        color: #696e77;
        line-height: 20px;
      }
    }
  }

  .help {
    box-sizing: border-box;
    margin-top: 16px;
    width: 100%;
    padding: 7px 0;
    background: rgba(235, 89, 26, 0.1);
    color: #333;
    font-size: 12px;
    text-align: center;
    p {
      line-height: 18px;
    }
  }

  .ipt-list {
    margin-top: 30px;
    .ipt-item {
      .balance {
        line-height: 20px;
        color: #696e77;
        strong {
          font-size: 16px;
          color: #121212;
          font-weight: 500;
        }
      }
      .ipt-wrap {
        height: 46px;
        margin-top: 10px;
        position: relative;
        i {
          position: absolute;
          top: 12px;
          right: 24px;
          color: #0091ff;
          line-height: 20px;
          font-style: normal;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .input-number {
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 7px;
        width: 100%;
        box-sizing: border-box;
        outline: none;
        overflow: hidden;
        font-size: 18px;
      }

      .btn-group {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        span {
          &[role='img'] {
            margin-right: 16px;
          }
        }
        i {
          font-style: normal;
        }
        .fees {
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 18px;
          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .detailed-lists {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .item {
      p {
        font-size: 12px;
        color: #696e77;
        line-height: 16px;
      }
      h2 {
        font-size: 18px;
        color: #121212;
        line-height: 20px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 576px) {
          font-size: 14px;
        }
        img {
          margin-right: 10px;
          width: 23px;
          &:first-child {
            width: 20px;
          }
        }
        strong {
          color: #eb591a;
        }
        span {
          display: flex;
          align-items: center;
          padding-right: 10px;
        }
      }
    }
  }
`
export default TokenPanel
