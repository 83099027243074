import React from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import Card from '../../../../components/Card'
import { RightCircleOutlined } from '@ant-design/icons'
const WorksStep: React.FC = () => {
  const below576 = useMedia('(max-width:576px)')
  return (
    <Wrap>
      <StyledCard>
        <StyledHeader>How saVault Works?</StyledHeader>
        <Steps>
          <p className="desc">
            saVault is a function that helps you increams your assets. Theoretically, when you deposit the Token, the
            system will automatically sell the rewards from your Token output and convert it to get more Token. All will
            be done automatically by smart contracts without manual intervention. System will charge 0.5% Withdrawal Fee
            and 4.5% Performance Fee of the profit in this process, and you will get SASHIMI rewards from the farm by
            staking svToken. Your Token is safe.
          </p>
          <p className="title">For example you should do it like this step:</p>
          <div className="steps">
            <div className="item">
              <h2>
                1<span>st</span>
              </h2>
              <p>
                Deposit your Token to <br />
                get svToken here.
              </p>
            </div>
            {!below576 && <RightCircleOutlined className="arrow" style={{ fontSize: '34px', color: '#f5ac8d' }} />}
            <div className="item">
              <h2>
                2<span>nd</span>
              </h2>
              <p>
                After that, farm and get rich <br /> SASHIMI rewards.
              </p>
            </div>
            {!below576 && <RightCircleOutlined className="arrow" style={{ fontSize: '34px', color: '#f5ac8d' }} />}
            <div className="item">
              <h2>
                3<span>th</span>
              </h2>
              <p>
                Redeem your Token and
                <br /> earn more Token.
              </p>
            </div>
          </div>
        </Steps>
      </StyledCard>
    </Wrap>
  )
}
const Wrap = styled.div`
  margin-top: 34px;
  box-sizing: border-box;
  padding: 0 40px 30px 40px;
  @media screen and (max-width: 576px) {
    padding: 0 16px 30px 16px;
  }
`

const StyledCard = styled(Card)`
  padding: 0 20px;
`
const StyledHeader = styled.div`
  line-height: 70px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`
const Steps = styled.div`
  box-sizing: border-box;
  padding: 0 54px 70px 54px;
  @media screen and (max-width: 576px) {
    padding: 0 0px 70px 0px;
  }
  .desc {
    line-height: 16px;
    color: #696e77;
    margin-top: 12px;
  }
  .title {
    line-height: 20px;
    color: #333;
    margin-top: 16px;
    font-weight: 500;
  }

  .steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 65px;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 220px;
      height: 160px;
      background: rgba(252, 133, 95, 0.05);
      border-radius: 3px;
      border: 1px dashed #eb591a;
      color: #eb591a;
      h2 {
        line-height: 42px;
        font-size: 36px;
        color: #eb591a;
        span {
          font-size: 24px;
        }
      }
      p {
        margin-top: 18px;
        line-height: 20px;
        text-align: center;
      }
    }
    .arrow {
      margin: 0 34px;
    }
  }
`
export default WorksStep
