import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row, Tooltip } from 'antd'
import '../Compensation.less';
import defi from '../../../assets/images/logo.svg'
import { RedeemPanelProps, TUserInfo } from './index'
import { useActiveWeb3React } from '../../../hooks'
import LoginButton from './LoginButton'
import { useExchangeContract } from '../hooks/useContract'
import { EXCHANGE_DELEGATE_ADDRESS, LP_DECIMAL_TENTH_POWER } from '../config/config'
import BigNumber from 'bignumber.js'
import { CompensationContext } from '../context/useCompensation'
import { useBlockNumber } from '../../../state/application/hooks'
import CountStart from './CountStart'

const RedeemPanel: React.FC<RedeemPanelProps>
  = ({
       panelInfo,
       setPanelInfo
     }) => {
  const { account } = useActiveWeb3React();
  const {redeemTimeType, countdown} = useContext(CompensationContext);
  const block = useBlockNumber();

  const [loading, setLoading] = useState<boolean>(false);
  const [accSashimi, setAccSashimi] = useState<BigNumber>();
  const [pendingSashimi, setPendingSashimi] = useState<BigNumber>();
  const [canBeRedeemPools, setCanBeRedeemPools] = useState<Array<number>>([]);
  const exchangeContract = useExchangeContract(EXCHANGE_DELEGATE_ADDRESS);

  useEffect(() => {
    if (!account || !exchangeContract) {
      return;
    }
    const fetchData = async function () {
      const pIds = [0, 1, 2, 3];
      const [userInfos, pendingInfos]: [Array<TUserInfo>, Array<string | number>] = await Promise.all([
        exchangeContract.getUserInfos(pIds, account),
        exchangeContract.getPendingSashimi(pIds, account)
      ]);

      let accSashimiTemp = new BigNumber(0);
      let pendingSashimiTemp = new BigNumber(0);
      const canBeRedeemPools: Array<number> = [];
      userInfos.map(userInfo => {
        accSashimiTemp = accSashimiTemp.plus(userInfo.accSashimi.toString());
      });
      pendingInfos.map((pendingInfo, index) => {
        // console.log(`pendingInfos: ${index}`, pendingInfo.toString());
        pendingSashimiTemp = pendingSashimiTemp.plus(pendingInfo.toString());
        if (!(new BigNumber(pendingInfo.toString()).eq(0))) {
          canBeRedeemPools.push(index);
        }
      });

      // console.log('canBeRedeemPools:', canBeRedeemPools);
      // console.log('userInfos: ', userInfos, accSashimiTemp.toFormat(), pendingInfos, pendingInfos[0].toString(), pendingSashimiTemp.toString(4));
      setAccSashimi(accSashimiTemp);
      setPendingSashimi(pendingSashimiTemp);
      setCanBeRedeemPools(canBeRedeemPools);
    }
    fetchData();
  }, [account, exchangeContract, block]);

  return (
    <div className={`redeem ${panelInfo.right && !panelInfo.information ? null : 'display-none'}`}>
      <div className="info-logo-container">
        <div className="sushi-big">
          <img className="sashimi-big" src={defi} alt="sashimi-logo"/>
        </div>
      </div>

      <div>
        <CountStart
          startTime={countdown.startTime}
          endTime={countdown.endTime}
        />
      </div>

      <Row justify="space-between"
           align="middle"
           className="balance-container balance-container-right">
        <Col className="option-container">
          <div className="sashimi-middle-bg" >
            <img className="sashimi-middle" src={defi} alt="sashimi-logo"/>
          </div>
          <span className="option-value">Unclaimed SASHIMI</span>
        </Col>
        <Col className="balance-context">
          <Tooltip title={pendingSashimi?.div(LP_DECIMAL_TENTH_POWER).toFormat()}>
            {pendingSashimi ? pendingSashimi.div(LP_DECIMAL_TENTH_POWER).toFormat(4) : '0'}
          </Tooltip>
        </Col>
      </Row>
      <div className="staked">{accSashimi ? accSashimi.div(LP_DECIMAL_TENTH_POWER).toFormat() : '0'} SASHIMI Claimed </div>

      <div className="plan-detail redeem-margin-fix">
        <span onClick={() => {
          setPanelInfo({
            left: false,
            information: true,
            right: true
          });
        }}>SASHIMI Distribution Plan</span>
      </div>
      {
        account
          ? <Button
            className="button-full-width"
            type="primary"
            disabled={
              !account
              || !['ENDED', 'TO_END'].includes(redeemTimeType || '')
              || pendingSashimi?.eq(0)
            }
            loading={loading}
            onClick={async() => {
              if (!account || !exchangeContract) {
                return;
              }
              setLoading(true);
              // Todo: 等鹏哥那边批量接口
              try {
                // const txResult = await exchangeContract.accSashimi(0);
                const txResult = await exchangeContract.batchAccSashimi(canBeRedeemPools);
                await txResult.wait();
                setLoading(false);
              } catch {
                setLoading(false);
              }
            }}
          >Claim SASHIMI </Button>
          : <LoginButton/>
      }

    </div>
  )
}

export default RedeemPanel;
