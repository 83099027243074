import React, { useState, useCallback, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import UnstakeImg from '../../../../assets/images/unstake.png'
import useStakedBalance from '../../../../hooks/useStakedBalance'
import BalanceUtils from '../../../../fuck/formatBalance'
import DepositModal from '../../../../components/DepositModal'
import WithdrawModal from '../../../../components/WithdrawModal'
import { Contract } from 'ethers'
import { useContractHandler, useUserBalanceByContract } from '../../../../hooks/useSashimi'
import { useMasterChefContract } from '../../../../hooks/useContract'
import CountUpAni from '../../../../components/CountUpAni'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FarmPoolInfo } from '../../../../state/farm-pool/actions'

const ButtonStyle = {
  borderRadius: '10px',
  height: '46px',
  fontSize: '18px'
}

interface StakeProps {
  lpContract: Contract | null
  poolInfo: FarmPoolInfo | undefined
}

export const Stake: React.FC<StakeProps> = ({ lpContract, poolInfo }: StakeProps) => {
  const lpDecimal = poolInfo?.lpDecimal
  const poolId = poolInfo?.pid

  const [requestedApproval, setRequestedApproval] = useState(false)
  const [isDepositOpen, setIsDepositOpen] = useState<boolean>(false)
  const [isWithdrawOpen, setIsWithdrawOpen] = useState<boolean>(false)

  const lpContractHandler = useContractHandler(lpContract)

  const masterChefContract = useMasterChefContract()
  const masterChefHandler = useContractHandler(masterChefContract)

  const [allowance, setAllowance] = useState(new BigNumber(0))
  useEffect(() => {
    lpContractHandler
      .allowance(masterChefContract)
      .then(setAllowance)
      .catch(console.error)
  }, [lpContractHandler, masterChefContract, lpContract])

  // on stake
  const onStake = useCallback(
    async (amount: string) => {
      if (lpDecimal === undefined || poolId === undefined) return

      await masterChefHandler.stake(poolId, amount, lpDecimal)
    },
    [masterChefHandler, poolId, lpDecimal]
  )
  // unstake
  const onUnStake = useCallback(
    async (amount: string) => {
      if (lpDecimal === undefined || poolId === undefined) return

      await masterChefHandler.unStake(poolId, amount, lpDecimal)
    },
    [masterChefHandler, poolId, lpDecimal]
  )

  const stakedBalance: BigNumber = useStakedBalance(poolId)
  // max
  const tokenBalance: BigNumber = useUserBalanceByContract(lpContract)

  // 批准
  const handleApprove = useCallback(async () => {
    if (lpContractHandler === null || masterChefContract === null) return
    try {
      setRequestedApproval(true)
      await lpContractHandler.approve(masterChefContract)
    } catch (e) {
      console.log(e)
    } finally {
      setRequestedApproval(false)
    }
  }, [lpContractHandler, masterChefContract, setRequestedApproval])

  return (
    <>
      <DepositModal
        onDismiss={() => {
          setIsDepositOpen(false)
        }}
        isOpen={isDepositOpen}
        max={tokenBalance}
        onConfirm={onStake}
        tokenName={poolInfo?.lpSymbol}
        decimal={lpDecimal}
      />
      <WithdrawModal
        isOpen={isWithdrawOpen}
        onDismiss={() => {
          setIsWithdrawOpen(false)
        }}
        max={stakedBalance}
        onConfirm={onUnStake}
        tokenName={poolInfo?.lpSymbol}
        decimal={lpDecimal}
      />
      <div className="item-wrap">
        <div className="item">
          <div className="img">
            <img style={{ width: '32px' }} src={UnstakeImg} alt="" />
          </div>
          <h2>
            {/* {BalanceUtils.getBalanceNumber(stakedBalance).toLocaleString('currency', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4
            })} */}
            <CountUpAni num={BalanceUtils.getBalanceNumber(stakedBalance)} />
          </h2>
          <p>{poolInfo?.lpSymbol} Tokens Staked</p>
        </div>
        {!allowance.toNumber() ? (
          <div className="btn">
            <Button
              style={{ width: '360px', overflow: 'hidden', textOverflow: 'ellipsis', ...ButtonStyle }}
              disabled={requestedApproval}
              type="primary"
              onClick={() => {
                if (handleApprove) handleApprove()
              }}
            >{`Approve ${poolInfo?.lpSymbol}`}</Button>
          </div>
        ) : (
          <div className="btn">
            <div style={{ width: '280px' }}>
              <Button
                disabled={stakedBalance.eq(new BigNumber(0))}
                type="primary"
                style={{ width: '282px', ...ButtonStyle }}
                onClick={() => {
                  setIsWithdrawOpen(true)
                }}
              >
                Unstake
              </Button>
            </div>

            <Button
              onClick={() => {
                setIsDepositOpen(true)
              }}
              icon={<PlusOutlined />}
              type="primary"
              style={{ width: '68px', ...ButtonStyle }}
            />
          </div>
        )}
      </div>
    </>
  )
}
