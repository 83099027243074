//核心的合约地址
import { LpSourceType } from '../../state/farm-pool/actions'
import { VaultStrategyType } from '../../state/vault/actions'
import * as tokenIcons from './tokenIcons';
export const contractAddresses = {
  sashimi: '0xC28E27870558cF22ADD83540d2126da2e4b464c2',
  sashimiBar: '0x6ed306DbA10E6c6B20BBa693892Fac21f3B91977',
  sashimiRouter: '0xe4FE6a45f354E845F954CdDeE6084603CEDB9410',
  sashimiRouterV2: '0xe4fe6a45f354e845f954cddee6084603cedb9410',
  investment: '0x3F966FA1c0606e19047ed72068D2857677E07EF4',
  masterChef: '0x1DaeD74ed1dD7C9Dabbe51361ac90A69d851234D',
  vaultIdAddr: '0x56BB940D92AE9a45EBDc77e94C28B960CffD6168',
}
export const mainContractAddr = {
  sashimi: {
    42: '0x89d5F2E52D36b85e72e25e8fE46d709D445c03Fc', // aelf sushi new one 9.9
    1: '0xC28E27870558cF22ADD83540d2126da2e4b464c2' // aelf sashimi
  },
  sashimiBar: {
    42: '0xfaC2681cB05Ba08De504e7FDBc2186B22d868f2A', // aelf sushi new one 9.18
    1: '0x6ed306DbA10E6c6B20BBa693892Fac21f3B91977' // aelf sashimi
  },
  sashimiRouter: {
    42: '0xe4FE6a45f354E845F954CdDeE6084603CEDB9410',
    1: '0xe4FE6a45f354E845F954CdDeE6084603CEDB9410'
  },
  investment: {
    42: '0x3F966FA1c0606e19047ed72068D2857677E07EF4',
    1: '0x3F966FA1c0606e19047ed72068D2857677E07EF4'
  },
  masterChef: {
    42: '0x042036D6F0b5C246aA07Dd9688b46cf86Bd179C3', // aelf sushi new one 9.9
    1: '0x1daed74ed1dd7c9dabbe51361ac90a69d851234d' // aelf master
  }
}

export const waitingInfo = {
  waitingPool: [38, 39, 40],
  startTime: 1603378800000
}

// 0928 UNI LP lpBar
const xLpBarSupportedPools = [
  // xLP supported start
  {
    pid: 26,
    name: 'Double happiness!',
    lpSymbol: '\xa0DAI-ETH UNI-V2 LP', // and blank to make it different with the normal farm symbol
    icon: '🍗',
    lpSourceType: LpSourceType.LPBAR_FARM,
    lpCoinUrls: ['0x6B175474E89094C44Da98b954EedeAC495271d0F', '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2']
  },
  // 0925
  {
    pid: 27,
    name: 'Double happiness!',
    lpSymbol: '\xa0USDC-ETH UNI-V2 LP', // and blank to make it different with the normal farm symbol
    icon: '🍖',
    lpSourceType: LpSourceType.LPBAR_FARM,
    lpCoinUrls: ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2']
  },
  {
    pid: 28,
    name: 'Double happiness!',
    lpSymbol: '\xa0ETH-USDT UNI-V2 LP', // and blank to make it different with the normal farm symbol
    icon: '🥩',
    lpSourceType: LpSourceType.LPBAR_FARM,
    lpCoinUrls: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', '0xdAC17F958D2ee523a2206206994597C13D831ec7']
  },
  {
    pid: 29,
    name: 'Double happiness!',
    lpSymbol: '\xa0WBTC-ETH UNI-V2 LP', // and blank to make it different with the normal farm symbol
    icon: '🥓',
    lpSourceType: LpSourceType.LPBAR_FARM,
    lpCoinUrls: ['0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2']
  }
]
const newNormalPool1124 = [
  {
    pid: 41,
    name: 'AAVE',
    lpSymbol: 'AAVE-ETH SALP LP',
    icon: '🍍',
  },
  {
    pid: 42,
    name: 'BUSD',
    lpSymbol: 'BUSD-ETH SALP LP',
    icon: '🥭',
  },
  {
    pid: 43,
    name: 'OMG',
    lpSymbol: 'OMG-ETH SALP LP',
    icon: '🍑',
  },
  {
    pid: 44,
    name: 'SUSHI',
    lpSymbol: 'SUSHI-ETH SALP LP',
    icon: '🍒',
  },
  {
    pid: 45,
    name: 'CRV',
    lpSymbol: 'CRV-ETH SALP LP',
    icon: '🍈',
  },
  {
    pid: 46,
    name: 'COMP',
    lpSymbol: 'COMP-ETH SALP LP',
    icon: '🍓',
  },
  {
    pid: 47,
    name: 'TUSD',
    lpSymbol: 'TUSD-ETH SALP LP',
    icon: '🍇',
  },
  {
    pid: 48,
    name: 'SXP',
    lpSymbol: 'SXP-ETH SALP LP',
    icon: '🍉',
  },
  {
    pid: 49,
    name: 'ELF',
    lpSymbol: 'ELF-USDT SALP LP',
    icon: '🍌',
    //lpSourceType: LpSourceType.VAULT_FARM,
    uniV2Pivot: true,
    uniV2LPAddress: '0x490ccb3c835597ff31e525262235487f9426312b', // ETH-USDT
    sashimiPlateInfo: {
      mainTokenIndex: 1, // eg. DAI-ETH, DAI-> 0, ETH-DAI, DAI-> 1
      tokensDecimal: [18, 6],
      type: 10, // for normal pool
    },
  },
  {
    pid: 50,
    name: 'WBTC',
    lpSymbol: 'SASHIMI-WBTC SALP LP',
    icon: '🍋',
  },
  {
    pid: 51,
    name: 'DAI',
    lpSymbol: 'SASHIMI-DAI SALP LP',
    icon: '🍊',
  },
  {
    pid: 52,
    name: 'UNI',
    lpSymbol: 'SASHIMI-UNI SALP LP',
    icon: '🍐',
  },
  {
    pid: 53,
    name: 'SUSHI',
    lpSymbol: 'SASHIMI-SUSHI SALP LP',
    icon: '🍎',
  },
  {
    pid: 54,
    name: 'YFI',
    lpSymbol: 'SASHIMI-YFI SALP LP',
    icon: '🍏',
  },
];

const newVaultPool1021 = [
  {
    pid: 38,
    name: 'SashimiPlate Party!',
    lpSymbol: 'svDAI',
    icon: '👨‍👦',
    lpSourceType: LpSourceType.VAULT_FARM
  },
  {
    pid: 39,
    name: 'SashimiPlate Party!',
    lpSymbol: 'svUSDT',
    icon: '👨‍👧',
    lpSourceType: LpSourceType.VAULT_FARM
  },
  {
    pid: 40,
    name: 'SashimiPlate Party!',
    lpSymbol: 'svUSDC',
    icon: '👩‍👦',
    lpSourceType: LpSourceType.VAULT_FARM
  }
]

const newVaultPool1014 = [
  {
    pid: 34,
    name: 'SashimiPlate Party!',
    lpSymbol: 'DAI-ETH svUNI-V2',
    icon: '👨‍👩‍👧',
    lpSourceType: LpSourceType.VAULT_FARM
  },
  {
    pid: 35,
    name: 'SashimiPlate Party!',
    lpSymbol: 'USDC-ETH svUNI-V2',
    icon: '👨‍👨‍👦',
    lpSourceType: LpSourceType.VAULT_FARM
  },
  {
    pid: 36,
    name: 'SashimiPlate Party!',
    lpSymbol: 'ETH-USDT svUNI-V2',
    icon: '👨‍👨‍👧',
    lpSourceType: LpSourceType.VAULT_FARM
  },
  {
    pid: 37,
    name: 'SashimiPlate Party!',
    lpSymbol: 'WBTC-ETH svUNI-V2',
    icon: '👩‍👩‍👦',
    lpSourceType: LpSourceType.VAULT_FARM
  }
]

const newNormalPool0939 = [
  {
    pid: 2,
    name: 'Donald DAI',
    lpSymbol: 'DAI-ETH SALP LP',
    icon: '🦆'
  }
]

const newNormalPools = [
  {
    pid: 30,
    name: 'GAT love SASHIMI!',
    lpSymbol: 'GAT-SASHIMI SALP LP',
    icon: '🍭'
  },
  {
    pid: 31,
    name: 'Global Awards Token!',
    lpSymbol: 'GAT-ETH SALP LP',
    icon: '🍯'
  },
  {
    pid: 32,
    name: 'Huobi Token!',
    lpSymbol: 'HT-ETH SALP LP',
    icon: '🥜'
  },
  {
    pid: 33,
    name: 'Uniswap!',
    lpSymbol: 'UNI-ETH SALP LP',
    icon: '🦄'
  }
]

const newNormalPool0224 = [
  {
    pid: 56,
    name: 'renBTC',
    lpSymbol: 'renBTC-USDT SALP LP',
    lpAddresses: '0xB50C6C684305cEC944d8BD4b7CE9e4a315aA4793',
    icon: '🍌',
    uniV2Pivot: true,
    uniV2LPAddress: '0xB50C6C684305cEC944d8BD4b7CE9e4a315aA4793', // renBTC-USDT
    sashimiPlateInfo: {
      mainTokenIndex: 1, // eg. DAI-ETH, DAI-> 0, ETH-DAI, DAI-> 1
      tokensDecimal: [18, 6],
      type: 10, // for normal pool
    },
  },
  {
    pid: 57,
    name: 'WBTC',
    lpSymbol: 'WBTC-USDT SALP LP',
    lpAddresses: '0x5c5CAb005510F72eB23c332848B0C7D26E3078Ea',
    icon: '🍌',
    uniV2Pivot: true,
    uniV2LPAddress: '0x5c5CAb005510F72eB23c332848B0C7D26E3078Ea', // WBTC-USDT
    sashimiPlateInfo: {
      mainTokenIndex: 1, // eg. DAI-ETH, DAI-> 0, ETH-DAI, DAI-> 1
      tokensDecimal: [18, 6],
      type: 10, // for normal pool
    },
  }
]

/** ===================================================================================
 *  farm-pools
 *  ===================================================================================
 */
// 0928 Normal Farm -> SASHIMI LP
export const supportedPools = [
  ...xLpBarSupportedPools,
  // xLP supported end
  {
    pid: 9,
    name: 'Sashimi Party!',
    lpSymbol: 'SASHIMI-ETH SALP LP',
    icon: '🍣'
  },
  ...newNormalPool1124,
  ...newVaultPool1021,
  ...newVaultPool1014,
  ...newNormalPool0939,
  ...newNormalPools,
  ...newNormalPool0224,
  // 0917
  {
    pid: 22,
    name: 'HT love Sashimi!',
    lpSymbol: 'HT-SASHIMI SALP LP',
    icon: '🥂'
  },
  {
    pid: 23,
    name: 'USDT love Sashimi!',
    lpSymbol: 'USDT-SASHIMI SALP LP',
    icon: '🍻'
  },
  {
    pid: 24,
    name: 'USDC love Sashimi!',
    lpSymbol: 'USDC-SASHIMI SALP LP',
    icon: '🍷'
  },

  // old
  {
    pid: 10,
    name: 'elf love Sashimi!🧝🍱',
    lpSymbol: 'SASHIMI-ELF SALP LP',
    icon: '🍱'
  },
  {
    pid: 25,
    name: 'MX!',
    lpSymbol: 'MXC-ETH SALP LP',
    icon: '🌱'
  },
  // SASHIMI new
  {
    pid: 12,
    name: 'GT love Sashimi!',
    lpSymbol: 'GT-SASHIMI SALP LP',
    icon: '🍙'
  },
  {
    pid: 13,
    name: 'LRC love Sashimi!',
    lpSymbol: 'LRC-SASHIMI SALP LP',
    icon: '🍘'
  },
  {
    pid: 14,
    name: 'KNC love Sashimi!',
    lpSymbol: 'KNC-SASHIMI SALP LP',
    icon: '🍥'
  },
  {
    pid: 15,
    name: 'REN love Sashimi!',
    lpSymbol: 'REN-SASHIMI SALP LP',
    icon: '🥠'
  },
  {
    pid: 16,
    name: 'YFII love Sashimi!',
    lpSymbol: 'YFII-SASHIMI SALP LP',
    icon: '🦪'
  },
  // SASHIMI End

  // New ETH
  {
    pid: 17,
    name: 'Gate Token',
    lpSymbol: 'GT-ETH SALP LP',
    icon: '🍝'
  },
  {
    pid: 18,
    name: 'LRC',
    lpSymbol: 'LRC-ETH SALP LP',
    icon: '🍜'
  },
  {
    pid: 19,
    name: 'KNC',
    lpSymbol: 'KNC-ETH SALP LP',
    icon: '🍲'
  },
  {
    pid: 20,
    name: 'REN',
    lpSymbol: 'REN-ETH SALP LP',
    icon: '🍛'
  },
  {
    pid: 21,
    name: 'YFII',
    lpSymbol: 'YFII-ETH SALP LP',
    icon: '🍳'
  },

  // ETH Start
  {
    pid: 7,
    name: 'elf garden 🧝🧝‍♂️🧝‍♀',
    lpSymbol: 'ELF-ETH SALP LP',
    icon: '🧝'
  },
  {
    pid: 8,
    name: 'BTC Satoshi',
    lpSymbol: 'WBTC-ETH SALP LP',
    icon: '₿'
  },

  // Others
  {
    pid: 0,
    name: 'Tether Turtle',
    lpSymbol: 'ETH-USDT SALP LP',
    icon: '🐢'
  },
  {
    pid: 1,
    name: 'Circle Snail',
    lpSymbol: 'USDC-ETH SALP LP',
    icon: '🐌'
  },
  {
    pid: 3,
    name: 'YFI Whale',
    lpSymbol: 'YFI-ETH SALP LP',
    icon: '🐋'
  },
  {
    pid: 4,
    name: 'Aave Boar',
    lpSymbol: 'LEND-ETH SALP LP',
    icon: '🐗'
  },
  {
    pid: 5,
    name: 'Toadie Marine',
    lpSymbol: 'LINK-ETH SALP LP',
    icon: '🐸'
  },
  {
    pid: 6,
    name: 'Synthetic Snake',
    lpSymbol: 'SNX-ETH SALP LP',
    icon: '🐍'
  },
  // Burn
  {
    pid: 11,
    name: 'Reduce Mint',
    lpSymbol: 'Nothing',
    icon: '🔥'
  },
  // UNI, Hidden
  {
    pid: 101,
    lpAddresses: '0xd3d2e2692501a5c9ca623199d38826e513033a17', // 0x00 actual
    name: 'Hidden',
    lpSymbol: 'Hidden',
    icon: '🔥',
    hide: true
  }
]

/** ===================================================================================
 *  vaults
 *  ===================================================================================
 */

export const vaults = [
  {
    vaultIdAddr: '0x56BB940D92AE9a45EBDc77e94C28B960CffD6168',
    tokenName: 'DAI-ETH',
    lpTokenName: 'DAI-ETH',
    strategyType: VaultStrategyType.VAULT_STRATEGY_UNI,
    icon: ['0x6B175474E89094C44Da98b954EedeAC495271d0F', '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
    apyIcon: ['0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', '0xc28e27870558cf22add83540d2126da2e4b464c2']
  },
  {
    vaultIdAddr: '0xc5D00a4E730fC2e1C77764A74E5F1308A460de7F',
    tokenName: 'USDC-ETH',
    lpTokenName: 'USDC-ETH',
    strategyType: VaultStrategyType.VAULT_STRATEGY_UNI,
    icon: ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
    apyIcon: ['0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', '0xc28e27870558cf22add83540d2126da2e4b464c2']
  },
  {
    vaultIdAddr: '0x8E95bc97B0C1B88Aa8708206C85c06299F778648',
    tokenName: 'ETH-USDT',
    lpTokenName: 'ETH-USDT',
    strategyType: VaultStrategyType.VAULT_STRATEGY_UNI,
    icon: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', '0xdAC17F958D2ee523a2206206994597C13D831ec7'],
    apyIcon: ['0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', '0xc28e27870558cf22add83540d2126da2e4b464c2']
  },
  {
    vaultIdAddr: '0x81885d776D2963941ec7434F30D61B851E9697FA',
    tokenName: 'WBTC-ETH',
    lpTokenName: 'WBTC-ETH',
    strategyType: VaultStrategyType.VAULT_STRATEGY_UNI,
    icon: ['0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'],
    apyIcon: ['0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', '0xc28e27870558cf22add83540d2126da2e4b464c2']
  },
  {
    vaultIdAddr: '0xe6cB949e11bEB2a7b48f4cb5bFD8724501cfdA91',
    tokenName: 'DAI',
    lpTokenName: 'DAI',
    strategyType: VaultStrategyType.VAULT_STRATEGY_DF,
    icon: ['0x6B175474E89094C44Da98b954EedeAC495271d0F'],
    apyIcon: ['0x6B175474E89094C44Da98b954EedeAC495271d0F', '0xc28e27870558cf22add83540d2126da2e4b464c2']
  },
  {
    vaultIdAddr: '0x722669a18852c659110D4a1E44f9647Fc44EdCc2',
    tokenName: 'USDT',
    lpTokenName: 'USDT',
    strategyType: VaultStrategyType.VAULT_STRATEGY_DF,
    icon: ['0xdAC17F958D2ee523a2206206994597C13D831ec7'],
    apyIcon: ['0xdAC17F958D2ee523a2206206994597C13D831ec7', '0xc28e27870558cf22add83540d2126da2e4b464c2']
  },
  {
    vaultIdAddr: '0x4221330F24b26f20457f7c7c925CFc46b1541CA6',
    tokenName: 'USDC',
    lpTokenName: 'USDC',
    strategyType: VaultStrategyType.VAULT_STRATEGY_DF,
    icon: ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'],
    apyIcon: ['0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', '0xc28e27870558cf22add83540d2126da2e4b464c2']
  }
]

export const vaultStableTokenPriceAPI = 'http://39.98.34.153:8081/api/price'

/** ===================================================================================
 *  supportedLendingInvestmentPools
 *  ===================================================================================
 */
export const sashimiAddress = '0xC28E27870558cF22ADD83540d2126da2e4b464c2';
export const wethAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
export const wethSashimiLpPAddress = '0x3fa4b0b3053413684d0b658689ede7907bb4d69d';

export const supportedLendingInvestmentPools = [
  {
    lpAddress:{
      1: '0x3fa4b0b3053413684d0b658689ede7907bb4d69d'
    } , // WETH-SASHIMI
    providerAddress: {
      1: '0x7Bc801A840a7c2c027f4E5e48Bf618348B0bCE2B'
    }, // ETH vault provider
    depositAddress: {
      1: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
    } ,
    depositTokenDecimal: 18,
    depositTokenSymbol: 'WETH',
    tokenSymbol: 'WETH',
    icon: tokenIcons.eth,
  },
  {
    lpAddress: {
      1:'0x51214310ac356b26df2a9caf3895398e533c4fa9'
    } , // DAI-ETH
    providerAddress: {
      1:'0xfdeDB338C5E5A26ed5816091DFAF34B5616Cf2F4'
    } , // DAI vault provider
    depositAddress: {
      1:'0x6B175474E89094C44Da98b954EedeAC495271d0F'
    } ,
    depositTokenDecimal: 18,
    depositTokenSymbol: 'DAI',
    tokenSymbol: 'DAI',
    icon: tokenIcons.dai,
  },
  {
    lpAddress: {
      1:'0x64a9d29305b9847ceee21558d3ce1f8e85ee4496'
    }, // USDC-ETH
    providerAddress:{
      1:'0x7cf0569A5d1602068f2C1425BFE8352fD6d8B942'
    } , // USDC vault provider
    depositAddress: {
      1:'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
    } ,
    depositTokenDecimal: 6,
    depositTokenSymbol: 'USDC',
    tokenSymbol: 'USDC',
    icon: tokenIcons.usdc,
  },
  {
    lpAddress: {
      1:'0x490ccb3c835597ff31e525262235487f9426312b'
    } , // USDT-ETH
    providerAddress:{
      1:'0x33A057E6061e3849954578E3Ae0Ae15e615E72Cf'
    } , // USDT vault provider
    depositAddress:{
      1:'0xdAC17F958D2ee523a2206206994597C13D831ec7'
    },
    depositTokenDecimal: 6,
    depositTokenSymbol: 'USDT',
    tokenSymbol: 'USDT',
    icon: tokenIcons.usdt,
  },
  {
    lpAddress:{
      1:'0x04d7eecd7decfc5cc335e4ba5fb6bb09a581be01'
    }, // WBTC-ETH
    providerAddress:{
      1:'0x194402BC8Adcc11a77873996361efe40E9dcdfCb'
    }, // WBTC vault provider
    depositAddress:{
      1:'0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'
    },
    depositTokenDecimal: 8,
    depositTokenSymbol: 'WBTC',
    tokenSymbol: 'WBTC',
    icon: tokenIcons.wbtc,
  },
  {
    lpAddress:{
      1:'0x18492965eef77d1a101d77a394c32178090e98a6'
    }, // YFI-ETH
    providerAddress:{
      1:'0xd3D3fc25B836a8fD49026787C4406F5791f891bE'
    }, // YFI vault provider
    depositAddress:{
      1:'0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e'
    },
    depositTokenDecimal: 18,
    depositTokenSymbol: 'YFI',
    tokenSymbol: 'YFI',
    icon: tokenIcons.yfi,
  },
  {
    lpAddress:{
      1:'0x28e240e04113877bf99354e1e4f43a79e59c535a'
    }, // ELF-ETH
    providerAddress:{
      1:'0x206D84982e032950E3F87d64f86C7c4C7cb60FCC'
    } , // ELF vault provider
    depositAddress:{
      1:'0xbf2179859fc6D5BEE9Bf9158632Dc51678a4100e'
    },
    depositTokenDecimal: 18,
    depositTokenSymbol: 'ELF',
    tokenSymbol: 'ELF',
    icon: tokenIcons.elf,
  },
];
/** ===================================================================================
 *  supportedInvestmentPools
 *  ===================================================================================
 */
export const supportedInvestmentPools = [
  {
    lpAddresses: {
      42: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA',
      1: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA', // WETH-SASHIMI UNI LP
    },
    pivotLpAddresses: {
      42: '0x359c138b1666aa2167aafc205a841ff432a23040',
      1: '0x9776a214272ab452f8c88c7576dcd0c6ffbfee14' // GOF-ETH
    },
    depositAddresses: {
      42: '0xe66747a101bff2dba3697199dcce5b743b454759',
      1: '0xe66747a101bff2dba3697199dcce5b743b454759' // DAI
    },
    providerAddresses: {
      42: '0xE9886bBa3bA6A3C00144E1E068088eE879f560Cd',
      1: '0xE9886bBa3bA6A3C00144E1E068088eE879f560Cd'
    },
    depositTokenSymbol: 'GT',
    tokenSymbol: 'GOF', // GOLFF // The token you get
    icon: '💼',
    sashimiIndex: 1,
    pivotTokenIndex: 0,
  },
  // DeForce Investment
  {
    lpAddresses: {
      42: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA',
      1: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA', // ETH-SASHIMI
    },
    pivotLpAddresses: {
      42: '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11',
      1: '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11' // DAI-ETH
    },
    depositAddresses: {
      42: '0x6b175474e89094c44da98b954eedeac495271d0f', // WETH in kovan, ERC20
      1: '0x6b175474e89094c44da98b954eedeac495271d0f' // DAI
    },
    providerAddresses: {
      42: '0x30D75a1232c0d28aA53c43D47eF7D3441cfeD1E3',
      1: '0x30D75a1232c0d28aA53c43D47eF7D3441cfeD1E3'
    },
    depositTokenSymbol: 'DAI',
    tokenSymbol: 'DF', // Which token will I get, when I do the harvest？
    icon: '👜',
    sashimiIndex: 1,
    pivotTokenIndex: 0,
    hasRegularProfit: true,
  },
  {
    lpAddresses: {
      42: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA',
      1: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA', // ETH-SASHIMI
    },
    pivotLpAddresses: {
      42: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc',
      1: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc' // USDC-ETH
    },
    depositAddresses: {
      42: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // WETH in kovan, ERC20
      1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48' // USDC
    },
    providerAddresses: {
      42: '0x34CF6e94Cb4d3f7f9679584753e4447244f7CBB9',
      1: '0x34CF6e94Cb4d3f7f9679584753e4447244f7CBB9'
    },
    depositTokenSymbol: 'USDC',
    tokenSymbol: 'DF', // Which token will I get, when I do the harvest？
    icon: '👝',
    sashimiIndex: 1,
    pivotTokenIndex: 0,
    hasRegularProfit: true,
  },
  {
    lpAddresses: {
      42: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA',
      1: '0x4b618087DaE7765823BC47fFbF38C8Ee8489F5CA', // ETH-SASHIMI
    },
    pivotLpAddresses: {
      42: '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
      1: '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852' // ETH-USDT USDT-ETH
    },
    depositAddresses: {
      42: '0xdac17f958d2ee523a2206206994597c13d831ec7', // WETH in kovan, ERC20
      1: '0xdac17f958d2ee523a2206206994597c13d831ec7' // USDT
    },
    providerAddresses: {
      42: '0x8010685EaE3228886D2Ce438c1C2C9066227da96',
      1: '0x8010685EaE3228886D2Ce438c1C2C9066227da96'
    },
    depositTokenSymbol: 'USDT',
    tokenSymbol: 'DF', // Which token will I get, when I do the harvest？
    icon: '🧳',
    sashimiIndex: 1,
    pivotTokenIndex: 1,
    hasRegularProfit: true,
  }
  // {
  //   lpAddresses: {
  //     42: '0x7c2580099eF4c34B788d3f3A192817f35dd68f8A',
  //     1: '0x4b618087dae7765823bc47ffbf38c8ee8489f5ca', // WETH-SASHIMI
  //   },
  //   pivotLpAddresses: {
  //     42: '0x7c2580099eF4c34B788d3f3A192817f35dd68f8A',
  //     1: '0x9776a214272ab452f8c88c7576dcd0c6ffbfee14' // GOF-ETH
  //   },
  //   depositAddresses: {
  //     42: '0xA050886815CFc52a24B9C4aD044ca199990B6690', // WETH in kovan, ERC20
  //     1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
  //   },
  //   providerAddresses: {
  //     42: '0xeC0C65A04b985F1F162F13E7Df667bBF4804B621',
  //     1: '0xeC0C65A04b985F1F162F13E7Df667bBF4804B621'
  //   },
  //   depositTokenSymbol: 'WETH',
  //   tokenSymbol: 'GOF', // GOLFF // The token you get
  //   icon: '👜',
  //   sashimiIndex: 1,
  //   pivotTokenIndex: 0,
  // },
  // {
  //   lpAddresses: {
  //     42: '0xb819c827a0dc04e403099310317bd75b8a0a43fa',
  //     // 1: '0xaf46c4cf1cb2a669ce2f7bc1e7a53f0da8c7c574', // HT-SASHIMI UNI LP
  //     1: '0x4b618087dae7765823bc47ffbf38c8ee8489f5ca', // WETH-SASHIMI UNI LP
  //     // 1: '0xceea282be8da0f3f4b2bc57bfd89f4b7dd4454b1', // HT-SASHIMI SALP
  //   },
  //   pivotLpAddresses: {
  //     42: '0x7c2580099eF4c34B788d3f3A192817f35dd68f8A',
  //     1: '0x9776a214272ab452f8c88c7576dcd0c6ffbfee14' // GOF-ETH
  //   },
  //   depositAddresses: {
  //     42: '0x0FE180FC7548efbc2dEe32Eb208403639a01aaa5', // WETH in kovan, ERC20
  //     1: '0x6f259637dcd74c767781e37bc6133cd6a68aa161' // Huobi
  //   },
  //   providerAddresses: {
  //     42: '0x9492d7d2CB08EEF7C825a8199a4704056C808518',
  //     1: '0x9492d7d2CB08EEF7C825a8199a4704056C808518'
  //   },
  //   depositTokenSymbol: 'HT',
  //   tokenSymbol: 'GOF', // GOLFF // The token you get
  //   icon: '👝',
  //   sashimiIndex: 1,
  //   pivotTokenIndex: 0,
  // },
  // {
  //   lpAddresses: {
  //     42: '0xb819c827a0dc04e403099310317bd75b8a0a43fa',
  //     1: '0x4b618087dae7765823bc47ffbf38c8ee8489f5ca', // WETH-SASHIMI UNI LP
  //   },
  //   pivotLpAddresses: {
  //     42: '0x7c2580099eF4c34B788d3f3A192817f35dd68f8A',
  //     1: '0x9776a214272ab452f8c88c7576dcd0c6ffbfee14' // GOF-ETH
  //   },
  //   depositAddresses: {
  //     42: '0x6b175474e89094c44da98b954eedeac495271d0f',
  //     1: '0x6b175474e89094c44da98b954eedeac495271d0f' // DAI
  //   },
  //   // TODO: waiting contract deploy
  //   providerAddresses: {
  //     // 42: '0x9492d7d2CB08EEF7C825a8199a4704056C808518',
  //     // 1: '0x9492d7d2CB08EEF7C825a8199a4704056C808518'
  //     42: '0x65d34f76B6a34BAfE0E03877C3ce68F79B18c394',
  //     1: '0x65d34f76B6a34BAfE0E03877C3ce68F79B18c394'
  //   },
  //   depositTokenSymbol: 'DAI',
  //   tokenSymbol: 'GOF', // GOLFF // The token you get
  //   icon: '🧳',
  //   sashimiIndex: 1,
  //   pivotTokenIndex: 0,
  // },
];