/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { callERC20ViewMethod } from '../../../utils/erc20'
import config from '../config'
import { isUTCToday } from '../utils'
import { Web3Provider } from '@ethersproject/providers'
import { useActiveWeb3React } from '../../../hooks'
import { ContractBasic } from '../../../utils/contract'
import { usePrevious } from 'react-use'
import { useLocation } from 'react-router'
import { useBalances } from './useBalances'
const { feeDecimals, CrossChainAddress } = config
type bridge = [
  {
    crossChainInfo: {
      reqFee: BigNumber
      maxAmount: BigNumber
      maxAmountPerDay: BigNumber
      sendTotalAmount: BigNumber
      paused: boolean
    }
    address: string
    dBalance: BigNumber
    fee: BigNumber
    showFee: string
    balance: BigNumber
    decimals: number
    showBalance: BigNumber
    maxAmount: BigNumber
    account: string
    library: Web3Provider
    symbol: string
    chainId?: number
  },
  { setToken: React.Dispatch<any>; getCrossChainInfo: () => void }
]

export const useBridge = (defaultValue: any, toChainID: number): bridge => {
  const [crossChainInfo, setCrossChainInfo] = useState<any>({})
  const { fee } = crossChainInfo
  const [token, Token] = useState(defaultValue ?? {})
  const { address, decimals } = token
  const { account, library, chainId } = useActiveWeb3React()
  const [[balance], onGetBalance] = useBalances(address)
  const setToken = useCallback(i => Token(i), [])
  const onGetDecimal = useCallback(async () => {
    if (!library) return
    const decimals = await callERC20ViewMethod('decimals', library, address)
    const obj = {
      ...token,
      decimals: isNaN(decimals) ? 0 : Number(decimals)
    }
    if (JSON.stringify(obj) !== JSON.stringify(token)) setToken(obj)
  }, [library, address, token, setToken])
  const onGetToken = useCallback(() => {
    if (account && address && library) {
      if (!decimals) onGetDecimal()
    }
  }, [account, address, decimals, library, onGetDecimal])
  const getChainInfo = useCallback(async () => {
    if (!address) return
    const contract = new ContractBasic({
      contractName: 'CrossChain',
      provider: library,
      contractAddress: CrossChainAddress,
      chainId
    })
    const [timestamp, fee, maxAmount, maxAmountPerDay, sendTotalAmount, paused] = (
      await Promise.all([
        contract.callViewMethod('timestamp'),
        contract.callViewMethod('fee', [toChainID]),
        contract.callViewMethod('maxAmount', [address]),
        contract.callViewMethod('maxSendAmountPerDay', [address]),
        contract.callViewMethod('sendTotalAmount', [address]),
        contract.callViewMethod('paused')
      ])
    ).map((i, index) => {
      if (index === 0 && i._isBigNumber) return i.toString()
      if (i._isBigNumber) return new BigNumber(i.toString())
      return i
    })
    const obj: any = {}
    if (!fee.error) obj.fee = fee

    if (!maxAmount.error) obj.maxAmount = maxAmount

    if (!maxAmountPerDay.error) obj.maxAmountPerDay = maxAmountPerDay

    if (!sendTotalAmount.error && !timestamp.error)
      obj.sendTotalAmount = new BigNumber(isUTCToday(timestamp) ? sendTotalAmount : 0)

    if (!paused.error) obj.paused = paused

    setCrossChainInfo(obj)
  }, [address, chainId, library, toChainID])
  const getCrossChainInfo = useCallback(() => {
    onGetBalance()
    onGetToken()
    getChainInfo()
  }, [getChainInfo, onGetBalance, onGetToken])
  useEffect(() => {
    getCrossChainInfo()
  }, [getCrossChainInfo])
  const dBalance = decimals && balance ? balance.dividedBy(10 ** decimals) : '-'
  return [
    {
      chainId,
      decimals: decimals || 18,
      library,
      crossChainInfo,
      account: account ?? '',
      balance,
      ...token,
      showFee: fee ? fee.dividedBy(10 ** Number(feeDecimals)).toFixed() : '-',
      dBalance,
      showBalance: decimals && balance && !balance.isNaN() ? balance.dividedBy(10 ** decimals).toFixed() : '-'
    },
    { setToken, getCrossChainInfo }
  ]
}
export const useUrlParams = () => {
  const obj: any = {}
  const { search } = useLocation()
  if (typeof search === 'string') {
    const pairs = search.slice(1).split('&')
    Array.isArray(pairs) &&
      pairs.forEach(i => {
        const pair: any = i.split('=')
        obj[pair[0]] = pair[1]
      })
  }
  return obj
}
export const useAccountEffect = (callback: (account?: string | null, prevAccount?: string | null) => void) => {
  const savedCallback = useRef<(account?: string | null, prevAccount?: string | null) => void>()
  useEffect(() => {
    savedCallback.current = callback
  })
  const { account } = useActiveWeb3React()
  const prevAccount = usePrevious(account)

  useEffect(() => {
    if (prevAccount !== account) {
      savedCallback.current?.(account, prevAccount)
    }
  }, [account, prevAccount])
}
