import React, { useState, useCallback, useEffect } from 'react'
import { Button, InputNumber, Spin } from 'antd'
import BigNumber from 'bignumber.js'
import BalanceUtils from '../../../../fuck/formatBalance'
import { useWalletModalToggle } from '../../../../state/application/hooks'
import { useSashimiBarContract, useSashimiContract } from '../../../../hooks/useContract'
import { useContractHandler, useUserBalanceByContract } from '../../../../hooks/useSashimi'
import { useWeb3React } from '@web3-react/core'
const ButtonStyle = {
  width: '112px',
  height: '46px',
  fontSize: '16px',
  borderRadius: '10px',
  marginLeft: '12px'
}
const Stake = () => {
  const toggleWalletModal = useWalletModalToggle()
  const [stakeAmount, setStakeAmount] = useState<number>(0)
  const [allowance, setAllowance] = useState(0)
  // 请求状态管理
  const [pending, setPending] = useState(false)
  const [requestedApproval, setRequestedApproval] = useState(false)

  const { account } = useWeb3React()
  const sashimiContract = useSashimiContract()
  const sashimiBarContract = useSashimiBarContract()

  const sashimiHandler = useContractHandler(sashimiContract)
  const sashimiBarHandler = useContractHandler(sashimiBarContract)

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await sashimiHandler.approve(sashimiBarContract)
      setRequestedApproval(false)
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sashimiHandler, setRequestedApproval])

  useEffect(() => {
    sashimiHandler
      .allowance(sashimiBarContract)
      .then(e => e.toNumber())
      .then(setAllowance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sashimiHandler, setAllowance])

  const tokenBalance = useUserBalanceByContract(sashimiContract)

  const stakingButton = (
    <Spin spinning={pending}>
      <Button
        type="primary"
        style={ButtonStyle}
        disabled={tokenBalance.eq(new BigNumber(0))}
        block
        onClick={async () => {
          if (!stakeAmount || stakeAmount <= 0) {
            return
          }

          try {
            setPending(true)
            await sashimiBarHandler.enter(stakeAmount, 18)
          } catch (error) {
            console.log(error)
          } finally {
            setPending(false)
          }
        }}
      >
        STAKE
      </Button>
    </Spin>
  )

  const unlockButton = (
    <Button
      type="primary"
      style={ButtonStyle}
      block
      onClick={() => {
        // 链接钱包弹层
        toggleWalletModal()
      }}
    >
      Unlock
    </Button>
  )

  const approveButtom = (
    <Button type="primary" style={ButtonStyle} disabled={requestedApproval} onClick={handleApprove} block>
      Approve
    </Button>
  )
  return (
    <>
      <div className="input-wrap">
        <div className="input">
          <InputNumber
            value={stakeAmount}
            className="input-number"
            placeholder="0"
            onChange={value => {
              const amount = parseFloat(value?.toString() ?? '0') ?? 0
              setStakeAmount(amount)
            }}
            max={BalanceUtils.getBalanceNumber(tokenBalance)}
            min={0}
          />
          <i
            onClick={() => {
              setStakeAmount(BalanceUtils.getBalanceNumber(tokenBalance))
            }}
          >
            MAX
          </i>
        </div>
        {account ? (!allowance ? approveButtom : stakingButton) : unlockButton}
      </div>
      <p className="balance">
        My SASHIMI: <strong>{BalanceUtils.getBalanceNumber(tokenBalance)}</strong>
      </p>
    </>
  )
}

export default Stake
