import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, InputNumber, Row, Select, Spin, Input } from 'antd'
import {BigNumber} from 'bignumber.js';
import '../Compensation.less';
import Countdown from './Countdown'
import { StakePanelProps, TUserInfo } from './index'
import { useActiveWeb3React } from '../../../hooks'
import LoginButton from './LoginButton'
import {
  EXCHANGE_DELEGATE_ADDRESS,
  LP_BLOCK_TAG,
  LP_DECIMAL_TENTH_POWER,
  POOLS_INFO,
} from '../config/config'
import { useExchangeContract, useTokenContract } from '../hooks/useContract'
import { Contract } from '@ethersproject/contracts'
import { CompensationContext } from '../context/useCompensation'
import { useBlockNumber } from '../../../state/application/hooks'
import { ethers } from 'ethers'

const { Option } = Select;

const StakePanel: React.FC<StakePanelProps>
  = ({
       panelInfo,
       setPanelInfo
     }) => {
  const { account } = useActiveWeb3React();
  const block = useBlockNumber();
  const exchangeContract = useExchangeContract(EXCHANGE_DELEGATE_ADDRESS);
  const {stakeTimeType, countdown} = useContext(CompensationContext);

  const [refreshTag, setRefreshTag] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLp, setLoadingLp] = useState<boolean>(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);
  const [poolId, setPoolId] = useState<number>(0);
  const [stakeNum, setStakeNum] = useState<BigNumber>(new BigNumber(0));
  const [stakeNumShow, setStakeNumShow] = useState<string>();
  const [stakedNum, setStakedNum] = useState<BigNumber>(new BigNumber(0));
  const [lpBalance, setLpBalance] = useState<BigNumber>(new BigNumber(0));
  const [historyLpBalance, setHistoryLpBalance] = useState<BigNumber>(new BigNumber(0));
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));

  // const [poolInfo, setPoolInfo] = useState<TPoolInfo>();
  const [userInfo, setUserInfo] = useState<TUserInfo>();

  const [lpTokenAddress, setLpTokenAddress] = useState<string>(POOLS_INFO[0].lpToken);
  const lpTokenContract = useTokenContract(lpTokenAddress);

  useEffect(() => {
    if (!exchangeContract || poolId < 0 || !account) {
      return;
    }
    console.log('block:', block);
    // setLoading(true);
    const fetchPoolInfo = async function () {
      const [poolInfo, userInfo] = await Promise.all([
        exchangeContract.poolInfo(poolId),
        exchangeContract.userInfo(poolId, account)
      ]);

      setLpTokenAddress(poolInfo.lpToken);

      // setPoolInfo(poolInfo);
      setStakedNum(new BigNumber(userInfo.amount.toString()).plus(userInfo.unconfirmAmount.toString()));
      setUserInfo(userInfo);
      setLoading(false);
      console.log('poolInfo: ', poolInfo, userInfo, account);
    }
    fetchPoolInfo();
  }, [exchangeContract, poolId, account, block]);

  useEffect(() => {
    if (!account || !lpTokenContract) {
      return;
    }
    // setLoadingLp(true);
    const fetchUserInfo = async function(account: string, lpTokenContract: Contract) {
      // 初始化合约并调用合约方法
      // const balanceHistory = await lpTokenContract.balanceOf(account, {
      //   blockTag: LP_BLOCK_TAG
      // });
      // const balance = await lpTokenContract.balanceOf(account);
      // const name = await lpTokenContract.decimals();
      // const allowance = await lpTokenContract.allowance(account, EXCHANGE_DELEGATE_ADDRESS);
      //
      const [balanceHistory, balance, name, allowance] = await Promise.all([
        lpTokenContract.balanceOf(account, {
          blockTag: LP_BLOCK_TAG
        }),
        lpTokenContract.balanceOf(account),
        lpTokenContract.decimals(),
        lpTokenContract.allowance(account, EXCHANGE_DELEGATE_ADDRESS)
      ]);

      console.log('balance lp', balance.toString(), name, balanceHistory.toString());

      setHistoryLpBalance(new BigNumber(balanceHistory.toString()));
      setLpBalance(new BigNumber(balance.toString()));
      setLoadingLp(false);
      setAllowance(allowance);
    };
    fetchUserInfo(account, lpTokenContract);
  }, [account, refreshTag, lpTokenContract, block]);

  const [canBeStakedLimit, setCanBeStakedLimit] = useState<BigNumber>(new BigNumber(0));
  useEffect(() => {
    const canBeStakedLimit = BigNumber.min(
      historyLpBalance.minus(stakedNum).div(LP_DECIMAL_TENTH_POWER),
      lpBalance.div(LP_DECIMAL_TENTH_POWER)
    );
    setCanBeStakedLimit(canBeStakedLimit);
  }, [historyLpBalance, lpBalance, stakedNum]);

  const [isBadAddress, setIsBadAddress] = useState<boolean>(false);
  useEffect(() => {
    if (!exchangeContract || !account) {
      return;
    }
    const checkBadAddress = async () => {
      const isBadAddress = await exchangeContract.badAddresses(account);
      setIsBadAddress(isBadAddress);
      console.log('isBadAddress', isBadAddress);
    };
    checkBadAddress();
  }, [exchangeContract, account]);

  return (
    <div className={`stake ${panelInfo.left && !panelInfo.information ? null : 'display-none'}`}>
      <div className="info-logo-container">
        <div className="sushi-big">🍣</div>
      </div>
      <div>
        <Countdown
          startTime={countdown.startTime}
          endTime={
            (countdown.startTime && countdown.depositTimeLimit)
            && (+countdown.startTime + +countdown.depositTimeLimit)}
        />
      </div>

      <Spin spinning={loading || loadingLp}>
        <div className="balance-container">
          <Row justify="space-between">
            <Col>
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (!option) {
                    return false;
                  }
                  const charMatch= option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  const addressMatch= option.key
                    ? `${option.key}`.toLowerCase() === input.toLowerCase() : false;
                  console.log('filterOption', input, option, charMatch);
                  return charMatch || addressMatch;
                }}
                style={{ width: 200 }}
                defaultValue={0}
                bordered={false}
                onChange={value => {
                  if (typeof value === 'undefined') {
                    return;
                  }
                  console.log('Select Changed,', value);
                  setStakeNum(new BigNumber(0));
                  setLoading(true);
                  setLoadingLp(true);
                  setPoolId(+value);
              }}>
                {POOLS_INFO.map((poolInfo, index) => {
                  return <Option value={+index} key={poolInfo.lpToken} name={poolInfo.lpName}>
                    🍣 <span className="compensation-option-value">{poolInfo.lpName} SALP LP</span>
                  </Option>
                })}
              </Select>
            </Col>
            <Col className="balance-context">
              {lpBalance.div(LP_DECIMAL_TENTH_POWER).toFormat()}
            </Col>
          </Row>
          <div className="staked">{
            userInfo
              ? stakedNum.div(LP_DECIMAL_TENTH_POWER).toFormat()
              : '0'
          } {POOLS_INFO[poolId].lpName} SALP LP Deposited</div>
        </div>
      </Spin>

      <Spin spinning={loading || loadingLp}>
        <div>
          {/*<InputNumber*/}
          {/*  className="stake-input"*/}
          {/*  // formatter={(value) => {*/}
          {/*  //   if (!value) {*/}
          {/*  //     return '';*/}
          {/*  //   }*/}
          {/*  //   console.log('setStakeNumber formatter', value);*/}
          {/*  //   return new BigNumber(value).toString()*/}
          {/*  // }}*/}
          {/*  value={stakeNum.toNumber() || undefined}*/}
          {/*  min={0} max={canBeStakedLimit.toNumber()}*/}
          {/*  placeholder={`Enter deposit amount, limit ${canBeStakedLimit.toString()}`}*/}
          {/*  onChange={value => {*/}
          {/*    console.log('setStakeNumber: ', value,*/}
          {/*      canBeStakedLimit.toString(), stakeNum.toString(),*/}
          {/*      BigNumber.min(value || 0, canBeStakedLimit).toString(),*/}
          {/*      BigNumber.min(value || 0, canBeStakedLimit).toNumber());*/}
          {/*    if (!value) {*/}
          {/*      setStakeNum(new BigNumber(0));*/}
          {/*      return*/}
          {/*    }*/}
          {/*    setStakeNum(BigNumber.min(value, canBeStakedLimit));*/}
          {/*  }} />*/}

          <Input
            className="stake-input"
            // value={stakeNum.gt(0) ? stakeNum.toString() : undefined}
            value={stakeNumShow}
            placeholder={`Enter deposit amount, limit ${canBeStakedLimit.toString()}`}
            onBlur={(event) => {
              const { value } = event.target;
              const reg = /^-?\d*(\.\d*)?$/;
              if (!((!isNaN(+value) && reg.test(value)) || value === '' || value === '-')) {
                setStakeNum(new BigNumber(0));
                setStakeNumShow('')
                return;
              }
              setStakeNum(BigNumber.min(value, canBeStakedLimit));
              const stakeNumShow = BigNumber.min(value, canBeStakedLimit).toString();
              setStakeNumShow(stakeNumShow === 'NaN' ? '' :  stakeNumShow);
              console.log('setStakeNumber: ', value,
                BigNumber.min(value || 0, canBeStakedLimit).toString(), stakeNumShow);
            }}
            onChange={event => {
              const { value } = event.target;
              const reg = /^-?\d*(\.\d*)?$/;
              if (!((!isNaN(+value) && reg.test(value)) || value === '' || value === '-')) {
                // setStakeNum(new BigNumber(0));
                setStakeNumShow('')
                return;
              }
              setStakeNumShow(value);
            }} />
        </div>
      </Spin>

      <div className="plan-detail">
              <span onClick={() => {
                setPanelInfo({
                  left: true,
                  information: true,
                  right: false
                });
              }}>SASHIMI Distribution Plan</span>
      </div>
      {
        account
          ? <Button
            className="button-full-width"
            type="primary"
            disabled={
              isBadAddress ||
              !stakeNum || !stakeNum.gt(0)
              || !account || stakeTimeType !== 'TO_END'
            }
            loading={submitBtnLoading}
            onClick={async () => {
              // console.log('button', stakeNum.toString());
              if (!stakeNum || stakeNum.eq(0) || !exchangeContract || !lpTokenContract) {
                return;
              }
              setSubmitBtnLoading(true);

              try {
                if (allowance.eq(0)) {
                  const approveTx = await lpTokenContract
                    .approve(EXCHANGE_DELEGATE_ADDRESS, ethers.constants.MaxUint256.toString());
                  await approveTx.wait();
                  setRefreshTag(new Date().getTime());
                  setSubmitBtnLoading(false);
                  return;
                }

                // NOTE: 请求失败，不知道为什么; 因为时间判断错了，能抵押只有 90天, 领补偿有180天
                // console.log('exchangeContract', poolId, stakeNum.times(LP_DECIMAL_TENTH_POWER).toString());
                const depositTx
                  =  await exchangeContract.deposit(poolId + '', stakeNum.times(LP_DECIMAL_TENTH_POWER).toString());
                await depositTx.wait();
                // console.log('发起合约交易');
                // 交易成功后，刷新页面数据
                setRefreshTag(new Date().getTime());
                setSubmitBtnLoading(false);
              } catch {
                setSubmitBtnLoading(false);
              }
            }}
          >{
            isBadAddress
              ? 'Invalid Address'
              : allowance.eq(0) ? 'Approve' : 'Deposit LP token'
          }</Button>
          : <LoginButton/>
      }
      <div className="stake-note-bottom">
        <p>LP token will be valid only at the block height of 13905777 !!</p>
        <p> Depositing LP token after block height of 13905777 is invalid !!</p>
      </div>
    </div>
  )
}

export default StakePanel;
