import eth from "./eth.png";
import bnb from "./bnb.png";
import ht from "./ht.png";
import arrows from "./arrows.png";
import arrowRight from "./arrow_right.png";

export default {
  ht,
  eth,
  bnb,
  arrows,
  arrowRight,
};
