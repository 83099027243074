import React from 'react'
import styled from 'styled-components'
import TokenPanel from '../TokenPanel'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../state'
import { useVaultApys } from '../../../../state/vault/hooks'

const Vault = () => {
  const vaultsInfo = useSelector<AppState, AppState['vault']['vaults']>(state => state.vault.vaults)
  const vaultsApy = useVaultApys()

  return (
    <Lists>
      {vaultsInfo &&
        Object.values(vaultsInfo).map(vault => {
          return <TokenPanel key={vault.vaultIdAddr} vault={vault} apyInfo={vaultsApy[vault.vaultIdAddr]} />
        })}
    </Lists>
  )
}
const Lists = styled.div`
  margin-top: 38px;
  padding: 0 40px;
  /* display: flex;
  flex-wrap: wrap; */
  @media screen and (max-width: 576px) {
    padding: 0 16px;
    grid-template-columns: 1fr;
    grid-template-rows: 500px 500px 500px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 500px 500px 500px;
  grid-gap: 34px 34px;
  @media screen and (max-width: 1600px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 500px 500px;
    grid-gap: 40px 40px;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 500px;
    grid-gap: 40px;
  }
`

export default Vault
