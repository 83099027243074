import React from 'react'
import styled from 'styled-components'
import Notice from '../../components/Notice'
// import MarketPrice from './components/MarketPrice'
import Announcement from './components/Announcement'
import DynamicData from './components/DynamicData'
import Locked from './components/Locked'
import Exchange from './components/Exchange'
import OverviewUpdater from '../../state/overview/updater'
import Search from './components/Search'
import TradeSetting from './components/TradeSetting'

const Overview: React.FC = () => {
  return (
    <>
      <OverviewUpdater />
      <Notice text="This project is in beta. Use at your own risk. You can review the contract on Etherscan." />
      <Wrap>
        <Top>
          <Locked />
          <Exchange />
        </Top>
        <SearchBox>
          <Search />
        </SearchBox>
        <Middle>
          <TradeSetting />
          <Announcement />
        </Middle>
        <Bottom>
          <DynamicData />
        </Bottom>
      </Wrap>
    </>
  )
}
const Wrap = styled.div`
  box-sizing: border-box;
  padding: 30px 40px 34px 40px;
  @media screen and (max-width: 576px) {
    padding: 16px;
  }
`

const Top = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
  justify-content: space-between;
`
const Bottom = styled.div`
  margin-top: 30px;
  @media screen and (max-width: 576px) {
    margin-top: 16px;
  }
`

const Middle = styled(Top)`
  margin-top: 30px;
  @media screen and (max-width: 576px) {
    margin-top: 16px;
  }
`

const SearchBox = styled.div`
  width: 100%;
  position: relative;
  margin: 30px 0;
`

export default Overview
