import {useCallback, useEffect, useState} from 'react';
import { useActiveWeb3React } from './index'
import BigNumber from "bignumber.js";
import { wethAddress } from "../constants/sashimi/constants";

export const useTokenWethPriceByRouter = (sashimiRouterContract: any, lpAddress: string, tokenAddress: string): BigNumber => {
  const [tokenWethPrice, setTokenWethPrice] = useState(new BigNumber(0));
  const { library } = useActiveWeb3React();
  const fetchTokenWETHPrice = useCallback(async () => {
    if (!library || !sashimiRouterContract || !lpAddress || !tokenAddress) {
      return;
    }
    try {
      if (tokenAddress.toLowerCase() === wethAddress.toLowerCase()) {
        setTokenWethPrice(new BigNumber(1));
      }
      const tokenAmountWholeLPPromise = sashimiRouterContract.functions
        .getTokenInPair(
          lpAddress,
          tokenAddress
        )
      const lpContractWethPromise = sashimiRouterContract.functions
        .getTokenInPair(
          lpAddress,
          wethAddress
        )
  
      const [tokenAmountWholeLP, lpContractWeth] = await Promise.all([tokenAmountWholeLPPromise, lpContractWethPromise])
   
      setTokenWethPrice(new BigNumber(lpContractWeth).div(tokenAmountWholeLP));
    } catch (error) {
      console.log(error)
    }
    
  }, [library, sashimiRouterContract, lpAddress, tokenAddress]);
  
  useEffect(() => {
    fetchTokenWETHPrice();
  }, [fetchTokenWETHPrice]);

  return tokenWethPrice;
};