import { useState, useEffect } from 'react'
import server from '../api'
interface ServerProp {
  [key: string]: any
}
export interface StakerTopItemProps {
  id: number
  holder: string
  quantity: string
  block: number
}
export interface StakerTopProps {
  holderNumber: number
  holderList: StakerTopItemProps[]
}
const serverObj: ServerProp = server
const useStakerTop = () => {
  const [stakerTop, setStakerTop] = useState<StakerTopProps>()
  const { farms } = serverObj
  const handleStaker = () => {
    if (farms) {
      farms.getTopTokenHolders({
        success: (res: { code: number; msg: string; data: StakerTopProps }) => {
          const { code, msg, data } = res
          if (code === 0 && msg === 'success') {
            setStakerTop(data)
          }
        }
      })
    }
  }
  useEffect(() => {
    handleStaker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms])
  return stakerTop
}
export default useStakerTop
