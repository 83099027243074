import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Contract, BigNumber as EthBigNumber, ethers } from 'ethers'
import { useSashimiBarContract, useSashimiContract } from './useContract'
import { useBlockNumber } from '../state/application/hooks'

// add 10%
export function calculateGasMargin(value: EthBigNumber): EthBigNumber {
  return value.mul(EthBigNumber.from(10000).add(EthBigNumber.from(1000))).div(EthBigNumber.from(10000))
}

function convertEthBigNumberToBigNumber(value: EthBigNumber[]): BigNumber[] {
  return value.map(item => item.toString()).map(item => new BigNumber(item))
}

interface XSashimiInfo {
  sashimiBalanceOfSashimiBar: BigNumber
  xSashimiBalanceOfSashimiBar: BigNumber
}

/**
 * 获取 SashimiBar 信息
 *
 * 返回：SashimiBar 中的 sashimi 与 xSashimi 数量
 */
export function useSashimiBarInfo(): XSashimiInfo {
  const [xSashimiInfo, setXSashimiInfo] = useState<XSashimiInfo>({
    sashimiBalanceOfSashimiBar: new BigNumber(0),
    xSashimiBalanceOfSashimiBar: new BigNumber(0)
  })

  const sashimiContract: Contract | null = useSashimiContract()
  const sashimiBarContract: Contract | null = useSashimiBarContract()

  const block = useBlockNumber() ?? 0

  useEffect(() => {
    if (!sashimiContract || !sashimiBarContract) return

    Promise.all([
      sashimiBarContract.functions.totalSupply(),
      sashimiContract.functions.balanceOf(sashimiBarContract.address)
    ])
      .then(convertEthBigNumberToBigNumber)
      .then(([xSashimiSupply, sashimiBalanceOfSashimiBar]) => {
        setXSashimiInfo({
          sashimiBalanceOfSashimiBar: sashimiBalanceOfSashimiBar,
          xSashimiBalanceOfSashimiBar: xSashimiSupply
        })
      })
  }, [sashimiContract, sashimiBarContract, block])

  return useMemo(() => xSashimiInfo, [xSashimiInfo])
}

export function useContractHandler(
  contract: Contract | null
): {
  allowance: (targetContract: Contract | null) => Promise<BigNumber>
  approve: (targetContract: Contract | null) => Promise<any | void>
  harvest: (poolId: number) => void
  enter: (amount: number, decimal?: number) => void
  leave: (amount: number, decimal?: number) => void
  stake: (poolId: number, amount: string, decimal: number) => void
  unStake: (poolId: number, amount: string, decimal: number) => void
  getStake: (poolId: number) => Promise<BigNumber>
  earning: (poolId: number) => void
} {
  // 获取批准
  const approve = useCallback(
    async (targetContract: Contract | null) => {
      if (!contract || !contract.signer || !targetContract) return

      const estimatedGas = await contract.estimateGas.approve(targetContract.address, ethers.constants.MaxUint256)
      return await contract.functions.approve(targetContract.address, ethers.constants.MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  // 收成
  // masterChef
  const harvest = useCallback(
    async (poolId: number) => {
      if (!contract || !contract.signer) return

      const estimatedGas = await contract.estimateGas.deposit(poolId, '0')
      return await contract.functions.deposit(poolId, '0', {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  const allowance = useCallback(
    async (targetContract: Contract | null): Promise<BigNumber> => {
      if (!contract || !targetContract || !contract.signer) {
        return new BigNumber(0)
      }

      const address = await contract.signer.getAddress()
      const allowance = await contract.callStatic.allowance(address, targetContract.address)
      return new BigNumber(allowance.toString())
    },
    [contract]
  )

  const enter = useCallback(
    async (amount: number, decimal = 18) => {
      if (!contract || !contract.signer) return
      if (!contract.functions.enter) return
      const _amount = new BigNumber(amount).times(new BigNumber(10).pow(decimal))

      const estimatedGas = await contract.estimateGas.enter(_amount.toString())
      return await contract.functions.enter(_amount.toString(), {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  const leave = useCallback(
    async (amount = 0, decimal = 18) => {
      if (!contract || !contract.signer) return
      if (!contract.functions.leave) return
      const _amount = new BigNumber(amount).times(new BigNumber(10).pow(decimal))

      const estimatedGas = await contract.estimateGas.leave(_amount.toString())
      return await contract.functions.leave(_amount.toString(), {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  // masterChef
  const stake = useCallback(
    async (poolId: number, amount: string, decimal = 18) => {
      if (!contract || !contract.signer) return
      if (!contract?.functions?.deposit) return

      const _amount: BigNumber = new BigNumber(amount).times(new BigNumber(10).pow(decimal))

      const estimatedGas = await contract.estimateGas.deposit(poolId, _amount.toString())
      return await contract.functions.deposit(poolId, _amount.toString(), {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  // masterChef
  const unStake = useCallback(
    async (poolId: number, amount: string, decimal = 18) => {
      if (!contract || !contract.signer) return
      if (!contract?.functions?.withdraw) return

      const _amount: BigNumber = new BigNumber(amount).times(new BigNumber(10).pow(decimal))
      const estimatedGas = await contract.estimateGas.withdraw(poolId, _amount.toString())
      return await contract.functions.withdraw(poolId, _amount.toString(), {
        gasLimit: calculateGasMargin(estimatedGas)
      })
    },
    [contract]
  )

  // masterChef
  const getStake = useCallback(
    async (poolId: number): Promise<BigNumber> => {
      if (!contract || !contract.signer) return new BigNumber(0)
      const account: string = await contract.signer.getAddress()

      const { amount } = await contract.functions.userInfo(poolId, account)
      return new BigNumber(amount.toString())
    },
    [contract]
  )

  // masterChef
  const earning = useCallback(
    async (poolId: number) => {
      if (!contract || !contract.signer) return
      const account: string = await contract.signer.getAddress()
      return contract.functions.pendingSashimi(poolId, account)
    },
    [contract]
  )

  return useMemo(
    () => ({
      allowance,
      approve,
      harvest,
      enter,
      leave,
      stake,
      unStake,
      getStake,
      earning
    }),
    [allowance, approve, harvest, enter, leave, stake, unStake, getStake, earning]
  )
}

export function useUserBalanceByContract(contract: Contract | null): BigNumber {
  const [balance, setBalance] = useState(new BigNumber(0))
  const block = useBlockNumber()

  useEffect(() => {
    async function getUserBalance() {
      if (!contract || !contract.signer) return
      const account = await contract.signer.getAddress()
      const balance = await contract.functions.balanceOf(account)

      setBalance(new BigNumber(balance.toString()))
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUserBalance().catch(err => {
      setBalance(new BigNumber(0))
    })
  }, [contract, block])

  return useMemo(() => balance, [balance])
}
