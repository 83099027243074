import { AppState } from '../index'
import { useSelector } from 'react-redux'
import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { useEtherPrice } from '../global/hooks'
import { useSashimiBarInfo } from '../../hooks/useSashimi'

export function useTokenMarkets(): AppState['overview']['tokenMarkets'] {
  return useSelector<AppState, AppState['overview']['tokenMarkets']>(state => state.overview.tokenMarkets)
}

/**
 * 返回：sashimi的总发行量, 已销毁数量，国库数量
 */
export function useSashimiInfo(): {
  sashimiOfTotalSupply: BigNumber
  sashimiOfBurned: BigNumber
  sashimiOfTreasury: BigNumber
} {
  const { sashimiOfTotalSupply, sashimiOfBurned, sashimiOfTreasury } = useSelector<
    AppState,
    AppState['overview']['sashimiInfo']
  >(state => state.overview.sashimiInfo)

  return {
    sashimiOfTotalSupply: new BigNumber(sashimiOfTotalSupply),
    sashimiOfBurned: new BigNumber(sashimiOfBurned),
    sashimiOfTreasury: new BigNumber(sashimiOfTreasury)
  }
}

/**
 * Sashimi 数据
 */
export function useSashimiDynamicInfo(): {
  maximumNumber: BigNumber
  sashimiOfTotalSupply: BigNumber
  sashimiOfBurned: BigNumber
  sashimiOfTreasury: BigNumber
  distributedNumber: BigNumber
  undistributedNumber: BigNumber
  distributedPercentageNumber: BigNumber
  undistributedPercentageNumber: BigNumber
} {
  const { sashimiOfTotalSupply, sashimiOfBurned, sashimiOfTreasury } = useSashimiInfo()

  const maximumNumber: BigNumber = new BigNumber(1e8).multipliedBy(new BigNumber(10).pow(18))
  const distributedNumber: BigNumber = sashimiOfTotalSupply

  return useMemo(() => {
    const undistributedNumber: BigNumber = distributedNumber ? maximumNumber.minus(distributedNumber) : new BigNumber(0)
    const distributedPercentageNumber: BigNumber = distributedNumber
      ? distributedNumber.dividedBy(maximumNumber).multipliedBy(100)
      : new BigNumber(0)
    const undistributedPercentageNumber: BigNumber = undistributedNumber
      ? undistributedNumber.dividedBy(maximumNumber).multipliedBy(100)
      : new BigNumber(0)

    return {
      maximumNumber,
      sashimiOfTotalSupply,
      sashimiOfBurned,
      sashimiOfTreasury,
      distributedNumber,
      undistributedNumber,
      distributedPercentageNumber,
      undistributedPercentageNumber
    }
  }, [maximumNumber, sashimiOfTotalSupply, sashimiOfBurned, sashimiOfTreasury, distributedNumber])
}

/**
 * 估算
 */
export function useSashimiEstimate(): {
  xSashimiUSD: BigNumber
  burnedUSD: BigNumber
  treasuryUSD: BigNumber
} {
  const { sashimiOfBurned, sashimiOfTreasury } = useSashimiInfo()
  const { now } = useTokenMarkets()
  const sashimiToken = Object.values(now).find(item => item.symbol.toLowerCase() === 'sashimi')
  const ethPriceUSD = useEtherPrice()
  const { sashimiBalanceOfSashimiBar, xSashimiBalanceOfSashimiBar } = useSashimiBarInfo()

  return useMemo(() => {
    if (!sashimiToken || !ethPriceUSD) {
      return {
        xSashimiUSD: new BigNumber(0),
        burnedUSD: new BigNumber(0),
        treasuryUSD: new BigNumber(0)
      }
    }

    const sashimiPriceUSD = new BigNumber(sashimiToken.derivedETH ?? '0').times(new BigNumber(ethPriceUSD.now))

    const decimals = new BigNumber(10).pow(18)
    const xSashimiUSD = sashimiBalanceOfSashimiBar
      .minus(xSashimiBalanceOfSashimiBar)
      .dividedBy(decimals)
      .times(sashimiPriceUSD)
    const burnedUSD = sashimiOfBurned.dividedBy(decimals).times(sashimiPriceUSD)
    const treasuryUSD = sashimiOfTreasury.dividedBy(decimals).times(sashimiPriceUSD)

    return {
      xSashimiUSD,
      burnedUSD,
      treasuryUSD
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sashimiOfBurned, sashimiOfTreasury, sashimiToken, ethPriceUSD, sashimiBalanceOfSashimiBar])
}
