import { useCallback, useState } from 'react'
import useInterval from './useInterval'
import { getNetworkLibrary } from '../connectors'
import { useActiveWeb3React } from '../hooks'

interface UseNFTInfoProps {
  address?: string
  delay?: number
}

const useMBalance: (params: UseNFTInfoProps) => Array<string | any> = ({ address, delay }) => {
  const { account, library, chainId } = useActiveWeb3React()
  const [userBalance, setUserBalance] = useState<string>('0')

  // Get eth balance
  const fetchBalance = useCallback(async () => {
    if (!account || !library) {
      setUserBalance('0')
      return
    }
    const provider = getNetworkLibrary()
    const ethBalance = await provider.getBalance(address || account)
    setUserBalance(ethBalance.toString())
  }, [account, library, address])

  useInterval(
    () => {
      fetchBalance()
    },
    delay,
    [account, chainId]
  )

  return [userBalance, fetchBalance]
}
export default useMBalance
