import React, { useState } from 'react'
import SashimiImg from '../../../../assets/images/logo.svg'
import BalanceUtils from '../../../../fuck/formatBalance'
import { Button } from 'antd'
import { useMasterChefContract } from '../../../../hooks/useContract'
import { useContractHandler } from '../../../../hooks/useSashimi'
import BigNumber from 'bignumber.js'
import { useEarningSashimi } from '../../../../state/farm-pool/hooks'
import CountUpAni from '../../../../components/CountUpAni'

const Harvest = ({ poolId }: { poolId: number }) => {
  const earnings = useEarningSashimi(poolId)
  const [pendingTx, setPendingTx] = useState(false)

  const masterChefContract = useMasterChefContract()
  const masterChefHandler = useContractHandler(masterChefContract)

  return (
    <div className="item-wrap">
      <div className="item">
        <div className="img">
          <img style={{ width: '35px' }} src={SashimiImg} alt="" />
        </div>
        <h2>
          {/* {BalanceUtils.getBalanceNumber(earnings).toLocaleString('currency', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
          })} */}
          <CountUpAni num={BalanceUtils.getBalanceNumber(earnings)} />
        </h2>
        <p>SASHIMI Earned</p>
      </div>
      <div className="btn">
        <Button
          style={{ borderRadius: '10px', width: '360px', height: '46px', fontSize: '18px' }}
          disabled={earnings.eq(new BigNumber(0)) || pendingTx}
          type="primary"
          size="large"
          block
          onClick={async () => {
            try {
              setPendingTx(true)
              if (masterChefHandler) await masterChefHandler.harvest(poolId)
            } finally {
              setPendingTx(false)
            }
          }}
        >
          {pendingTx ? 'Collecting SASHIMI' : 'Harvest'}
        </Button>
      </div>
    </div>
  )
}

export default Harvest
