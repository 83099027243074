import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { Button, Spin } from 'antd'
import { Contract } from 'ethers'
import BigNumber from 'bignumber.js'
import { ROUTER_ADDRESS } from '../../../constants'
import { supportedLendingInvestmentPools, sashimiAddress, wethSashimiLpPAddress } from '../../../constants/sashimi/constants'
import { useLendingInvestmentAPYs,ILendingInvestmentAPY } from '../../../hooks/useInvestmentAPY'
import { useActiveWeb3React } from '../../../hooks'
import { useTokenWethPriceByRouter } from '../../../hooks/useTokenWethPriceByRouter';
import {
  useSashimiContract,
  useInvestmentInfoContract,
  useTokenContract,
  useInvestmentLendingContract,
  useSashimiRouterContract
} from '../../../hooks/useContract'
import BalanceUtils from '../../../fuck/formatBalance'

const ButtonStyle = {
  width: '100%',
  height: '46px',
  borderRadius: '10px',
  fontSize: '16px',
  boxShadow: 'none',
}
type IInvestment = {
  lpAddress: string
  providerAddress: string
  depositAddress: string
  depositTokenDecimal: number
  depositTokenSymbol: string
  tokenSymbol: string
  icon: string
  apyInfo: any
}
type InvestmentRowProps = IInvestment
const InvestmentCards: React.FC = () => {
  const investmentInfoContract = useInvestmentInfoContract()
  const sashimiContract = useSashimiContract()
  const investmentAPYs:ILendingInvestmentAPY[] = useLendingInvestmentAPYs() 
  const sashimiRouterContract = useSashimiRouterContract();
  const { chainId, account } = useActiveWeb3React()
  const tokenWethPrice = useTokenWethPriceByRouter(sashimiRouterContract, wethSashimiLpPAddress, sashimiAddress);
  const investments: IInvestment[] = supportedLendingInvestmentPools.map(
    ({
     lpAddress,
     providerAddress,
     depositAddress,
     depositTokenDecimal,
     depositTokenSymbol,
     tokenSymbol,
     icon,
    }: IInvestment) => {
      return {
        depositAddress: depositAddress[chainId ?? 1],
        depositTokenSymbol,
        lpAddress: lpAddress[chainId ?? 1],
        providerAddress:providerAddress[chainId ?? 1],
        depositTokenDecimal,
        tokenSymbol,
        icon,
        apyInfo: {
          ...investmentAPYs.find(investmentAPY => {
            const symbol = investmentAPY.symbol.toLowerCase();
            const symbolPivot = tokenSymbol.toLowerCase();
            if (['eth','weth'].includes(symbol) && ['eth','weth'].includes(symbolPivot)) {
              return true;
            }
            return symbol === symbolPivot;
          })},
      }
    }
  )
  return (
    <>
      {!!investments.length
        ? investments.map((investment, i) => (
            <React.Fragment key={i}>
              <InvestmentCard
                investment={investment}
                investmentContract={investmentInfoContract}
                sashimiContract={sashimiContract}
                sashimiRouterContract={sashimiRouterContract}
                sashimiWethPrice={tokenWethPrice}
                account={account}
              />
            </React.Fragment>
          ))
        : 'Cooking the rice ...'}
    </>
  )
}

interface InvestmentCardProps {
  investment:IInvestment,
  investmentContract: any
  sashimiContract: any,
  sashimiRouterContract: any
  sashimiWethPrice: BigNumber,
  account: any
}
const InvestmentCard: React.FC<InvestmentCardProps> = ({ investment, investmentContract, sashimiContract, sashimiRouterContract, sashimiWethPrice, account }) => {
  const below576: boolean = useMedia('(max-width:576px)')
  const lpContract: Contract | null = useTokenContract(investment.depositAddress)
  

  const [harvestPending, setHarvestPending] = useState(false)
  const [reBalancePending, setReBalancePending] = useState(false)
  const [reservesRatio, setReservesRatio] = useState('-')
  const [depositAmount, setDepositAmount] = useState(0)
  const [actualFundUsed, setActualFundUsed] = useState('-%')

  const [supplyProfitEthValued, setSupplyProfitEthValued] = useState(new BigNumber(0));
  const [farmProfitEthValued, setFarmProfitEthValued] = useState(new BigNumber(0));
  const investmentLendingContract = useInvestmentLendingContract(investment.providerAddress);
  const tokenWethPrice = useTokenWethPriceByRouter(sashimiRouterContract, investment.lpAddress, investment.depositAddress);
  // console.log(investment.apyInfo.apy,"apyInfo");
  const fetchData = useCallback(async () => {
    if (investmentContract && sashimiContract && investmentLendingContract) {
      try {
        const pivotTokenDecimal = investment.depositTokenDecimal || 18

        const reservesPointsData = await investmentContract.reservesRatios(investment.depositAddress)
        const reservesPoints = new BigNumber(reservesPointsData.toString()).toNumber()

        const depositAmountData = await investmentContract.deposits(investment.depositAddress)
        const depositAmountBN = new BigNumber(depositAmountData.toString())
        const depositAmount = depositAmountBN.toNumber() / 10 ** pivotTokenDecimal

        const depositTokenBalanceInRouterData = await lpContract?.functions.balanceOf(ROUTER_ADDRESS)
        const depositTokenBalanceInRouter = new BigNumber(depositTokenBalanceInRouterData.toString())
        const actualFundPool = depositTokenBalanceInRouter.plus(depositAmountBN)
        const actualFundUsed = `${new BigNumber(100).minus(depositAmountBN.div(actualFundPool).times(100)).toFixed(2)}%`

        const earnedCurrent = await investmentLendingContract?.callStatic.earnedCurrent().catch(() => [0, 0])
        const sashimiBalanceOfProvider =  await sashimiContract.functions.balanceOf(investment.providerAddress).catch(() => 0)

        setSupplyProfitEthValued(new BigNumber(earnedCurrent[0].toString()).times(tokenWethPrice));
        setFarmProfitEthValued(new BigNumber(earnedCurrent[1].toString()).plus(sashimiBalanceOfProvider).times(sashimiWethPrice));

        setReservesRatio(`${reservesPoints / 100}%`)
        setDepositAmount(depositAmount)
        setActualFundUsed(actualFundUsed)
      } catch (error) {
        console.log('fetchData fail:', error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ investment, investmentContract, sashimiContract])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <ItemCard>
      <div className="coin-info">
        <img
          src={investment.icon}
          alt=""
          width={below576 ? 28 : 48}
          height={below576 ? 28 : 48}
        />
        <div className="name">
          <h2 className="ellipsis"> Invest {investment.depositTokenSymbol.toUpperCase()}</h2>
          <p className="ellipsis">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://etherscan.io/address/${investment.providerAddress}`}
            >
              Click To Review The Contract
            </a>
          </p>
        </div>
      </div>
      <div className="btn-group">
        <Spin spinning={harvestPending}>
          <Button
            type="primary"
            style={
              !supplyProfitEthValued.isEqualTo(0) || !new BigNumber(BalanceUtils.getBalanceNumber(farmProfitEthValued, 18).toFixed(8)).isEqualTo(0)
                ? {
                    ...ButtonStyle,
                    backgroundColor: '#FDE7E0',
                    color: '#EB591A',
                    borderColor: '#FDE7E0',
                    boxShadow: 'none',
                  }
                : {
                    ...ButtonStyle,
                  }
            }
            disabled={supplyProfitEthValued.isEqualTo(0) && new BigNumber(BalanceUtils.getBalanceNumber(farmProfitEthValued, 18).toFixed(8)).isEqualTo(0) }
            onClick={async () => {
              if (investmentContract) {
                try {
                  setHarvestPending(true) 
                  await investmentContract.functions.harvest(investment.depositAddress)
                } catch (error) {
                  console.error('harvest fail:', error)
                } finally {
                  setHarvestPending(false)
                }
              }
            }}
          >
            Harvest 
          </Button>
        </Spin>
        <Spin spinning={reBalancePending}>
          <Button
            type="primary"
            style={{
              ...ButtonStyle,
            }}
            // disabled={new BigNumber(supplyProfitEthValued).isEqualTo(0)}
            onClick={async () => {
              if (investmentContract) {
                try {
                  setReBalancePending(true)
                  await investmentContract.functions.reBalance(investment.depositAddress)
                } catch (error) {
                  console.error('reBalance fail:', error)
                } finally {
                  setReBalancePending(false)
                }
              }
            }}
          >
            ReBalance
          </Button>
        </Spin>
      </div>
      <div className="detail-info">
        <div className="detail-item">
          <span>Deposit</span>
          <span>
            {depositAmount.toFixed(2)} {investment.depositTokenSymbol}
          </span>
        </div>
        <div className="detail-item">
          <span>Supply Profit</span>
          <span>{BalanceUtils.getBalanceNumber(supplyProfitEthValued, 18).toFixed(8) || '-'} ETH</span>
        </div>
        <div className="detail-item">
          <span>Farm Profit</span>
          <span> {BalanceUtils.getBalanceNumber(farmProfitEthValued, 18).toFixed(8) || '-'} ETH </span>
        </div>
        <div className="detail-item">
          <span>Basic Reserves Ratio</span>
          <span>{reservesRatio}</span>
        </div>
        <div className="detail-item">
          <span>Actual Reserves Ratio</span>
          <span>{actualFundUsed}</span>
        </div>
        <div className="detail-item">
          <span>APY</span>
          <span>{investment.apyInfo.apy ? parseFloat(investment.apyInfo?.apy).toFixed(2) : '-'} %</span>
        </div>
      </div>
    </ItemCard>
  )
}
const ItemCard = styled.div`
  box-sizing: border-box;
  padding: 24px 20px 20px 20px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  .coin-info {
    box-sizing: border-box;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #f2f2f2;
    .name {
      margin-left: 36px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (max-width: 576px) {
        margin-left: 16px;
      }
      h2 {
        color: #333;
        font-size: 18px;
        line-height: 24px;
      }
      p {
        margin-top: 2px;
        color: #818690;
        line-height: 20px;
      }
    }
  }

  .btn-group {
    margin-top: 34px;
    display: flex;
    justify-content: space-between;
    .ant-spin-nested-loading {
      width: 45%;
    }
  }

  .detail-info {
    margin-top: 30px;
    .detail-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgb(129, 134, 144);
      font-size: 13px;
    }
  }
`
export default InvestmentCards
