import { Web3Provider } from '@ethersproject/providers'
import { getContract } from './index'
import { ERC20_ABI } from '../constants/abis/erc20'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
export const callERC20ViewMethod = async (
  functionName: string,
  provider: Web3Provider,
  nftAddress: string,
  paramsOption?: any
): Promise<any> => {
  try {
    const contract = getContract(nftAddress, ERC20_ABI, provider)
    if (paramsOption) {
      return await contract.callStatic[functionName](paramsOption)
    }
    return await contract.callStatic[functionName]()
  } catch (e) {
    console.log('callNFTShardsViewMethod error', e)
    return '0'
  }
}
export const getBalance = async (
  provider: Web3Provider,
  tokenAddress: string,
  userAddress: string
): Promise<string> => {
  try {
    const ERC20Contract = getContract(tokenAddress, ERC20_ABI, provider)
    const balance = await ERC20Contract.balanceOf(userAddress)
    return balance.toString()
  } catch (e) {
    return '0'
  }
}

export const checkAllowanceAndApprove = async (
  provider: Web3Provider,
  contractAddress: string,
  approveTargetAddress: string,
  account: string,
  pivotBalance: string | number
): Promise<
  | boolean
  | {
      error: Error
    }
> => {
  try {
    const erc20Contract = getContract(contractAddress, ERC20_ABI, provider, account)
    const [allowance, decimals] = await Promise.all([
      erc20Contract.allowance(account, approveTargetAddress),
      erc20Contract.decimals()
    ])
    if (allowance.error) {
      return allowance
    }

    const allowanceBN = new BigNumber(allowance.toString())
    const pivotBalanceBN = new BigNumber(pivotBalance).times(10 ** decimals)
    if (allowanceBN.lt(pivotBalanceBN)) {
      const tx = await erc20Contract.approve(approveTargetAddress, ethers.constants.MaxUint256)
      const approveResult = await tx.wait()
      if (approveResult.error) {
        return approveResult
      } else {
        return approveResult
      }
    }
    return true
  } catch (error) {
    return { error }
  }
}
