import CrossChain from '../constants/bridge/CrossChain.json'
import { Web3Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { AbiItem } from '@sashimiswap/compound-js/dist/nodejs/types'
import { getContract } from '.'
import { getNetworkLibrary, NETWORK_CHAIN_ID } from '../connectors'

const contractsABI = {
  CrossChain: CrossChain
}

interface ContractProps {
  contractName: keyof typeof contractsABI
  contractABI?: AbiItem
  provider?: Web3Provider
  contractAddress: string
  account?: string
  chainId?: number
}

interface ErrorMsg {
  error: Error
}

type InitContract = (provider: Web3Provider, address: string, ABI: AbiItem, account?: string) => Contract

type InitViewOnlyContract = (provider: Web3Provider, address: string, ABI: AbiItem) => Contract

type CallViewMethod = (functionName: string, paramsOption?: any[]) => Promise<any | ErrorMsg>

type CallSendMethod = (
  functionName: string,
  paramsOption?: any[],
  options?: {
    sendOptions?: any
    noWait?: boolean
  }
) => Promise<any | ErrorMsg>

export type ContractBasicErrorMsg = ErrorMsg
const networkLibrary = getNetworkLibrary()

export class ContractBasic {
  public contract: Contract | null
  public contractForView: Contract
  public address: string
  public provider?: Web3Provider
  public chainId?: number
  constructor(options: ContractProps) {
    const { contractName, contractABI, provider, contractAddress, account, chainId } = options
    const contactABITemp = contractABI || contractsABI[contractName]
    this.contract = provider ? this.initContract(provider, contractAddress, contactABITemp as AbiItem, account) : null
    this.contractForView = this.initViewOnlyContract(networkLibrary, contractAddress, contactABITemp as AbiItem)
    this.address = contractAddress
    this.provider = provider
    this.chainId = chainId
  }

  public initContract: InitContract = (provider, address, ABI, account) => {
    return getContract(address, ABI, provider, account)
  }
  public initViewOnlyContract: InitViewOnlyContract = (provider, address, ABI) => {
    return getContract(address, ABI, provider)
  }
  public callViewMethod: CallViewMethod = async (functionName, paramsOption) => {
    let contract = this.contractForView
    if (this.chainId === NETWORK_CHAIN_ID) contract = this.contract || this.contractForView
    try {
      return await contract[functionName](...(paramsOption || []))
    } catch (e) {
      return { error: e }
    }
  }

  public callSendMethod: CallSendMethod = async (functionName, paramsOption, options) => {
    const { sendOptions = {}, noWait } = options || {}
    if (!this.contract) return { error: { code: 401, message: 'Contract init error' } }
    const contract = this.contract
    try {
      let tx
      if (paramsOption) {
        tx = await contract[functionName](...paramsOption, sendOptions)
      } else {
        tx = await contract[functionName](sendOptions)
      }

      if (noWait) return tx

      return await tx.wait()
    } catch (e) {
      return { error: e }
    }
  }
}
