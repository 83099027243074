import React from 'react'
import { useMedia } from 'react-use'
import useStakerTop from '../../../../hooks/useStakerTop'
import formatAddress from '../../../../fuck/formatAddress'
import styled from 'styled-components'
import Card from '../../../../components/Card'
import CardTitle from '../../../../components/CardTitle'
const Stakers = () => {
  const stakerTopList = useStakerTop()
  const below576 = useMedia('(max-width:576px)')
  return (
    <>
      <StyledCard>
        <CardTitle text="Top 10 Staker">
          <SinceTime>{stakerTopList?.holderNumber} stakers</SinceTime>
        </CardTitle>
        {stakerTopList?.holderList.length
          ? stakerTopList.holderList.map((item, index) => (
              <StakerList key={index}>
                <div className="item">
                  <div className="ranking">
                    <span className={index < 3 ? 'top' : ''}>{index + 1}</span>
                    {!below576 ? formatAddress(item?.holder) : ''}
                  </div>
                  <div className="amount">{item?.quantity} xSASHIMI</div>
                </div>
              </StakerList>
            ))
          : null}
      </StyledCard>
    </>
  )
}

const StyledCard = styled(Card)`
  min-width: 470px;
  height: 617px;
  margin-bottom: 60px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 0 24px 34px 24px;
  @media screen and (max-width: 576px) {
    min-width: initial;
  }
`

const SinceTime = styled.span`
  color: #999;
  font-size: 14px;
  font-weight: 400;
`
const StakerList = styled.div`
  min-width: 420px;
  @media screen and (max-width: 576px) {
    min-width: initial;
  }
  &:nth-child(2) {
    margin-top: 22px;
  }
  &:nth-child(even) {
    background: rgba(18, 18, 18, 0.05);
  }
  .item {
    box-sizing: border-box;
    padding: 0 12px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ranking {
      display: flex;
      align-items: center;
      span {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 12px;
        font-size: 16px;
        color: #fff;
        background-color: #afaba8;
        text-align: center;
        line-height: 24px;
        &.top {
          background-color: #eb591a;
        }
      }
    }
    .amount {
      font-size: 12px;
      color: #818690;
    }
  }
`
export default Stakers
