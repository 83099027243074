import React from 'react'
import styled from 'styled-components'
import MainContainer from '../../components/MainContainer'
import Card from '../../components/Card'
import CardTitle from '../../components/CardTitle'
import CardContent from '../../components/CardContent'
import MinHeight from '../../components/MinHeight'
import InvestmentCards from './components/InvestmentCards'

const Investment: React.FC = () => {
  return (
    <MainContainer>
      <Card>
        <MinHeight>
          <CardContent>
            <CardTitle text="InvestmentLending" />
            <ListWrap>
              <InvestmentCards />
            </ListWrap>
          </CardContent>
        </MinHeight>
      </Card>
    </MainContainer>
  )
}

const ListWrap = styled.div`
  padding: 30px 40px 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 360px 360px 360px;
  grid-gap: 34px 34px;
  @media screen and (max-width: 576px) {
    padding: 16px 0;
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 360px 360px;
    grid-gap: 24px 24px;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 360px;
    grid-gap: 14px 14px;
  }
`
export default Investment
