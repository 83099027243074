/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import { useHistory } from 'react-router-dom'
import CoinLogo from '../../components/CoinLogo'
import Notice from '../../components/Notice'
import { Button, Checkbox, Divider, Spin } from 'antd'
import { useSelector } from 'react-redux'
import { AppState } from '../../state'
import { FarmPoolApyInfo, FarmPoolInfo, LpSourceType } from '../../state/farm-pool/actions'
import { PairData, PairUtils } from '../../state/exchange/entities/pair_entity'
import { useEtherPrice, usePairByAddress } from '../../state/global/hooks'
import { isEthAddress } from '../../utils'
import { AddressZero } from '@ethersproject/constants'
import BalanceUtils from '../../fuck/formatBalance'
import { LogoUtils } from '../../fuck/logoUtils'

const ButtonStyle = {
  width: '160px',
  height: '46px',
  borderRadius: '10px',
  fontSize: '16px',
}

const FarmCard: React.FC<{ pool: FarmPoolInfo; apyInfo: FarmPoolApyInfo | undefined }> = ({
  pool,
  apyInfo,
}: {
  pool: FarmPoolInfo
  apyInfo: FarmPoolApyInfo | undefined
}) => {
  const pairAddress: string = pool.lpAddress.toLocaleLowerCase()
  const pairData: PairData = usePairByAddress(pairAddress)
  const lpBarsInfo = useSelector<AppState, AppState['lpBar']['lpBarsInfo']>((state) => state.lpBar.lpBarsInfo)
  const vaults = useSelector<AppState, AppState['vault']['vaults']>((state) => state.vault.vaults)

  const history = useHistory()
  const handler = useCallback(() => {
    switch (pool.lpSourceType) {
      case LpSourceType.LPBAR_FARM:
        if (!lpBarsInfo) return
        if (lpBarsInfo[pool.lpAddress]) {
          window.open(`https://uniswap.info/pair/${lpBarsInfo[pool.lpAddress].lpAddress}`, '_blank')
        }
        break
      case LpSourceType.SASWAP_FARM:
        history.push({
          pathname: `/exchange/swap/${isEthAddress(pairData?.token0?.id)}/${isEthAddress(pairData?.token1?.id)}`,
          state: pairAddress,
        })
        break
      case LpSourceType.VAULT_FARM:
        history.push(`/savault`)
        break
    }
  }, [pool, pairData, lpBarsInfo, pairAddress, history])

  const { now } = useEtherPrice()
  const poolValue = BalanceUtils.formattedNum((apyInfo?.valueInEth ?? 0) * now, true)
  let apyStr = '--'
  // if (!!apyInfo && apyInfo.apy1 > 0) {
  //   apyStr = parseFloat((apyInfo.apy1 + apyInfo.apy2).toString()).toFixed(2) + '%'
  // }
  // the apy only according to sashimi
  if (!!apyInfo && apyInfo.apy1 > 0) {
    apyStr = parseFloat(apyInfo.apy1.toString()).toFixed(2) + '%'
  }
  let url: string[] = []
  switch (pool.lpSourceType) {
    case LpSourceType.SASWAP_FARM:
      url = PairUtils.getPairLogo(pairData)
      break
    case LpSourceType.VAULT_FARM:
      const vault = vaults[pool.lpAddress]
      if (vault) {
        url = vault.icon.map((address) => LogoUtils.getTokenLogo(address))
      }
      break
    case LpSourceType.LPBAR_FARM:
      url = pool.lpCoinUrls.map((addr) => LogoUtils.getTokenLogo(addr))
      break
  }
  const below576 = useMedia('(max-width:576px)')
  return (
    <div className="item">
      <div className="coin-info">
        <CoinLogo size={below576 ? 28 : 48} url={url} />
        <div className="name">
          <h2 className="ellipsis"> {/*pool.name +*/ pool.lpSymbol}</h2>
          <p className="ellipsis">Reward: {parseFloat(pool.sashimiPerBlock.toString()).toFixed(5)} SASHIMI per block</p>
        </div>
      </div>

      <div className="num-info">
        <span>
          <p>Pool Value</p>
          {/*<h2>{poolValue}</h2>*/}
          <h2>--</h2>
        </span>
        <span>
          <p>Staked {pool.lpSourceType === LpSourceType.VAULT_FARM ? pool.lpSymbol : 'SALP' }</p>
          <h2>
            {!!apyInfo ? parseFloat(!!apyInfo ? apyInfo.lpStakeAmount : '').toLocaleString('currency', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            }): '--'}
          </h2>
        </span>
        <span>
          <p>APY</p>
          <h2>{apyStr}</h2>
        </span>
      </div>

      <div className="btn-group">
        <Button
          onClick={() => history.push(`/farm/${pool.lpSymbol}/${pool.lpAddress}/${pool.pid}`)}
          type="primary"
          style={{
            ...ButtonStyle,
            backgroundColor: '#FDE7E0',
            color: '#EB591A',
            borderColor: '#FDE7E0',
            boxShadow: 'none',
          }}
          disabled={pool.advanceLine}
        >
          Select to redeem
        </Button>
        {/*<Button*/}
        {/*  onClick={handler}*/}
        {/*  type="primary"*/}
        {/*  style={{*/}
        {/*    ...ButtonStyle,*/}
        {/*    color: pool.advanceLine ? "#EB591A" : '#fff',*/}
        {/*    boxShadow: 'rgba(232, 97, 54, 0.3) 0px 5px 10px 0px',*/}
        {/*  }}*/}
        {/*  disabled={pool.advanceLine}*/}
        {/*>*/}
        {/*  Get SALP*/}
        {/*</Button>*/}
      </div>
    </div>
  )
}

const Farms = () => {
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      window.scrollTo(0, node.offsetTop)
      // node.getBoundingClientRect().height
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tabIndex, setTabIndex] = useState(0)
  // const [showNoProfit, setShowNoProfit] = useState(false)
  const [showNoProfit, setShowNoProfit] = useState(true)
  // const [ farmApy, setFarmApy ] = useState([])

  //TODO danie 每次变化，影响性能
  // const handleChangeTab = (index: number) => {
  //   setTabIndex(index)
  // }

  const farmState = useSelector<AppState, AppState['poolInfos']>((state) => state.poolInfos)
  const farmPools = farmState.pools
  const farmAPys = farmState.apys
  const poolArray = useMemo(() => {
    const normalPools: FarmPoolInfo[] = []
    const noProfitPools: FarmPoolInfo[] = []
    const dobuleFarmPools: FarmPoolInfo[] = []
    Object.keys(farmPools).forEach((key) => {
      const index = Number(key)
      const valuePool = farmPools[index]
      // console.log(valuePool)
      // valuePool['apyInfo'] = farmAPys[valuePool.pid] || 0
      // console.log(farmAPys[valuePool.pid])

      //去除1、lp为0的burn 2、人工隐藏
      if (valuePool.lpAddress !== AddressZero && !valuePool.isHide) {
        if (valuePool.lpSourceType === LpSourceType.LPBAR_FARM) {
          dobuleFarmPools.push({ ...valuePool, apyInfo: farmAPys[valuePool.pid] })
        } else {
          if (valuePool.sashimiPerBlock === 0) {
            noProfitPools.push({ ...valuePool, apyInfo: farmAPys[valuePool.pid] })
          } else {
            normalPools.push({ ...valuePool, apyInfo: farmAPys[valuePool.pid] })
          }
        }
      }
    })
    const sortFunc = (a: FarmPoolInfo, b: FarmPoolInfo) =>
      b.apyInfo?.apy1 + b.apyInfo?.apy2 - (a.apyInfo?.apy1 + a.apyInfo?.apy2)
    normalPools.sort(sortFunc)
    noProfitPools.sort(sortFunc)
    dobuleFarmPools.sort(sortFunc)
    return [normalPools, noProfitPools, dobuleFarmPools]
  }, [farmPools, farmAPys])

  const selectPool = tabIndex === 0 ? poolArray[0] : poolArray[2]
  const showMore = tabIndex === 0 && showNoProfit && poolArray[1].length
  const below576 = useMedia('(max-width:576px)')
  return (
    <>
      <Notice
        text="Instruction: Please remove your SALP"
      />
      {/* <Wrap>
        <NavList>
          <span onClick={() => handleChangeTab(0)} className={!tabIndex ? 'active' : ''}>
            Ordinary Farming <i>NEW</i>
          </span>
          <span onClick={() => handleChangeTab(1)} className={tabIndex ? 'active' : ''}>
            Double Farming
          </span>
        </NavList>
        <div style={{ marginTop: '30px' }}>
          <Notice
            text="Instruction: Add liquidity to Sashimiswap to get SALP. For example you add eth-usdt liquidity to get ETH-USDT
            SALP"
          />
        </div>
      </Wrap> */}
      {selectPool.length ? (
        <ListWrap>
          {selectPool.map((value) => (
            <FarmCard pool={value} apyInfo={value?.apyInfo ?? undefined} key={value.pid} />
          ))}
        </ListWrap>
      ) : (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
      )}

      {showMore ? (
        <>
          <div ref={measuredRef}>
            <Divider
              orientation="left"
              plain
              style={{
                fontSize: below576 ? '15px' : '20px',
                padding: below576 ? '30px 10px 0 10' : '40px 40px 0 40px',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              Pools with no profit of sashimi temporarily
            </Divider>
          </div>
          <ListWrap>
            {showMore
              ? poolArray[1].map((value) => {
                  return <FarmCard pool={value} apyInfo={farmAPys[value.pid] ?? undefined} key={value.pid} />
                })
              : null}
          </ListWrap>
        </>
      ) : null}
      {tabIndex === 0 ? (
        <CheckboxWrap>
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <Checkbox
            checked={showNoProfit}
            className="checkbox"
            onChange={(event) => {
              setShowNoProfit(event.target.checked)
            }}
          >
            Show Inactive Farms
          </Checkbox>
        </CheckboxWrap>
      ) : null}
    </>
  )
}
// const Wrap = styled.div`
//   padding: 30px 40px 0 40px;
//   @media screen and (max-width: 576px) {
//     padding: 16px;
//   }
// `
// const NavList = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-radius: 10px;
//   max-width: 470px;
//   height: 46px;
//   overflow: hidden;
//   span {
//     flex: 1;
//     background-color: #fff;
//     color: #eb591a;
//     line-height: 46px;
//     text-align: center;
//     font-weight: 600;
//     font-size: 18px;
//     cursor: pointer;
//     &.active {
//       background-color: #eb591a;
//       color: #fff;
//     }
//     position: relative;
//     i {
//       position: absolute;
//       top: 0;
//       right: 0;
//       display: block;
//       line-height: 16px;
//       color: #fff;
//       background-color: #6dd154;
//       border-radius: 0 10px 0 10px;
//       padding: 0 6px;
//       font-size: 12px;
//     }
//   }
// `

// const Notice = styled.div`
//   margin-top: 10px;
//   line-height: 40px;
//   height: 40px;
//   overflow: hidden;
//   color: #eb591a;
//   background-color: #ffeadb;
//   text-indent: 30px;
// `

const ListWrap = styled.div`
  /* box-sizing: border-box;
  margin-top: 30px;
  flex-wrap: wrap;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 0 26px; */

  padding: 30px 40px 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 300px 300px 300px;
  grid-gap: 34px 34px;
  /* width:100%; */
  /* margin-left:-25px; */
  @media screen and (max-width: 576px) {
    padding: 16px;
  }
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 300px 300px;
    grid-gap: 24px 24px;
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 300px;
    grid-gap: 14px 14px;
  }

  .item {
    /* flex: 1; */
    /* min-width: 360px; */
    /* height: 300px; */
    /* margin: 0 14px; */
    /* margin-bottom: 30px; */

    box-sizing: border-box;
    padding: 24px 20px 20px 20px;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  }
  .coin-info {
    box-sizing: border-box;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #f2f2f2;
    .name {
      margin-left: 36px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (max-width: 576px) {
        margin-left: 16px;
      }
      h2 {
        color: #333;
        font-size: 18px;
        line-height: 24px;
      }
      p {
        margin-top: 2px;
        color: #818690;
        line-height: 20px;
      }
    }
  }

  .num-info {
    margin-top: 30px;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    span {
      &:first-child {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
      p {
        color: #818690;
        line-height: 20px;
      }
      h2 {
        margin-top: 12px;
        color: #eb591a;
        font-size: 22px;
        @media screen and (max-width: 576px) {
          font-size: 16px;
        }
      }
    }
  }

  .btn-group {
    margin-top: 34px;
    display: flex;
    justify-content: space-around;
  }
`

const CheckboxWrap = styled.div`
  padding-left: 40px;
  padding-bottom: 30px;
  margin-top: 25px;
  .checkbox {
    color: #9da2ab;
  }
`
export default Farms
