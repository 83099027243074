import React, { useCallback, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { Button } from 'antd'
import styled from 'styled-components'
import Modal from '../Modal'
import ModalActions from '../ModalActions'
import ModalTitle from '../ModalTitle'
import TokenInput from '../TokenInput'
import BalanceUtils from '../../fuck/formatBalance'

export interface WithdrawModalProps {
  isOpen: boolean
  max: BigNumber
  onConfirm: (amount: string) => void
  onDismiss: () => void
  tokenName?: string
  modalTitle?: string
  decimal?: number
}

const StyledModal = styled.div`
  width: 100%;
  padding: 0 20px;
`
const WithdrawModal: React.FC<WithdrawModalProps> = ({
  isOpen,
  onConfirm,
  onDismiss,
  max,
  tokenName = '',
  modalTitle = '',
  decimal = 0
}: WithdrawModalProps) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)

  const fullBalance = useMemo(() => {
    return BalanceUtils.getFullDisplayBalance(max, decimal)
  }, [max, decimal])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal]
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  const handleDismiss = useCallback(() => null, [])

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss}>
      <StyledModal>
        <ModalTitle text={modalTitle || `Withdraw ${tokenName}`} />
        <TokenInput
          onSelectMax={handleSelectMax}
          onChange={handleChange}
          value={val}
          max={fullBalance}
          symbol={tokenName}
        />
        <ModalActions>
          <Button block size="large" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            block
            size="large"
            type="primary"
            disabled={pendingTx}
            onClick={async () => {
              try {
                setPendingTx(true)
                await onConfirm(val)
              } finally {
                setPendingTx(false)
              }
              onDismiss()
            }}
          >
            {pendingTx ? 'Pending Confirmation' : 'Confirm'}
          </Button>
        </ModalActions>
      </StyledModal>
    </Modal>
  )
}

export default WithdrawModal
