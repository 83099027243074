import React, { useState } from 'react'
import { Button, InputNumber, Spin } from 'antd'
import BalanceUtils from '../../../../fuck/formatBalance'
import { useWalletModalToggle } from '../../../../state/application/hooks'
import { useSashimiBarContract } from '../../../../hooks/useContract'
import { useContractHandler, useUserBalanceByContract } from '../../../../hooks/useSashimi'
import { useWeb3React } from '@web3-react/core'

const ButtonStyle = {
  width: '112px',
  height: '46px',
  fontSize: '16px',
  borderRadius: '10px',
  marginLeft: '12px'
}

const UnStake = () => {
  const toggleWalletModal = useWalletModalToggle()
  const [unStakeAmount, setUnStakeAmount] = useState<number>(0)
  const [pending, setPending] = useState(false)

  const { account } = useWeb3React()
  const sashimiBarContract = useSashimiBarContract()
  const sashimiBarHandler = useContractHandler(sashimiBarContract)

  const tokenBalance = useUserBalanceByContract(sashimiBarContract)

  const unlockButton = (
    <Button
      type="primary"
      style={ButtonStyle}
      block
      onClick={() => {
        toggleWalletModal()
      }}
    >
      Unlock
    </Button>
  )

  const unstakeButton = (
    <Spin spinning={pending}>
      <Button
        style={ButtonStyle}
        disabled={!tokenBalance.toNumber() || tokenBalance.isEqualTo(0)}
        type="primary"
        block
        onClick={async () => {
          if (!unStakeAmount || unStakeAmount <= 0) {
            return
          }
          try {
            setPending(true)
            await sashimiBarHandler.leave(unStakeAmount, 18)
          } catch (error) {
            console.log(error)
          } finally {
            setPending(false)
          }
        }}
      >
        UNSTAKE
      </Button>
    </Spin>
  )
  return (
    <>
      <div className="input-wrap">
        <div className="input">
          <InputNumber
            className="input-number"
            value={unStakeAmount}
            placeholder="0"
            onChange={value => {
              const amount = parseFloat(value?.toString() ?? '0') ?? 0
              setUnStakeAmount(amount)
            }}
            max={tokenBalance.toNumber()}
            min={0}
          />
          <i
            onClick={() => {
              setUnStakeAmount(BalanceUtils.getBalanceNumber(tokenBalance))
            }}
          >
            MAX
          </i>
        </div>
        {account ? unstakeButton : unlockButton}
      </div>
      <p className="balance">
        My xSASHIMI: <strong>{BalanceUtils.getBalanceNumber(tokenBalance)}</strong>
      </p>
    </>
  )
}
export default UnStake
