import React from 'react'
import styled from 'styled-components'
import Card from '../../../../components/Card'
import CardContent from '../../../../components/CardContent'
import CardTitle from '../../../../components/CardTitle'
import DynamicIcon from '../../../../assets/images/dynamic_icon.png'
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
// import BigNumber from 'bignumber.js'
import BalanceUtils from '../../../../fuck/formatBalance'
import ArrowImg from '../../../../assets/images/arrow.png'
import { useSashimiDynamicInfo } from '../../../../state/overview/hooks'
import CountUpAni from '../../../../components/CountUpAni'

/**
 * 将BigNumber转成额度字符串
 **/
// function balanceStrByNumber(balance: BigNumber, defaultValue?: string): string {
//   if (!balance) return defaultValue || ''
//   return (
//     BalanceUtils.getBalanceNumber(balance).toLocaleString('currency', {
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2
//     }) ||
//     defaultValue ||
//     ''
//   )
// }

const DynamicData: React.FC = () => {
  const {
    sashimiOfBurned,
    sashimiOfTreasury,
    distributedNumber,
    undistributedNumber,
    distributedPercentageNumber,
    undistributedPercentageNumber
  } = useSashimiDynamicInfo()

  // 总发行量, 已分配数量, 未分配
  const maximum = '100M'
  // const distributed: string = balanceStrByNumber(distributedNumber, '0.00')
  // const undistributed: string = balanceStrByNumber(undistributedNumber, '0.00')
  const distributedPercentage: string = distributedPercentageNumber?.toFixed(2) ?? '0.00'
  const undistributedPercentage: string = undistributedPercentageNumber?.toFixed(2) ?? '0.00'

  // 已销毁、国库、流通
  // const burnedQty: string = balanceStrByNumber(sashimiOfBurned, '0.00')
  // const treasuryQty: string = balanceStrByNumber(sashimiOfTreasury, '0.00')
  // const circulatingQty: string = balanceStrByNumber(
  //   distributedNumber?.minus(sashimiOfBurned)?.minus(sashimiOfTreasury),
  //   '0.00'
  // )
  const circulatingQty = distributedNumber?.minus(sashimiOfBurned)?.minus(sashimiOfTreasury)
  return (
    <CardWrap>
      <CardContent>
        <CardTitle
          text="SASHIMI Tokens (Dynamic Data)"
          icon={<img alt="Dynamic" src={DynamicIcon} style={{ width: '31px' }} />}
        />
        <Container>
          <Maximun>
            <h1>{maximum}</h1>
            <p>
              Maximum
              <br />
              SASHIMI Supply
            </p>
          </Maximun>

          <DistributionWrap>
            <DistributionItem>
              <h2>
                <CountUpAni num={BalanceUtils.getBalanceNumber(distributedNumber)} decimal={0} />
              </h2>
              <p>Distributed ({distributedPercentage}%)</p>
            </DistributionItem>
            <PlusOutlined style={{ fontSize: '20px', color: '#eb591a', margin: '12px 0' }} />
            <DistributionItem>
              <h2>
                <CountUpAni num={BalanceUtils.getBalanceNumber(undistributedNumber)} decimal={0} />
              </h2>
              <p>Undistributed ({undistributedPercentage}%)</p>
            </DistributionItem>
          </DistributionWrap>

          <DistributionDetailWrap>
            <DistributionDetailItem className="first">
              <div className="item">
                <span>Burned:</span>
                <strong
                  onClick={() =>
                    window.open(
                      'https://etherscan.io/address/0x000000000000000000000000000000000000dead#code',
                      '_blank'
                    )
                  }
                >
                  {/* {burnedQty} */}
                  <CountUpAni num={BalanceUtils.getBalanceNumber(sashimiOfBurned)} decimal={0} />
                  <QuestionCircleOutlined style={{ color: '#AAAAAA', marginLeft: '10px', cursor: 'pointer' }} />
                </strong>
              </div>
              <div className="item">
                <span> Treasury:</span>
                <strong
                  onClick={() =>
                    window.open(
                      'https://etherscan.io/address/0x84ee348617563944ffd4a23843e086a7dc0224f3#code', //TODO danie
                      '_blank'
                    )
                  }
                >
                  {/* {treasuryQty} */}
                  <CountUpAni num={BalanceUtils.getBalanceNumber(sashimiOfTreasury)} decimal={0} />
                  <QuestionCircleOutlined style={{ color: '#AAAAAA', marginLeft: '10px', cursor: 'pointer' }} />
                </strong>
              </div>
              <div className="item">
                <span>Circulating:</span>
                <strong>
                  <CountUpAni num={BalanceUtils.getBalanceNumber(circulatingQty)} decimal={0} />
                </strong>
              </div>
            </DistributionDetailItem>
            <DistributionDetailItem className="second">
              <strong>
                {' '}
                10 SASHIMI / block until 2021/8/3
                {/* <QuestionCircleOutlined style={{ color: '#AAAAAA', marginLeft: '10px', cursor: 'pointer' }} /> */}
              </strong>
            </DistributionDetailItem>
          </DistributionDetailWrap>
        </Container>
      </CardContent>
    </CardWrap>
  )
}

const Arrow = styled.div`
  position: relative;
  &::after {
    display: block;
    content: '';
    width: 102px;
    height: 13px;
    background: url(${ArrowImg}) right center no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -170px;
    @media screen and (max-width: 1550px) {
      right: -128px;
    }
  }
`
const CardWrap = styled(Card)`
  overflow-x: auto;
  padding-bottom: 50px;
`
const Container = styled.div`
  width: 1180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1550px) {
    width: 980px;
  }
  h1,
  h2,
  strong {
    color: #eb591a;
  }
  h1 {
    font-size: 46px;
  }
  h2 {
    font-size: 24px;
    font-weight: 500;
  }
  p {
    color: #4e5055;
    font-size: 14px;
  }
`

const Maximun = styled(Arrow)`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 186px;
  height: 186px;
  background-color: #fff;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  h2 {
    line-height: 1;
  }
`
// 总量分布
const DistributionWrap = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const DistributionItem = styled(Arrow)`
  width: 210px;
  height: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  h2 {
    line-height: 28px;
  }
  p {
    margin-top: 6px;
    line-height: 16px;
  }
`

// 分布详细
const DistributionDetailWrap = styled(DistributionWrap)``
const DistributionDetailItem = styled.div`
  width: 294px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  .item {
    padding-left: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    line-height: 20px;
    margin-top: 18px;
    font-weight: 500;
    & > span {
      width: 90px;
      font-size: 14px;
      color: #121212;
      font-weight: 600;
    }
    strong {
      display: flex;
      align-items: center;
    }
  }

  &.first {
    height: 130px;
    padding-left: 22px;
  }
  &.second {
    margin-top: 16px;
    padding: 24px 0 24px 22px;
  }
`
export default DynamicData
