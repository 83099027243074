import { message } from 'antd'
import BigNumber from 'bignumber.js'
import i18next from 'i18next'
import { isAddress } from '@ethersproject/address'
import { checkAllowanceAndApprove } from '../../../utils/erc20'
import config from '../config'
import { Web3Provider } from '@ethersproject/providers'
import { ContractBasic } from '../../../utils/contract'

const { CrossChainAddress } = config

export const addressValidator = (_: unknown, value: string): any => {
  if (!isAddress(value)) {
    return Promise.reject(new Error(i18next.t('Invalid address')))
  }
  return Promise.resolve()
}
export const getMillisecond = (time: any): any => {
  const { seconds } = time || {}
  const tim = seconds || time
  if (String(tim).length <= 10) {
    return tim * 1000
  }
  if (typeof tim !== 'number') {
    return Number(tim)
  }
  return tim
}
export const isUTCToday = (str: string | number): boolean => {
  const newDate = new Date()
  const lastDate = new Date(getMillisecond(str))
  if (
    newDate.getUTCDate() === lastDate.getUTCDate() &&
    newDate.getUTCFullYear() === lastDate.getUTCFullYear() &&
    newDate.getUTCMonth() === lastDate.getUTCMonth()
  ) {
    return true
  }
  return false
}
export const isToday = (str: string | number): boolean => {
  if (new Date(getMillisecond(str)).toDateString() === new Date().toDateString()) {
    return true
  }
  return false
}

export const checkCrossChainTransfer = async (
  contract: ContractBasic,
  ethereum: Web3Provider,
  account: string,
  address: string,
  userBalanceBN: number | string,
  timesDigits: (v: BigNumber | string | undefined) => BigNumber,
  divDigits: (v: BigNumber | string | undefined) => BigNumber,
  amount: string,
  toChainID: number
): Promise<any> => {
  const [reqFee, maxAmountPerDay, sendTotalAmount, timestamp] = (
    await Promise.all([
      contract.callViewMethod('fee', [toChainID]),
      // contract.callViewMethod("maxAmount", [address]),
      contract.callViewMethod('maxSendAmountPerDay', [address]),
      contract.callViewMethod('sendTotalAmount', [address]),
      contract.callViewMethod('timestamp')
    ])
  ).map(i => (i._isBigNumber ? i.toString() : i))
  if (new BigNumber(reqFee).gt(userBalanceBN)) {
    message.error(i18next.t('Insufficient Fees'))
    return Promise.resolve(false)
  }
  const send = isUTCToday(timestamp) ? sendTotalAmount : 0
  const sendAmount = divDigits(new BigNumber(maxAmountPerDay)?.minus(send))
  if (new BigNumber(amount).gt(sendAmount)) {
    message.error(
      i18next.t('Insufficient Contract Balance', {
        balance: sendAmount.toFixed()
      })
    )
    return Promise.resolve(false)
  }

  // if (new BigNumber(amount).lte(timesDigits(maxAmount))) {
  //   setLoading(false);
  //   getCrossChainInfo();
  //   return message.error(t("maxAmount", { balance: dBalance }));
  // }

  const approveResult = await checkAllowanceAndApprove(ethereum, address, CrossChainAddress, account, amount)
  if (typeof approveResult !== 'boolean' && approveResult.error) {
    message.error(i18next.t('Check allowance and Approved failed'))
    message.error(approveResult.error.message)
    return Promise.resolve(false)
  }

  return Promise.resolve([reqFee])
}
export const getHoursOffset = (): number => {
  return 0 - new Date().getTimezoneOffset() / 60
}
