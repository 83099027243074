import { useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'

import { useMasterChefContract } from './useContract'
import { useContractHandler } from './useSashimi'

const useStakedBalance = (pid?: number): BigNumber => {
  const [balance, setBalance] = useState(new BigNumber(0))

  const masterChefContract = useMasterChefContract()
  const handler = useContractHandler(masterChefContract)

  useEffect(() => {
    if (pid && handler) {
      handler.getStake(pid).then(data => {
        if (data) {
          setBalance(data)
        }
      })
    }
  }, [pid, handler, setBalance])

  return balance
}

export default useStakedBalance
