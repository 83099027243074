import React from 'react'
import styled from 'styled-components'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import CardTitle from '../../components/CardTitle'
import Notice from '../../components/Notice'
import MainContainer from '../../components/MainContainer'
import StakingPool from './components/StakingPool'
import Stakers from './components/Stakers'
import MinHeight from '../../components/MinHeight'
const Staking: React.FC = () => {
  return (
    <MainContainer>
      <Card>
        <MinHeight>
          <CardContent>
            <CardTitle text="Staking" />
            <NoticeWrap>
              <Notice text="Instruction: Staking is the pool to get more SASHIMI and governance voting rights. " />
            </NoticeWrap>
            <StakingWrap>
              <div className="staking-item">
                <StakingPool />
              </div>
              <div className="staking-item">
                <Stakers />
              </div>
            </StakingWrap>
          </CardContent>
        </MinHeight>
      </Card>
    </MainContainer>
  )
}

const NoticeWrap = styled.div`
  margin-top: 10px;
`
const StakingWrap = styled.div`
  padding: 94px 0 60px 0;
  @media screen and (max-width: 576px) {
    padding: 30px 0 20px 0;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .staking-item {
    flex: 1;
    margin: 0 38px;
    @media screen and (max-width: 576px) {
      margin: 0;
    }
  }
`
export default Staking
