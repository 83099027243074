import React from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-use'
import formatAddress from '../../fuck/formatAddress'
import useTokenBalance from '../../hooks/useTokenBalance'

import CountUpAni from '../CountUpAni'
import { useWeb3React } from '@web3-react/core'
import BalanceUtils from '../../fuck/formatBalance'
import { useSashimiBarContract, useSashimiContract } from '../../hooks/useContract'
import { Contract } from 'ethers'
import { useAllPendingSashimi } from '../../state/farm-pool/hooks'

const AccountInfo: React.FC = () => {
  const { account }: { account?: any } = useWeb3React()
  const sashimiContract: Contract | null = useSashimiContract()
  const sashimiBalance = useTokenBalance(sashimiContract?.address ?? '')
  const sashimiBarContract: Contract | null = useSashimiBarContract()
  const xSashimiBalance = useTokenBalance(sashimiBarContract?.address ?? '')
  const allPendings = useAllPendingSashimi()
  // const below576 = useMedia('(max-width:576px)')
  const below576 = useMedia('(max-width:768px)')
  return (
    <>
      {!below576 && (
        <AccountInfoWrap>
          <AccountInfoItem className="account">
            <span className="name">
              Account: <strong>{formatAddress(account)}</strong>
            </span>
          </AccountInfoItem>
          <AccountInfoItem>
            <span className="name">SASHIMI</span>
            <span className="num">
              <CountUpAni num={BalanceUtils.getBalanceNumber(sashimiBalance)} />
            </span>
          </AccountInfoItem>
          <AccountInfoItem>
            <span className="name">xSASHIMI</span>
            <span className="num">
              <CountUpAni num={BalanceUtils.getBalanceNumber(xSashimiBalance)} />
            </span>
          </AccountInfoItem>
          <AccountInfoItem>
            <span className="name">Pending SASHIMI</span>
            <span className="num">
              <CountUpAni num={allPendings?.toNumber()} />
            </span>
          </AccountInfoItem>
        </AccountInfoWrap>
      )}
    </>
  )
}

const AccountInfoWrap = styled.div`
  box-sizing: border-box;
  padding: 10px 20px 8px 30px;
  width: 100%;
  background-color: #f9f9f9;
`
const AccountInfoItem = styled.div`
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 16px;
  font-size: 14px;
  &.account {
    margin-bottom: 20px;
    font-weight: 500;
    .name {
      color: #333;
    }
  }
  strong {
    color: #1a1a1a;
  }
  span.name {
    color: #666;
  }
  span.num {
    color: #eb591a;
    font-weight: 500;
    font-size: 14px;
  }
`

export default AccountInfo
