/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ResponsiveContainer } from 'recharts'
import { useGlobalChartData, useGlobalStatisticsData, useTVLChartData } from '../../state/global/hooks'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Chart, { CHART_TYPES } from './chartView'
dayjs.extend(utc)
const LoadingWrap = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  ALL_TIME: 'All time'
}
function getTimeframe(timeWindow: string) {
  const utcEndTime = dayjs.utc()
  // based on window, get starttime
  let utcStartTime
  switch (timeWindow) {
    case timeframeOptions.WEEK:
      utcStartTime =
        utcEndTime
          .subtract(1, 'week')
          .endOf('day')
          .unix() - 1
      break
    case timeframeOptions.MONTH:
      utcStartTime =
        utcEndTime
          .subtract(1, 'month')
          .endOf('day')
          .unix() - 1
      break
    case timeframeOptions.ALL_TIME:
      utcStartTime =
        utcEndTime
          .subtract(1, 'year')
          .endOf('day')
          .unix() - 1
      break
    default:
      utcStartTime =
        utcEndTime
          .subtract(1, 'year')
          .startOf('year')
          .unix() - 1
      break
  }
  return utcStartTime
}
const CHART_VIEW = {
  VOLUME: 'Volume',
  LIQUIDITY: 'Liquidity'
}

const VOLUME_WINDOW = {
  WEEKLY: 'WEEKLY',
  DAYS: 'DAYS'
}

const StyledPeriod = styled.div`
  display: inline-block;
  font-weight: 500;
  width: 24px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  white-space: nowrap;
  border-radius: 6px;
  border: 1px solid #f8f8f8;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  &.active,
  :hover {
    background-color: #f2f2f2;
  }
`

const GlobalChart: React.FC<{ display: string }> = ({ display }) => {
  // chart options
  const [chartView] = useState(display === 'volume' ? CHART_VIEW.VOLUME : CHART_VIEW.LIQUIDITY)

  // time window and window size for chart
  const timeWindow = timeframeOptions.ALL_TIME
  const [volumeWindow, setVolumeWindow] = useState(VOLUME_WINDOW.DAYS)

  // global historical data
  const chartData = useGlobalChartData()
  const dailyData = chartData?.[0]
  const TVLChartData = useTVLChartData()  
  const TVLData = TVLChartData[0] ?? []
  const TVLChange = TVLChartData[1] ?? 0;
  const LqBase = TVLData[TVLData.length-1]?.totalValueLocked ?? 0
  const weeklyData = chartData?.[1]
  const {
    // totalLiquidityUSD,
    oneDayVolumeUSD,
    volumeChangeUSD,
    // liquidityChangeUSD,
    oneWeekVolume,
    weeklyVolumeChange
  } = useGlobalStatisticsData()
  // const TVLChange = chartData?.[2] || liquidityChangeUSD

  // based on window, get starttim
  const utcStartTime = getTimeframe(timeWindow)

  const chartDataFiltered = useMemo(() => {
    const currentData = volumeWindow === VOLUME_WINDOW.DAYS ? dailyData : weeklyData
    return (
      currentData &&
      Object.keys(currentData)
        ?.map(key => {
          const item = currentData[key]
          if (item.date > utcStartTime) {
            return item
          } else {
            return
          }
        })
        .filter(item => {
          return !!item
        })
    )
  }, [dailyData, utcStartTime, volumeWindow, weeklyData])

  // update the width on a window resize
  const ref = useRef<any>()
  const isClient = typeof window === 'object'
  const [width, setWidth] = useState<any>(ref?.current?.container?.clientWidth)
  useEffect((): any => {
    if (!isClient) {
      return false
    }
    function handleResize() {
      setWidth(ref?.current?.container?.clientWidth ?? width)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isClient, width]) // Empty array ensures that effect is only run on mount and unmount

  return chartDataFiltered ? (
    <>
      {chartDataFiltered && chartView === CHART_VIEW.LIQUIDITY && (
        <ResponsiveContainer aspect={60 / 30}>
          <Chart
            data={TVLData}
            base={LqBase}
            baseChange={TVLChange}
            title=""
            field="totalValueLocked"
            width={width}
            type={CHART_TYPES.AREA}
          />
        </ResponsiveContainer>
      )}
      {chartDataFiltered && chartView === CHART_VIEW.VOLUME && (
        <ResponsiveContainer aspect={60 / 30}>
          <Chart
            data={chartDataFiltered}
            base={volumeWindow === VOLUME_WINDOW.WEEKLY ? oneWeekVolume : oneDayVolumeUSD}
            baseChange={volumeWindow === VOLUME_WINDOW.WEEKLY ? weeklyVolumeChange : volumeChangeUSD}
            title={volumeWindow === VOLUME_WINDOW.WEEKLY ? 'Volume (7d)' : 'Volume'}
            field={volumeWindow === VOLUME_WINDOW.WEEKLY ? 'weeklyVolumeUSD' : 'dailyVolumeUSD'}
            width={width}
            type={CHART_TYPES.BAR}
            useWeekly={volumeWindow === VOLUME_WINDOW.WEEKLY}
          />
        </ResponsiveContainer>
      )}
      {display === 'volume' && (
        <div
          style={{
            bottom: '50px',
            position: 'absolute',
            left: '20px',
            zIndex: 10
          }}
        >
          <StyledPeriod
            className={volumeWindow === VOLUME_WINDOW.DAYS ? 'active' : ''}
            onClick={() => setVolumeWindow(VOLUME_WINDOW.DAYS)}
          >
            D
          </StyledPeriod>
          <StyledPeriod
            style={{ marginLeft: '4px' }}
            className={volumeWindow === VOLUME_WINDOW.WEEKLY ? 'active' : ''}
            onClick={() => setVolumeWindow(VOLUME_WINDOW.WEEKLY)}
          >
            W
          </StyledPeriod>
        </div>
      )}
    </>
  ) : (
    <LoadingWrap>
      <Spin size="large" tip="Loading···" />
    </LoadingWrap>
  )
}

export default GlobalChart
