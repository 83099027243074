import React from 'react'

import Notice from '../../components/Notice'
import Vault from './components/Vault'
import WorksStep from './components/WorksStep'
const Savault: React.FC = () => {
  return (
    <>
      <Notice text="Instruction: saVault is a decentralized management tool that helps users automatically to increase their wealth. Your principal will be growing here. Although not much, it is a very robust service. saVault is in beta. Use at your own risk. " />
      <Vault />
      <WorksStep />
    </>
  )
}
export default Savault
