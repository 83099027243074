import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import HarvestImg from '../../assets/images/harvst.png'
import MainCointainer from '../../components/MainContainer'
import MinHeight from '../../components/MinHeight'
import Card from '../../components/Card'
import CardContent from '../../components/CardContent'
import UnlockWallet from '../../components/UnlockWallet'
import Harvest from './components/Harvest'
import { Stake } from './components/Stake'
import { useWeb3React } from '@web3-react/core'
import { useTokenContract } from '../../hooks/useContract'
import { Contract } from 'ethers'
import { useSelector } from 'react-redux'
import { AppState } from '../../state'
import { FarmPoolInfo } from '../../state/farm-pool/actions'

const Farm = () => {
  const { account } = useWeb3React()
  const { height } = useWindowSize()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { farmName, farmToken, poolId } = useParams<{farmName: string, farmToken: string, poolId: string}>()
  const pools = useSelector<AppState, AppState['poolInfos']['pools']>(state => state.poolInfos.pools)
  const poolInfo: FarmPoolInfo | undefined = pools[+poolId || 0]
  const lpContract: Contract | null = useTokenContract(poolInfo?.lpAddress)

  return (
    <MainCointainer>
      <Card>
        <CardContent>
          <MinHeight>
            {!!account ? (
              <>
                <StyledHeader>
                  <img src={HarvestImg} alt="" />
                  <h2 className="ellipsis">{poolInfo?.name ?? 'Happy Farm'}</h2>
                  <p className="ellipsis">Deposit {poolInfo?.lpSymbol} Tokens and earn SASHIMI</p>
                </StyledHeader>
                <StyledContainer>
                  <Harvest poolId={+poolId} />
                  <Stake lpContract={lpContract} poolInfo={poolInfo} />
                </StyledContainer>
                <StyledTips>
                  <span role="img" aria-label="star">
                    ⭐️
                  </span>{' '}
                  Every time you stake and unstake LP tokens, the contract will automagically harvest SASHIMI rewards
                  for you!
                </StyledTips>
              </>
            ) : (
              <UnlockWalletWrap height={height}>
                <UnlockWallet />
              </UnlockWalletWrap>
            )}
          </MinHeight>
        </CardContent>
      </Card>
    </MainCointainer>
  )
}
const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  img {
    width: 74px;
  }
  h2 {
    margin-top: 30px;
    color: #121212;
  }
  p {
    margin-top: 4px;
    line-height: 20px;
    color: #696e77;
  }
`

const StyledContainer = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
  flex-wrap: wrap;
  .item-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 18px 40px 18px;
  }
  .item {
    width: 360px;
    height: 290px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    display: flex;
    flex-direction: column;
    align-items: center;
    .img {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 82px;
      height: 82px;
      background-color: #f2f2f2;
      border-radius: 50%;
    }
    h2 {
      margin-top: 32px;
      line-height: 42px;
      color: #eb591a;
      font-size: 36px;
    }
    p {
      margin-top: 12px;
      line-height: 20px;
      color: #696e77;
    }
  }
  .btn {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledTips = styled.div`
  margin-bottom: 60px;
  line-height: 22px;
  color: #696e77;
  text-align: center;
`

const UnlockWalletWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${(props: { height: number }): number => props.height - 62}px;
`
export default Farm
