/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { Web3Provider } from '@ethersproject/providers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useActiveWeb3React } from '../../../hooks'
import { MaxUint256 } from '@ethersproject/constants';
import {  getNetworkLibrary } from '../../../connectors'
import Compound from '@sashimiswap/compound-js';
import BigNumber from 'bignumber.js';
import { AppState } from '../../../state';
import { IMarketInfo } from '../types'

export function useWeb3Provider() {
  const { chainId } = useSelector<AppState, AppState['wallet']>(state => state.wallet);
  return useMemo(() => {
    if (chainId) {
      // modify xjz
      // return new Web3Provider((web3 || defaultWeb3).currentProvider);
      return getNetworkLibrary()
    }
    return null;
  }, [chainId]);
}

export function useCompound() {
  const {chainId, account, library} =  useActiveWeb3React();
  return useMemo(() => {

    if (chainId && account) {
      // modify xjz
      // return new Compound((web3 || defaultWeb3).currentProvider);
      // @ts-ignore
      return new Compound(library?.provider);
    }
    return null;
  }, [account, chainId, library]);
}

export const ethDummyAddress = '0xETH';

// export async function getUnderlyingTokenAddress(cTokenAddress: string, network: string, provider: any): Promise<string> {
//   try {
//     return await Compound.eth.read(
//       cTokenAddress,
//       'function underlying() returns (address)',
//       [], // [optional] parameters
//       {
//         network,
//         _compoundProvider: provider,
//       }
//     );
//   } catch (error) {
//     if (error.error.code === 'CALL_EXCEPTION') {
//       return ethDummyAddress;
//     }
//     throw error;
//   }
// }

export function getUnderlyingTokenAddress(cTokenAddress: string, network: string) {
  // @ts-ignore
  if (Compound.util.getAddress(Compound.slETH, network).toLowerCase() === cTokenAddress.toLowerCase()) {
    return ethDummyAddress;
  }
  return Compound.util.getUnderlyingAddressBySlToken(cTokenAddress, network).toLowerCase();
}




export async function getLiquidationInfo(wallet: AppState['wallet']) {
  const { chainId } = wallet;
  // modify xjz
  // const { currentProvider } = web3 || defaultWeb3;
  // const provider = new Web3Provider(currentProvider);
  const provider = getNetworkLibrary() as any;
  const network = Compound.util.getNetNameWithChainId(chainId);
  const [
    closeFactor,
    incentive
  ] = await Promise.all([
    Compound.eth.read(
      // @ts-ignore
      Compound.util.getAddress(Compound.Comptroller, network),
      'function closeFactorMantissa() returns (uint256)',
      [], // [optional] parameters
      {
        network,
        _compoundProvider: provider
      } // [optional] call options, provider, network, ethers.js "overrides"
    ),
    Compound.eth.read(
      // @ts-ignore
      Compound.util.getAddress(Compound.Comptroller, network),
      'function liquidationIncentiveMantissa() returns (uint256)',
      [], // [optional] parameters
      {
        network,
        _compoundProvider: provider
      } // [optional] call options, provider, network, ethers.js "overrides"
    )
  ]);
  return {
    closeFactor: new BigNumber(closeFactor.toString()).dividedBy(1e18),
    incentive: new BigNumber(incentive.toString()).dividedBy(1e18),
    bonusPercent: new BigNumber(200).minus(new BigNumber(incentive.toString()).dividedBy(1e18).times(100))
  };
}

// async function getDecimals(tokenAddress: string, network: string, provider: any) {
//   let decimals;
//   if (tokenAddress === ethDummyAddress) {
//     decimals = 18;
//   } else {
//     decimals = await Compound.eth.read(
//       tokenAddress,
//       'function decimals() returns (uint8)',
//       [], // [optional] parameters
//       {
//         network,
//         _compoundProvider: provider
//       } // [optional] call options, provider, network, ethers.js "overrides"
//     );
//     decimals = decimals.toString();
//   }

//   return +decimals;
// }
function getDecimals(symbol:string) {
  return Compound.util.getTokenDecimals(symbol);
}

const priceFeedDecimals = 18;
const exchangeRateDecimals = 18;
// const cTokenDecimals = 8;

export async function getUnderlyingPrice(cTokenAddress: string, tokenDecimals: number, network: string, provider: any) {
  // @ts-ignore
  // const priceFeedAddress = Compound.util.getAddress(Compound.PriceFeed, network);
  const priceFeedAddress = '0xdaaea7791e3eef5b71a68664e6743a5cfbd1c91b';
  const underlyingPrice = await Compound.eth.read(
    priceFeedAddress,
    'function getUnderlyingPrice(address) returns (uint)',
    [cTokenAddress], // [optional] parameters
    {
      network,
      _compoundProvider: provider
    } // [optional] call options, provider, network, ethers.js "overrides"
  );
  return new BigNumber(underlyingPrice.toString()).dividedBy(`1e${priceFeedDecimals * 2 - tokenDecimals}`);
}

async function getCompSpeed(tokenAddress: string, network: string, provider: any) {
  const compSpeed = await Compound.eth.read(
    // @ts-ignore
    Compound.util.getAddress(Compound.Comptroller, network),
    'function sashimiSpeeds(address) returns (uint)',
    [tokenAddress], // [optional] parameters
    {
      network,
      _compoundProvider: provider
    } // [optional] call options, provider, network, ethers.js "overrides"
  );

  return new BigNumber(compSpeed.toString()).dividedBy(`1e${18}`);
}

async function getMarketTotalBorrowInTokenUnit(cTokenAddress: string, tokenDecimals: number, network: string, provider: any) {
  const totalBorrows = await Compound.eth.read(
    cTokenAddress,
    'function totalBorrows() returns (uint)',
    [], // [optional] parameters
    {
      network,
      _compoundProvider: provider,
    } // [optional] call options, provider, network, ethers.js "overrides"
  );

  return new BigNumber(totalBorrows.toString()).dividedBy(`1e${tokenDecimals}`);
}

async function getMarketTotalSupplyInTokenUnit(cTokenAddress: string, tokenDecimals: number, network: string, provider: any) {
  const [
    cTokenTotalSupply,
    exchangeRateStored
  ] = await Promise.all([
    Compound.eth.read(
      cTokenAddress,
      'function totalSupply() returns (uint)',
      [], // [optional] parameters
      {
        network,
        _compoundProvider: provider
      }
    ),
    Compound.eth.read(
      cTokenAddress,
      'function exchangeRateStored() returns (uint)',
      [], // [optional] parameters
      {
        network,
        _compoundProvider: provider,
      } // [optional] call options, provider, network, ethers.js "overrides"
    )
  ]);
  return new BigNumber(exchangeRateStored.toString())
    .times(cTokenTotalSupply.toString())
    .dividedBy(`1e${tokenDecimals + exchangeRateDecimals}`);
}

async function getUnderlyingAmount(cTokenAddress: string, tokenDecimals: number, network: string, provider: any) {
  const underlyingAmount = await Compound.eth.read(
    cTokenAddress,
    'function getCash() returns (uint)',
    [], // [optional] parameters
    {
      network,
      _compoundProvider: provider,
    }
  );

  return new BigNumber(underlyingAmount.toString()).dividedBy(`1e${tokenDecimals}`);
}

async function getCollateralFactor(cTokenAddress: string, network: string, provider: any) {
  const market = await Compound.eth.read(
    // @ts-ignore
    Compound.util.getAddress(Compound.Comptroller, network),
    'function markets(address) returns (bool, uint, bool)',
    [cTokenAddress], // [optional] parameters
    {
      network,
      _compoundProvider: provider,
    } // [optional] call options, provider, network, ethers.js "overrides"
  );
  return new BigNumber(market[1].toString()).dividedBy(1e18);
}

const SUPPLY_RATE_DECIMALS = 18;
const BLOCK_PER_DAY = 4 * 60 * 24;
const DAYS_PER_YEAR = 365;
const SASHIMI_DECIMALS = 18;

async function getSupplyApy(cTokenAddress: string, network: string, provider: any) {
  let supplyRatePerBlock;
  try {
    supplyRatePerBlock = await Compound.eth.read(
      cTokenAddress,
      'function supplyRatePerBlock() returns (uint256)',
      [], // [optional] parameters
      {
        network,
        _compoundProvider: provider,
      } // [optional] call options, provider, network, ethers.js "overrides"
    );
  } catch (e) {
    supplyRatePerBlock = new BigNumber(0);
  }
  return new BigNumber(supplyRatePerBlock.toNumber())
    .dividedBy(`1e${SUPPLY_RATE_DECIMALS}`)
    .times(BLOCK_PER_DAY)
    .plus(1)
    .exponentiatedBy(DAYS_PER_YEAR - 1)
    .minus(1)
    .times(100);
}

async function getBorrowApy(cTokenAddress: string, network: string, provider: any) {
  let borrowRatePerBlock;
  try {
    borrowRatePerBlock = await Compound.eth.read(
      cTokenAddress,
      'function borrowRatePerBlock() returns (uint256)',
      [], // [optional] parameters
      {
        network,
        _compoundProvider: provider,
      } // [optional] call options, provider, network, ethers.js "overrides"
    );
  } catch (e) {
    borrowRatePerBlock = new BigNumber(0);
  }
  return new BigNumber(borrowRatePerBlock.toNumber())
    .dividedBy(`1e${SUPPLY_RATE_DECIMALS}`)
    .times(BLOCK_PER_DAY)
    .plus(1)
    .exponentiatedBy(DAYS_PER_YEAR - 1)
    .minus(1)
    .times(100);
}

// async function getTokenSymbol(tokenAddress: string, network: string, provider: any) {
//   const saiAddress = Compound.util.getAddress(
//     // @ts-ignore
//     Compound.SAI,
//     network
//   );
//   let symbol;
//   if (saiAddress && tokenAddress.toLowerCase() === saiAddress.toLowerCase()) {
//     symbol = 'SAI';
//   } else if (tokenAddress === ethDummyAddress) {
//     symbol = 'ETH';
//   } else {
//     symbol = await Compound.eth.read(
//       tokenAddress,
//       'function symbol() returns (string)',
//       [], // [optional] parameters
//       {
//         network,
//         _compoundProvider: provider,
//       } // [optional] call options, provider, network, ethers.js "overrides"
//     );
//   }
//   return symbol;
// }
function getTokenSymbol(tokenAddress: string, network: string) {
  let symbol
  if (tokenAddress === ethDummyAddress) {
    symbol = 'ETH'
  } else {
    symbol = Compound.util.getSymbolByAddress(tokenAddress, network)
  }
  return symbol
}


export async function getMarket(sashimiPrice: BigNumber, network: string, provider: any, cTokenAddress: string) {
  const underlyingAddress =  getUnderlyingTokenAddress(
    cTokenAddress,
    network,
  );
  const symbol = getTokenSymbol(underlyingAddress, network);
  const tokenDecimals = parseFloat(getDecimals(symbol)) ;
  const underlyingPrice = await getUnderlyingPrice(
    cTokenAddress,
    tokenDecimals ,
    network,
    provider
  );

  const [
    marketTotalBorrowInTokenUnit,
    marketTotalSupplyInTokenUnit,
    collateralFactor,
    supplyApy,
    borrowApy,
    underlyingAmount,
    sashimiSpeed
  ] = await Promise.all([
    getMarketTotalBorrowInTokenUnit(
      cTokenAddress,
      tokenDecimals,
      network,
      provider
    ),
    getMarketTotalSupplyInTokenUnit(
      cTokenAddress,
      tokenDecimals,
      network,
      provider
    ),
    getCollateralFactor(
      cTokenAddress,
      network,
      provider
    ),
    getSupplyApy(
      cTokenAddress,
      network,
      provider
    ),
    getBorrowApy(
      cTokenAddress,
      network,
      provider
    ),
    getUnderlyingAmount(
      cTokenAddress,
      tokenDecimals,
      network,
      provider
    ),
    getCompSpeed(cTokenAddress, network, provider)
  ]);
  const sashimiPerYear = sashimiSpeed
    .times(sashimiPrice)
    .times(BLOCK_PER_DAY)
    .times(DAYS_PER_YEAR);
  const marketTotalSupply = marketTotalSupplyInTokenUnit.times(underlyingPrice);
  const marketTotalBorrow = marketTotalBorrowInTokenUnit.times(underlyingPrice);

  const sashimiApyOfBorrow = marketTotalBorrow.eq(0)
    ? marketTotalBorrow : sashimiPerYear.dividedBy(marketTotalBorrow).times(100);
  const sashimiApyOfSupply = marketTotalSupply.eq(0)
    ? marketTotalSupply : sashimiPerYear.dividedBy(marketTotalSupply).times(100);

  return {
    cTokenAddress,
    underlyingAddress,
    symbol,
    supplyRate: supplyApy,
    borrowRate: borrowApy,
    marketTotalSupply,
    marketTotalSupplyInTokenUnit,
    marketTotalBorrowInTokenUnit,
    marketTotalBorrow,
    sashimiApyOfBorrow,
    sashimiApyOfSupply,
    underlyingAmount,
    price: underlyingPrice,
    liquidity: underlyingAmount.times(underlyingPrice),
    collateralFactor,
    sashimiSpeed,
    distributionSupplyAPY: marketTotalSupply.eq(0) ? marketTotalSupply : sashimiSpeed
      .times(BLOCK_PER_DAY * DAYS_PER_YEAR)
      .times(100)
      .times(sashimiPrice)
      .dividedBy(marketTotalSupply),
    distributionBorrowAPY: marketTotalBorrow.eq(0) ? marketTotalBorrow : sashimiSpeed
      .times(BLOCK_PER_DAY * DAYS_PER_YEAR)
      .times(100)
      .times(sashimiPrice)
      .dividedBy(marketTotalBorrow),
    tokenDecimals
  };
}

async function getSashimiPrice(network: string, provider: any) {
  // todo: 接入sashimi价格
  // @ts-ignore
  const slSashimi = Compound.util.getAddress(Compound.slSASHIMI, network);
  const price = await getUnderlyingPrice(slSashimi, 18, network, provider);
  return price;
}

export async function getAllMarkets(chainId: number, pro: any) {
  // const { chainId } = wallet;
  if (!pro) return []
  // modify xjz
  // const { currentProvider } = web3 || defaultWeb3;
  const provider = new Web3Provider(pro) as any;
  // const provider = getNetworkLibrary() as any;
  const network = Compound.util.getNetNameWithChainId(chainId);
  const allMarketsAddress: string[] = await Compound.eth.read(
    // @ts-ignore
    Compound.util.getAddress(Compound.Comptroller, network),
    'function getAllMarkets() returns (address[])',
    [],
    {
      network,
      _compoundProvider: provider as any,
    }
  );
  const sashimiPrice = await getSashimiPrice(network, provider);
  const allMarkets = await Promise
    .all(allMarketsAddress
      .map(marketAddress => getMarket(sashimiPrice, network, provider, marketAddress)));
  return allMarkets;
}

// eslint-disable-next-line max-len
async function getUserSupplyAndBorrowBalance(cTokenAddress: string, tokenDecimals: number, underlyingPrice: BigNumber, account: string, network: string, provider: any) {
  const accountSnapshot = await Compound.eth.read(
    cTokenAddress,
    'function getAccountSnapshot(address) returns (uint, uint, uint, uint)',
    [account], // [optional] parameters
    {
      network,
      _compoundProvider: provider,
    } // [optional] call options, provider, network, ethers.js "overrides"
  );

  const [, cTokenBalance, borrowBalance, exchangeRate] = accountSnapshot;
  const supplyBalanceInTokenUnit = new BigNumber(
    cTokenBalance.toString()
  ).times(exchangeRate.toString()).dividedBy(`1e${tokenDecimals + exchangeRateDecimals}`);
  const supplyBalanceInUsd = supplyBalanceInTokenUnit.times(underlyingPrice);
  const borrowBalanceInTokenUnit = new BigNumber(
    borrowBalance.toString()
  ).dividedBy(`1e${tokenDecimals}`);
  const borrowBalanceInUsd = borrowBalanceInTokenUnit.times(
    underlyingPrice
  );

  return {
    supplyBalanceInTokenUnit: new BigNumber(supplyBalanceInTokenUnit.toFixed(tokenDecimals)),
    supplyBalance: supplyBalanceInUsd,
    borrowBalanceInTokenUnit,
    borrowBalance: borrowBalanceInUsd,
    cTokenBalance: new BigNumber(cTokenBalance.toString())
  };
}

async function getEnteredMarket(account: string, network: string, provider: any) {
  return Compound.eth.read(
    // @ts-ignore
    Compound.util.getAddress(Compound.Comptroller, network),
    'function getAssetsIn(address) returns (address[])',
    [account], // [optional] parameters
    {
      network,
      _compoundProvider: provider
    }
  );
}

export async function getAllowance(tokenAddress: string, tokenDecimals: number, account: string, cTokenAddress: string, network: string, provider: any) {
  let allowance;
  if (tokenAddress === ethDummyAddress) {
    allowance = MaxUint256;
  } else {
    allowance = await Compound.eth.read(
      tokenAddress,
      'function allowance(address, address) returns (uint)',
      [account, cTokenAddress], // [optional] parameters
      {
        network,
        _compoundProvider: provider,
      }
    );
  }
  return new BigNumber(allowance.toString()).dividedBy(`1e${tokenDecimals}`);
}

async function getWalletBalance(tokenAddress: string, tokenDecimals: number, account: string, network: string, provider: any) {
  let balance;
  if (tokenAddress === ethDummyAddress) {
    //modify xjz
    // balance = await web3.eth.getBalance(account);
    balance = await provider.getBalance(account);
    balance = new BigNumber(balance.toString());
  } else {
    balance = await Compound.eth.read(
      tokenAddress,
      'function balanceOf(address) returns (uint)',
      [account], // [optional] parameters
      {
        network,
        _compoundProvider: provider,
      } // [optional] call options, provider, network, ethers.js "overrides"
    );
  }

  return new BigNumber(balance.toString()).dividedBy(`1e${tokenDecimals}`);
}

export async function getUserInfo(wallet: {
  chainId: number, account: string, connected: boolean
}, allMarkets: IMarketInfo[], pro: any) {
  const {
    chainId,
    account,
    connected
  } = wallet;
  if (!account || !connected) {
    return allMarkets;
  }
  // const { currentProvider } = web3 || defaultWeb3;
  const provider = new Web3Provider(pro);
  // const provider = getNetworkLibrary();
  const network = Compound.util.getNetNameWithChainId(chainId);
  const enteredMarkets = await getEnteredMarket(account, network, provider);
  return Promise.all(allMarkets.map(async market => {
    const {
      cTokenAddress,
      underlyingAddress,
      price,
      tokenDecimals
    } = market;
    const [
      supplyAndBorrowBalance,
      walletBalance,
      allowance
    ] = await Promise.all([
      getUserSupplyAndBorrowBalance(
        cTokenAddress,
        tokenDecimals,
        price,
        account,
        network,
        provider
      ),
      getWalletBalance(
        underlyingAddress,
        tokenDecimals,
        account,
        network,
        provider,
      ),
      getAllowance(
        underlyingAddress,
        tokenDecimals,
        account,
        cTokenAddress,
        network,
        provider
      )
    ]);
    return {
      ...market,
      entered: enteredMarkets.includes(cTokenAddress),
      ...supplyAndBorrowBalance,
      allowance,
      enabled: allowance.gt(0),
      walletBalance
    };
  }));
}

export async function getSashimiEarned(wallet: {chainId: number, account: string}, currentProvider: any) {
  const { chainId, account } = wallet;
  // modify xjz
  // const { currentProvider } = web3 || defaultWeb3;
  const provider = new Web3Provider(currentProvider);
  const network = Compound.util.getNetNameWithChainId(chainId);
  const compBalanceMetadata = await Compound.eth.read(
    // @ts-ignore
    Compound.util.getAddress(Compound.CompoundLens, network),
    'function getSashimiBalanceMetadataExt(address, address, address) returns (uint, uint, address, uint)',
    [
      // @ts-ignore
      Compound.util.getAddress(Compound.SASHIMI, network),
      // @ts-ignore
      Compound.util.getAddress(Compound.Comptroller, network),
      account,
    ], // [optional] parameters
    {
      network,
      _compoundProvider: provider as any,
    } // [optional] call options, provider, network, ethers.js "overrides"
  );
  return new BigNumber(compBalanceMetadata[3].toString()).dividedBy(new BigNumber(`1e${SASHIMI_DECIMALS}`));
}

export function getGlobalDetail(allMarketsWithUserInfo: IMarketInfo[]) {
  const {
    totalSupplyBalance,
    totalBorrowBalance,
    yearlySupplyInterest,
    yearlyBorrowInterest,
    totalBorrowLimit
  } = allMarketsWithUserInfo.reduce((acc, market) => {
    const {
      entered = false,
      supplyBalance = new BigNumber(0),
      borrowBalance = new BigNumber(0),
      collateralFactor = new BigNumber(0),
      supplyRate = new BigNumber(0),
      borrowRate = new BigNumber(0)
    } = market;
    return {
      totalSupplyBalance: acc.totalSupplyBalance.plus(supplyBalance),
      totalBorrowBalance: acc.totalBorrowBalance.plus(borrowBalance),
      yearlyBorrowInterest: acc.yearlyBorrowInterest.plus(
        borrowRate.times(borrowBalance).dividedBy(100)
      ),
      yearlySupplyInterest: acc.yearlySupplyInterest.plus(
        supplyRate.times(supplyBalance).dividedBy(100)
      ),
      totalBorrowLimit: acc.totalBorrowLimit.plus(
        entered ? collateralFactor.times(supplyBalance) : 0
      )
    };
  }, {
    totalSupplyBalance: new BigNumber(0),
    totalBorrowBalance: new BigNumber(0),
    yearlySupplyInterest: new BigNumber(0),
    yearlyBorrowInterest: new BigNumber(0),
    totalBorrowLimit: new BigNumber(0)
  });
  return {
    totalSupplyBalance,
    totalBorrowBalance,
    totalBorrowLimit,
    totalBorrowLimitUsedPercent: totalBorrowLimit.eq(0) ? totalBorrowLimit : totalBorrowBalance
      .div(totalBorrowLimit)
      .times(100),
    yearlySupplyInterest,
    yearlyBorrowInterest,
    netApy: totalSupplyBalance.eq(0) ? totalSupplyBalance : yearlySupplyInterest
      .minus(yearlyBorrowInterest)
      .div(totalSupplyBalance)
      .times(100),
  };
}
