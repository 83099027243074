import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { FireFilled } from '@ant-design/icons'

import {
  AboutIcon,
  ExchangeIcon,
  FarmIcon,
  GovernanceIcon,
  InvestmentIcon,
  OverviewIcon,
  SavaultIcon,
  StakingIcon,
  HotIcon,
  LendIcon,
  BSCChainIcon,
  EthereumV1Icon,
  BridgeIcon, DistributionIcon
} from '../Icons/index'

interface Link {
  title: string
  subTitle?: any
  path: string
  icon?: React.ReactNode
  subTitleIsImg: boolean
  jump: boolean
}
const Links: Link[] = [
  {
    title: 'Overview',
    subTitle: '',
    path: '/home/swap',
    icon: <OverviewIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },
  {
    title: 'Distribution Plan',
    subTitle: '',
    path: '/distribution',
    icon: <DistributionIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },
  {
    title: 'Exchange',
    subTitle: '',
    path: '/pairs',
    icon: <ExchangeIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },
  {
    title: 'Farm',
    subTitle: 'Earn SASHIMI',
    path: '/farms',
    // path: 'https://v1.sashimi.cool/farms',
    icon: <FarmIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
    // jump: true,
  },
  // {
  //   title: 'Lending',
  //   subTitle: '',
  //   path: 'https://sashimi.cool/lending/#/',
  //   icon: <LendIcon style={{ color: '#818690' }} />,
  //   subTitleIsImg: false,
  //   jump: true,
  // },
  {
    title: 'Lending',
    subTitle: '',
    path: '/lending',
    icon: <LendIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },
  {
    title: 'saVault',
    subTitle: '',
    path: '/savault',
    icon: <SavaultIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },
  {
    title: 'Investment Info',
    subTitle: '',
    path: '/investment',
    icon: <InvestmentIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },
  {
    title: 'Bridge',
    subTitle: '',
    path: '/bridge',
    icon: <BridgeIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },
  {
    title: 'Staking',
    subTitle: 'Get Dividend',
    path: '/staking',
    icon: <StakingIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: false,
  },

  {
    title: 'Governance',
    subTitle: <HotIcon style={{ color: '#DF0000' }} />,
    path: 'https://snapshot.sashimi.cool/#/sashimi',
    icon: <GovernanceIcon style={{ color: '#818690' }} />,
    subTitleIsImg: true,
    jump: true,
  },
  {
    title: 'Heco Chain',
    subTitle: '',
    path: 'https://heco.sashimi.cool/',
    icon: <FireFilled style={{ color: '#818690' }} />,
    subTitleIsImg: true,
    jump: true,
  },
  {
    title: 'BSC Chain',
    subTitle: '',
    path: 'https://bsc.sashimi.cool/',
    icon: <BSCChainIcon style={{ color: '#818690' }} />,
    subTitleIsImg: true,
    jump: true,
  },
  {
    title: 'Ethereum v1',
    subTitle: '',
    path: 'https://v1.sashimi.cool/',
    icon: <EthereumV1Icon style={{ color: '#818690' }} />,
    subTitleIsImg: true,
    jump: true,
  },
  {
    title: 'About',
    subTitle: '',
    path: 'https://docs.sashimi.cool/',
    icon: <AboutIcon style={{ color: '#818690' }} />,
    subTitleIsImg: false,
    jump: true,
  },
]
const Menu: React.FC = () => {
  return (
    <MenuWrap>
      {Links.map((item, index) => {
        return (
          <MenuItem key={index}>
            {!item.jump ? (
              <StyledLink exact activeClassName="active" to={item.path}>
                <TxtWrap>
                  <Txt>
                    {item.icon} <span className="title">{item.title}</span>
                  </Txt>
                  {item.subTitle ? <TxtDesc>{item.subTitle}</TxtDesc> : null}
                </TxtWrap>
              </StyledLink>
            ) : (
              <a href={item.path} target="_blank" rel="noopener noreferrer">
                <TxtWrap>
                  <Txt>
                    {item.icon} <span className="title">{item.title}</span>
                  </Txt>
                  {item.subTitle ? <TxtDesc>{item.subTitle}</TxtDesc> : null}
                </TxtWrap>
              </a>
            )}
          </MenuItem>
        )
      })}
    </MenuWrap>
  )
}

const MenuWrap = styled.ul`
  flex: 1;
  box-sizing: border-box;
  padding: 0 30px;
`
const MenuItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  line-height: 50px;
  a {
    width: 100%;
  }
  &:hover,
  .active {
    span {
      color: #eb591a !important;
    }
  }
`
const StyledLink = styled(NavLink)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  line-height: 50px;
`
const TxtWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  line-height: 50px;
  color: #818690;
  span.title {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }
  span.anticon {
    vertical-align: -3px;
  }
`
// const RightArrow = styled(RightOutlined)`
//   svg{
//     height:10px!important;
//     color:#999;
//   }
// `
const Txt = styled.div`
  flex: 1;
`
const TxtDesc = styled.span`
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  border-radius: 10px;
  background: rgba(235, 89, 26, 0.15);
  color: #696e77;
`
export default Menu
