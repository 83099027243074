import React from 'react'
import styled from 'styled-components'
import Card from '../../../../components/Card'
import CardTitle from '../../../../components/CardTitle'
import BalanceUtils from '../../../../fuck/formatBalance'
import Stake from '../Stake'
import UnStake from '../Unstake'

import { useSashimiBarInfo } from '../../../../hooks/useSashimi'

const StakingPool = () => {
  const timeADay = 86400000
  const stakingStartTime = new Date(Date.UTC(2020, 8, 28, 13, 0, 0)).getTime()
  const apyTimePivot = (Date.now() - stakingStartTime) / timeADay

  const { sashimiBalanceOfSashimiBar, xSashimiBalanceOfSashimiBar } = useSashimiBarInfo()
  const exchangeRatio = sashimiBalanceOfSashimiBar.div(xSashimiBalanceOfSashimiBar)

  return (
    <>
      <StyledCard>
        <CardTitle text="Staking Pool">
          <SinceTime>
            Since{' '}
            {/*{DateTimeUtils.toNiceDate(new Date(StakingPool && StakingPool.poolList[0]['updateTime']).getTime() / 1000)}*/}
          </SinceTime>
        </CardTitle>
        <PoolDetail>
          <div className="detail-item-list">
            <div className="detail-item">
              <h2>{BalanceUtils.getBalanceNumber(xSashimiBalanceOfSashimiBar).toFixed(2)}</h2>
              <p>Total xSASHIMI</p>
            </div>
            <div className="detail-item">
              <h2>{BalanceUtils.getBalanceNumber(sashimiBalanceOfSashimiBar).toFixed(2)}</h2>
              <p>SASHIMI in the pool</p>
            </div>
          </div>
          <div className="detail-item-list">
            <div className="detail-item">
              <h2>
                {exchangeRatio.isNaN() ? '-' : exchangeRatio.toFixed(3)} <span>SASHIMI</span>
              </h2>
              <p>Per xSASHIMI</p>
            </div>
            <div className="detail-item">
              <h2>
                {sashimiBalanceOfSashimiBar.isEqualTo(0)
                  ? '-'
                  : sashimiBalanceOfSashimiBar
                      .minus(xSashimiBalanceOfSashimiBar)
                      .div(xSashimiBalanceOfSashimiBar)
                      .div(apyTimePivot)
                      .times(365 * 100)
                      .toFixed(2)}
                %
              </h2>
              <p>Staking APY</p>
            </div>
          </div>
        </PoolDetail>

        <InputItemList>
          <Stake />
        </InputItemList>
        <InputItemList>
          <UnStake />
        </InputItemList>
        <StyledText>
          <p>Stake SASHIMI to get xSASHIMI.</p>
          <p>- 0.05% of trading fee will buy SASHIMI and put it in the pool.(not implemented yet.)</p>
          <p>- 25% of investment will buy SASHIMI and put it in the pool.</p>
          {/* <p>- Governance voting depend on your xSASHIMI.</p> */}
        </StyledText>
      </StyledCard>
    </>
  )
}

const StyledCard = styled(Card)`
  margin-bottom: 60px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 0 24px 52px 24px;
`

const SinceTime = styled.span`
  color: #999;
  font-size: 14px;
  font-weight: 400;
`
const PoolDetail = styled.div`
  min-width: 420px;
  @media screen and (max-width: 576px) {
    min-width: initial;
  }
  .detail-item-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
    .detail-item {
      flex: 1;
      height: 99px;
      background: rgba(252, 133, 95, 0.05);
      border-radius: 3px;
      border: 1px dashed #eb591a;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      &:first-child {
        margin-right: 20px;
      }
      @media screen and (max-width: 576px) {
        &:first-child {
          margin-right: 0;
        }
      }
      h2 {
        line-height: 28px;
        font-size: 24px;
        color: #eb591a;
        span {
          color: #333;
          font-size: 14px;
        }
      }
      p {
        margin-top: 5px;
        color: #696e77;
      }
    }
  }
`

const InputItemList = styled.div`
  margin-top: 24px;
  &:last-child {
    margin-top: 16px;
  }
  .input-wrap {
    height: 46px;
    display: flex;
    justify-content: space-bewteen;
    .input {
      flex: 1;
      position: relative;
      .input-number {
        box-sizing: border-box;
        width: 100%;
        padding: 7px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        outline: none;
        overflow: hidden;
        font-size: 18px;
      }
      i {
        position: absolute;
        top: 12px;
        right: 24px;
        color: #0091ff;
        text-decoration: underline;
        font-style: normal;
        cursor: pointer;
      }
    }
  }
  .balance {
    margin-top: 6px;
    line-height: 20px;
    color: #696e77;
    strong {
      color: #eb591a;
    }
  }
`

const StyledText = styled.div`
  margin-top: 20px;
  p {
    line-height: 18px;
    font-size: 12px;
    color: #696e77;
  }
`
export default StakingPool
