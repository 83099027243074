import { useCallback, useEffect, useState } from 'react'
import api from '../api/index'
import { useBlockNumber } from '../state/application/hooks'
// demo
// {
//     "msg": "success",
//     "code": 0,
//     "data": [
//         {
//             "APY": "1769.40",
//             "key": "WETH-Candy"
//         },
//         {
//             "APY": "7464032960289.86",
//             "key": "XYZ-GOLFF"
//         }
//     ]
// }
interface APIPros {
  [key: string]: any
}
const API: APIPros = api
interface Earn {
  symbol: string,
  amount: string,
  address: string,
}
export interface InvestmentAPYItems {
  APY: string,
  key: string,
  earn: Earn
}
export interface InvestmentAPY {
  [key: string]: InvestmentAPYItems
}

export type ILendingInvestmentAPY = {
  price: string
  apy: string
  symbol: string
}
export const useInvestmentAPYs = () => {
  const farms = API?.farms
  const [investmentAPYs, setInvestmentAPYs] = useState<InvestmentAPY>({});
  const blockNumber: number = useBlockNumber() || 0
  const fetchAPY = useCallback(() => {
    if (farms && blockNumber % 6 === 0) {
      try {
        farms.getInvestmentAPY({
          success: (result: any) => {
            if (result.data && Array.isArray(result.data)) {
              const temp: InvestmentAPY = {}
              result.data.forEach((item: InvestmentAPYItems) => {
                temp[item.key.replace('-', '')] = item
              })
              setInvestmentAPYs(temp);
            }

          }
        })

      } catch (e) {
        console.log('fetchAPY error: ', e);
      }
    }
  }, [farms, blockNumber]);

  useEffect(() => {
    fetchAPY()
  }, [fetchAPY]);

  return investmentAPYs
};

export const useLendingInvestmentAPYs = ()=>{
  const farms = API?.farms
  const [investmentAPYs, setInvestmentAPYs] = useState<ILendingInvestmentAPY[]>([]);

  const block: number = 0;
  const fetchAPY = useCallback(async ()=>{
    if (farms && block % 6 === 0) {
      try {
        farms.getLendingAPY({
          success: (result: any) =>{
            if (result.data && Array.isArray(result.data.list)) {
              setInvestmentAPYs(result.data && result.data.list);
            }
          }
        })
      } catch (e) {
        console.log('fetchAPY error: ', e);
      }
    }
  },[farms, block]);
  
  useEffect(() => {
    fetchAPY()
  }, [fetchAPY]);

  return investmentAPYs
}
