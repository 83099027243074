import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const BUNDLE_ID = '1'

/**
 * 交易对字段
 */
const PairFields = `
  fragment PairFields on Pair {
    id
    txCount
    token0 {
      id
      symbol
      name
      totalLiquidity
      derivedETH
    }
    token1 {
      id
      symbol
      name
      totalLiquidity
      derivedETH
    }
    reserve0
    reserve1
    reserveUSD
    totalSupply
    trackedReserveETH
    reserveETH
    volumeUSD
    untrackedVolumeUSD
    token0Price
    token1Price
    createdAtTimestamp
  }
`

/**
 * 代币字段
 */
const TokenFields = `
  fragment TokenFields on Token {
    id
    name
    symbol
    derivedETH
    tradeVolume
    tradeVolumeUSD
    untrackedVolumeUSD
    totalLiquidity
    txCount
  }
`

export class GraphCommand {
  /**
   * 查询所有交易对列表
   */
  public static queryPairs = (): DocumentNode => gql`
    query pairs {
      pairs(first: 200, orderBy: trackedReserveETH, orderDirection: desc) {
        id
      }
    }
  `

  /**
   * 批量查询交易对数据
   */
  public static bulkQueryPairs = (): DocumentNode => gql`
    ${PairFields}
    query pairs($allPairs: [Bytes]!) {
      pairs(where: { id_in: $allPairs }, orderBy: trackedReserveETH, orderDirection: desc) {
        ...PairFields
      }
    }
  `

  /**
   * 查询交易对图表信息
   */
  public static queryPairChart = (): DocumentNode => gql`
    query pairDayDatas($pairAddress: Bytes!, $skip: Int!) {
      pairDayDatas(first: 1000, skip: $skip, orderBy: date, orderDirection: asc, where: { pairAddress: $pairAddress }) {
        id
        date
        dailyVolumeToken0
        dailyVolumeToken1
        dailyVolumeUSD
        reserveUSD
      }
    }
  `

  /**
   * 查询交易对交易记录
   */
  public static queryPairTransactions = (): DocumentNode => gql`
    query($allPairs: [Bytes]!) {
      mints(first: 20, where: { pair_in: $allPairs }, orderBy: timestamp, orderDirection: desc) {
        transaction {
          id
          timestamp
        }
        pair {
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
        }
        to
        liquidity
        amount0
        amount1
        amountUSD
      }
      burns(first: 20, where: { pair_in: $allPairs }, orderBy: timestamp, orderDirection: desc) {
        transaction {
          id
          timestamp
        }
        pair {
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
        }
        sender
        liquidity
        amount0
        amount1
        amountUSD
      }
      swaps(first: 30, where: { pair_in: $allPairs }, orderBy: timestamp, orderDirection: desc) {
        transaction {
          id
          timestamp
        }
        id
        pair {
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
        }
        amount0In
        amount0Out
        amount1In
        amount1Out
        amountUSD
        to
      }
    }
  `

  /**
   * 查询交易对信息
   * @param address
   * @param block
   */
  public static queryPairData(address: string, block: number): DocumentNode {
    const queryString = `
    ${PairFields}
    query pairs {
      pairs(${block ? `block: {number: ${block}}` : ``} where: { id: "${address}"} ) {
        ...PairFields
      }
    }`
    return gql(queryString)
  }

  /**
   * 批量查询交易对历史数据
   * @param pairs: 交易对列表
   * @param block: 区块高度
   */
  public static bulkQueryHistoryPairData(pairs: string[], block: number): DocumentNode {
    let pairsString = `[`
    pairs.map(pair => {
      return (pairsString += `"${pair}"`)
    })
    pairsString += ']'
    const queryString = `
      query pairs {
        pairs(first: 200, where: {id_in: ${pairsString}}, block: {number: ${block}}, orderBy: trackedReserveETH, orderDirection: desc) {
          id
          reserveUSD
          trackedReserveETH
          volumeUSD
          untrackedVolumeUSD
        }
      }
    `
    return gql(queryString)
  }

  /**
   * 查询交易对对流动行性数据
   */
  public static queryPairLiquidity(): DocumentNode {
    return gql`
      query lps($pair: Bytes!) {
        liquidityPositions(where: { pair: $pair }, orderBy: liquidityTokenBalance, orderDirection: desc, first: 10) {
          user {
            id
          }
          pair {
            id
          }
          liquidityTokenBalance
        }
      }
    `
  }

  /**
   * 查询所有代币信息
   */
  public static queryTokens(): DocumentNode {
    return gql`
      ${TokenFields}
      query tokens {
        tokens(first: 200, orderBy: tradeVolumeUSD, orderDirection: desc) {
          ...TokenFields
        }
      }
    `
  }

  /**
   * 查询历史 TokenData
   * @param block
   */
  public static queryHistoryTokenData(block: number): DocumentNode {
    const queryString = `
      ${TokenFields}
      query tokens {
        tokens(block: {number: ${block}} first: 200, orderBy: tradeVolumeUSD, orderDirection: desc) {
          ...TokenFields
        }
      }
    `
    return gql(queryString)
  }

  public static queryTokenDataWithTime = (tokenAddress: string, block: number) => {
    const queryString = `
      ${TokenFields}
      query tokens {
        tokens(${block ? `block : {number: ${block}}` : ``} where: {id:"${tokenAddress}"}) {
          ...TokenFields
        }
        pairs0: pairs(where: {token0: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
          id
        }
        pairs1: pairs(where: {token1: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
          id
        }
      }
    `
    return gql(queryString)
  }

  public static queryTokenDataByBlock(block: number): DocumentNode {
    const queryString = `
      query tokens {
        tokens(block: {number: ${block}} first: 200, orderBy: tradeVolumeUSD, orderDirection: desc) {
          id
          name
          symbol
          derivedETH
          tradeVolume
          tradeVolumeUSD
          untrackedVolumeUSD
          totalLiquidity
          txCount
        }
      }
    `
    return gql(queryString)
  }

  public static queryTokenChart = ()=> gql`
  query tokenDayDatas($tokenAddr: String!, $skip: Int!) {
    tokenDayDatas(first: 1000, skip: $skip, orderBy: date, orderDirection: asc, where: { token: $tokenAddr }) {
      id
      date
      priceUSD
      totalLiquidityToken
      totalLiquidityUSD
      totalLiquidityETH
      dailyVolumeETH
      dailyVolumeToken
      dailyVolumeUSD
      mostLiquidPairs {
        id
        token0 {
          id
          derivedETH
        }
        token1 {
          id
          derivedETH
        }
      }
    }
  }
`


public static pricesByBlock (tokenAddress:string, blocks:any):DocumentNode {
  let queryString = 'query blocks {'
  queryString += blocks.map(
    (block:any) => `
      t${block.timestamp}:token(id:"${tokenAddress}", block: { number: ${block.number} }) { 
        derivedETH
      }
    `
  )
  queryString += ','
  queryString += blocks.map(
    (block:any) => `
      b${block.timestamp}: bundle(id:"1", block: { number: ${block.number} }) { 
        ethPrice
      }
    `
  )

  queryString += '}'
  return gql(queryString)
}

public static queryTokenTransactions = () => gql`
  query($allPairs: [Bytes]!) {
    mints(first: 20, where: { pair_in: $allPairs }, orderBy: timestamp, orderDirection: desc) {
      transaction {
        id
        timestamp
      }
      pair {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      to
      liquidity
      amount0
      amount1
      amountUSD
    }
    burns(first: 20, where: { pair_in: $allPairs }, orderBy: timestamp, orderDirection: desc) {
      transaction {
        id
        timestamp
      }
      pair {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      sender
      liquidity
      amount0
      amount1
      amountUSD
    }
    swaps(first: 30, where: { pair_in: $allPairs }, orderBy: timestamp, orderDirection: desc) {
      transaction {
        id
        timestamp
      }
      id
      pair {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
      }
      amount0In
      amount0Out
      amount1In
      amount1Out
      amountUSD
      to
    }
  }
`
  /**
   * 查询 Factory 合约的交易数据信息
   * @param block
   */
  public static queryFactoryData(block?: number): DocumentNode {
    const factory = '0xf028f723ed1d0fe01cc59973c49298aa95c57472' // Todo: zbinnny factory address
    const queryString = ` query uniswapFactories {
      uniswapFactories(
       ${block ? `block: { number: ${block}}` : ``} 
       where: { id: "${factory}" }) {
        id
        totalVolumeUSD
        totalVolumeETH
        untrackedVolumeUSD
        totalLiquidityUSD
        totalLiquidityETH
        txCount
        pairCount
      }
    }`
    return gql(queryString)
  }

  public static queryEthPrice(block?: number): DocumentNode {
    const queryString = block
      ? `
          query bundles {
            bundles(where: { id: ${BUNDLE_ID} } block: {number: ${block}}) {
              id
              ethPrice
            }
          }
        `
      : ` query bundles {
            bundles(where: { id: ${BUNDLE_ID} }) {
              id
              ethPrice
            }
          }
        `
    return gql(queryString)
  }

  /**
   * 全局图表数据: 成交量，流动性
   */
  public static queryGlobalChart(): DocumentNode {
    return gql`
      query uniswapDayDatas($startTime: Int!, $skip: Int!) {
        uniswapDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
          id
          date
          totalVolumeUSD
          dailyVolumeUSD
          dailyVolumeETH
          totalLiquidityUSD
          totalLiquidityETH
        }
      }
    `
  }

  public static queryLending(block?:number):DocumentNode {
    return gql`
    {
      markets(first: 1000,block: {number: ${block}}) {
        borrowRate
        cash
        supplyRate
        totalBorrows
        totalSupply
        exchangeRate
        underlyingPriceUSD
        underlyingSymbol
        sashimiSpeed
        volumeUSD
        accrueInterest
      }
    }
    `
  }

  public static getPriceByBlock(tokenAddresses:string,block?:number) {
    return gql`
      {
        token(id:"${tokenAddresses}", block: { number: ${block} }) {
          derivedETH
        }
        bundle(id:"1", block: { number: ${block} }) {
          ethPrice
        }
      }
    `
  }

  public static queryHourlyPairRates(
    pairAddress: string,
    blocks: { number: number; timestamp: number }[]
  ): DocumentNode {
    let queryString = 'query blocks {'
    queryString += blocks.map(
      block => `
      t${block.timestamp}: pair(id:"${pairAddress}", block: { number: ${block.number} }) { 
        token0Price
        token1Price
      }
    `
    )
    queryString += '}'
    return gql(queryString)
  }
}

export class BlockCommand {
  /**
   * 根据时间戳列表查询区块数据
   * @param timestamps
   */
  public static queryBlockByTimestamps(timestamps: number[]): DocumentNode {
    let queryString = 'query blocks {'
    queryString += timestamps.map(timestamp => {
      return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${timestamp +
        600} }) {
      number
    }`
    })
    queryString += '}'
    return gql(queryString)
  }
}

export class HealthCommand {
  public static queryHealth(): DocumentNode {
    return gql`
      query health {
        indexingStatusForCurrentVersion(subgraphName: "sashimiproject/sashimi") {
          synced
          health
          chains {
            chainHeadBlock {
              number
            }
            latestBlock {
              number
            }
          }
        }
      }
    `
  }
}
// export const SUBGRAPH_HEALTH = gql`
//   query health {
//     indexingStatusForCurrentVersion(subgraphName: "ianlapham/uniswapv2") {
//       synced
//       health
//       chains {
//         chainHeadBlock {
//           number
//         }
//         latestBlock {
//           number
//         }
//       }
//     }
//   }
// `