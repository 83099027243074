/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs'
import { GraphCommand, SubgraphClient as client, LendingClient } from '../../api/apollo'
import ExchangeUtils from '../../fuck/exchangeUtils'
import { getBlocksFromTimestamps } from '../common/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { AppDispatch, AppState } from '../index'
import { initialGlobalChartData, initialGlobalStatisticsData, initialTVLChartData } from './actions'
import { useInvestmentContract } from '../../hooks/useContract'
import { Contract, getDefaultProvider } from 'ethers'
import BigNumber from 'bignumber.js'
import { PairData } from '../exchange/entities/pair_entity'
import { useBlockNumber } from '../application/hooks'

import { svDAI, svUSDC, svUSDT, sashimiBar } from './useContract'
import api from '../../api'
const SAADDRESS = '0xc28e27870558cf22add83540d2126da2e4b464c2'
const SABARADDRESS = '0x6ed306DbA10E6c6B20BBa693892Fac21f3B91977'
dayjs.extend(utc)
dayjs.extend(weekOfYear)

/**
 * 当前 Ether 的价格
 */
export function useEtherPrice(): AppState['global']['etherPrice'] {
  return useSelector<AppState, AppState['global']['etherPrice']>(state => state.global.etherPrice)
}

/**
 * Gets the current price  of ETH, 24 hour price, and % change between them
 */
export async function getEthPrice(): Promise<{
  now: number
  old: number
  change: number
}> {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime
    .subtract(1, 'day')
    .startOf('minute')
    .unix()

  let ethPrice = 0
  let ethPriceOneDay = 0
  let priceChangeETH = 0

  try {
    const [{ number: oneDayBlock }] = await getBlocksFromTimestamps([utcOneDayBack])

    const result = await client.query({
      query: GraphCommand.queryEthPrice(),
      fetchPolicy: 'cache-first'
    })
    const resultOneDay = await client.query({
      query: GraphCommand.queryEthPrice(oneDayBlock),
      fetchPolicy: 'cache-first'
    })
    const currentPrice = result?.data?.bundles[0]?.ethPrice ?? '0'
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPrice ?? '0'
    priceChangeETH = ExchangeUtils.getPercentChange(currentPrice, oneDayBackPrice)
    ethPrice = parseFloat(currentPrice)
    ethPriceOneDay = parseFloat(oneDayBackPrice)
  } catch (e) {
    console.log(e)
  }

  return {
    now: ethPrice,
    old: ethPriceOneDay,
    change: priceChangeETH
  }
}

/**
 * Gets all the global data for the overview page.
 * Needs current eth price and the old eth price to get
 * 24 hour USD changes.
 * @param {*} ethPrice
 * @param {*} oldEthPrice
 */
async function getGlobalData(ethPrice: number, oldEthPrice: number): Promise<any> {
  // data for each day , historic data used for % changes
  let data: any = {}
  let oneDayData: any = {}
  let twoDayData: any = {}

  try {
    // get timestamps for the days
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix()
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix()
    const utcTwoWeeksBack = utcCurrentTime.subtract(2, 'week').unix()

    // get the blocks needed for time travel queries
    const [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] = await getBlocksFromTimestamps([
      utcOneDayBack,
      utcTwoDaysBack,
      utcOneWeekBack,
      utcTwoWeeksBack
    ])

    // fetch the global data
    const result = await client.query({
      query: GraphCommand.queryFactoryData(),
      fetchPolicy: 'cache-first'
    })
    data = result.data.uniswapFactories[0]

    // fetch the historical data
    const oneDayResult = await client.query({
      query: GraphCommand.queryFactoryData(oneDayBlock?.number),
      fetchPolicy: 'cache-first'
    })
    oneDayData = oneDayResult.data.uniswapFactories[0]

    const twoDayResult = await client.query({
      query: GraphCommand.queryFactoryData(twoDayBlock?.number),
      fetchPolicy: 'cache-first'
    })
    twoDayData = twoDayResult.data.uniswapFactories[0]

    const oneWeekResult = await client.query({
      query: GraphCommand.queryFactoryData(oneWeekBlock?.number),
      fetchPolicy: 'cache-first'
    })
    const oneWeekData = oneWeekResult.data.uniswapFactories[0]

    const twoWeekResult = await client.query({
      query: GraphCommand.queryFactoryData(twoWeekBlock?.number),
      fetchPolicy: 'cache-first'
    })
    const twoWeekData = twoWeekResult.data.uniswapFactories[0]
    if (data) {
      data.oneDayTxns = data.txCount
    }
    if (data && oneDayData && twoDayData) {
      const [oneDayVolumeUSD, volumeChangeUSD] = ExchangeUtils.get2DayPercentChange(
        data.totalVolumeUSD,
        oneDayData.totalVolumeUSD ? oneDayData.totalVolumeUSD : 0,
        twoDayData.totalVolumeUSD ? twoDayData.totalVolumeUSD : 0
      )

      const [oneDayTxns, txnChange] = ExchangeUtils.get2DayPercentChange(
        data.txCount,
        oneDayData.txCount ? oneDayData.txCount : 0,
        twoDayData.txCount ? twoDayData.txCount : 0
      )

      // format the total liquidity in USD
      data.totalLiquidityUSD = data.totalLiquidityETH * ethPrice
      const liquidityChangeUSD = ExchangeUtils.getPercentChange(
        data.totalLiquidityETH * ethPrice,
        oneDayData.totalLiquidityETH * oldEthPrice
      )

      // add relevant fields with the calculated amounts
      data.oneDayVolumeUSD = oneDayVolumeUSD
      data.volumeChangeUSD = volumeChangeUSD
      data.liquidityChangeUSD = liquidityChangeUSD
      data.oneDayTxns = oneDayTxns
      data.txnChange = txnChange
    }
    if (data && twoWeekData) {
      const [oneWeekVolume, weeklyVolumeChange] = ExchangeUtils.get2DayPercentChange(
        data.totalVolumeUSD,
        oneWeekData.totalVolumeUSD,
        twoWeekData.totalVolumeUSD
      )
      data.oneWeekVolume = oneWeekVolume
      data.weeklyVolumeChange = weeklyVolumeChange
    }
  } catch (e) {
    console.log(e)
  }

  return data
}

/**
 * 图表数据周期类型
 */
export enum ChartPeriod {
  WEEK,
  MONTH,
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME
}

/**
 * 图表数据开始时间
 * @param timeWindow
 */
export function getTimeframe(timeWindow: any): number {
  const utcEndTime = dayjs.utc()
  // based on window, get starttime
  let utcStartTime: number
  switch (timeWindow) {
    case ChartPeriod.WEEK:
      utcStartTime =
        utcEndTime
          .subtract(1, 'week')
          .endOf('day')
          .unix() - 1
      break
    case ChartPeriod.MONTH:
      utcStartTime =
        utcEndTime
          .subtract(1, 'month')
          .endOf('day')
          .unix() - 1
      break
    case ChartPeriod.ALL_TIME:
      utcStartTime =
        utcEndTime
          .subtract(1, 'year')
          .endOf('day')
          .unix() - 1
      break
    default:
      utcStartTime =
        utcEndTime
          .subtract(1, 'year')
          .startOf('year')
          .unix() - 1
      break
  }
  return utcStartTime
}

async function getBlockNumber(timestamp?: number) {
  const timestampArr = []
  if (!timestamp) return
  timestampArr.push(timestamp)
  const [data] = await getBlocksFromTimestamps(timestampArr)
  return data.number ?? 0
}

async function getVaultTVL(block?: number) {
  if (!block) return new BigNumber(0)

  const [dai, usdc, usdt] = await Promise.all([
    svDAI.balance({
      blockTag: +block
    }),
    svUSDC.balance({
      blockTag: +block
    }),
    svUSDT.balance({
      blockTag: +block
    })
  ])
    .then(res => res.map(v => new BigNumber(v.toString())))
    .catch(e => {
      // console.error(e, 'vaultData balanceSVE')
      return [new BigNumber(0), new BigNumber(0), new BigNumber(0)]
    })

  const TVL = dai
    .dividedBy(1e18)
    .plus(usdc.dividedBy(1e6))
    .plus(usdt.dividedBy(1e6))
  return TVL
}

async function getSashimiBarTVL(block?: number) {
  if (!block) return new BigNumber(0)
  try {
    const balance = await sashimiBar.balanceOf(SABARADDRESS, {
      blockTag: +block
    })

    return new BigNumber(balance.toString()).dividedBy(1e18)
  } catch (e) {
    return new BigNumber(0)
  }
}

/**
 * lendingData
 */
async function getLendingData(block?: number) {
  if (!block) return new BigNumber(0)
  const { data } = await LendingClient.query({
    query: GraphCommand.queryLending(block),
    fetchPolicy: 'cache-first'
  })
  let lendingTotal = new BigNumber(0)
  if (!data.markets[data.markets.length - 1]) return lendingTotal
  const { exchangeRate, totalSupply, underlyingPriceUSD } = data.markets[data.markets.length - 1]
  if (exchangeRate && totalSupply && underlyingPriceUSD) {
    lendingTotal = new BigNumber(exchangeRate).times(totalSupply).times(underlyingPriceUSD)
  }

  return lendingTotal
}

async function getTokenPrices(addresses: string, block?: number) {
  const { data } = await client.query({
    query: GraphCommand.getPriceByBlock(addresses, block),
    fetchPolicy: 'cache-first'
  })
  return {
    tokenPrices: new BigNumber(data.token.derivedETH),
    ethPriceInUSD: new BigNumber(data.bundle.ethPrice)
  }
}

async function getSashimiPriceByTime(block?: number) {
  if (!block) return 0
  const { tokenPrices, ethPriceInUSD } = await getTokenPrices(SAADDRESS, block)
  return ethPriceInUSD.times(tokenPrices)
}

/**
 * 全局图表数据
 */
async function getGlobalChartData(oldestDateToFetch: number) {
  let data: any = []
  const weeklyData: any = []
  const utcEndTime = dayjs.utc()
  let skip = 0
  let allFound = false

  try {
    while (!allFound) {
      const result = await client.query({
        query: GraphCommand.queryGlobalChart(),
        variables: {
          startTime: oldestDateToFetch,
          skip
        },
        fetchPolicy: 'cache-first'
      })
      skip += 1000
      data = data.concat(result.data.uniswapDayDatas)
      if (result.data.uniswapDayDatas.length < 1000) {
        allFound = true
      }
    }

    if (data) {
      const dayIndexSet = new Set()
      const dayIndexArray: any = []
      const oneDay = 24 * 60 * 60

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData: any, i: number) => {
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0))
        dayIndexArray.push(data[i])
        dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD)
      })

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? data[0].date : oldestDateToFetch
      let latestLiquidityUSD = data[0].totalLiquidityUSD
      let latestDayDats = data[0].mostLiquidTokens
      let index = 1
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay
        const currentDayIndex = (nextDay / oneDay).toFixed(0)
        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats
          })
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD
          latestDayDats = dayIndexArray[index].mostLiquidTokens
          index = index + 1
        }
        timestamp = nextDay
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a: any, b: any) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1))
    let startIndexWeekly = -1
    let currentWeek = -1
    data = await Promise.all(
      data.map(async (entry: any, i: number) => {
        const week = dayjs.utc(dayjs.unix(data[i].date)).week()
        if (week !== currentWeek) {
          currentWeek = week
          startIndexWeekly++
        }
        weeklyData[startIndexWeekly] = weeklyData[startIndexWeekly] || {}
        weeklyData[startIndexWeekly].date = data[i].date
        weeklyData[startIndexWeekly].weeklyVolumeUSD =
          (weeklyData[startIndexWeekly].weeklyVolumeUSD ?? 0) + data[i].dailyVolumeUSD

        // const block = await getBlockNumber(data[i].date)
        // const lendingTotal = await getLendingData(block)
        // const TVL = await getVaultTVL(block)
        // const sashimiBar = await getSashimiBarTVL(block)
        // const ethPrice = await getSashimiPriceByTime(block)
        // const vaultAndBar = TVL.plus(sashimiBar).times(ethPrice)
        // entry.totalValueLocked = lendingTotal
        //   .plus(vaultAndBar)
        //   .plus(entry.totalLiquidityUSD)
        //   .toFixed(4)
        //   entry.lendingLocked = lendingTotal.toFixed()
        // entry.vaultLocked = TVL.times(ethPrice).toFixed()
        // entry.blockNumber = block
        // entry.ethPrice = ethPrice.toFixed()
        // entry.sashimiBar = sashimiBar.times(ethPrice).toFixed(6)
        return entry
      })
    )
  } catch (e) {
    console.log(e)
  }
  // const TVLChange = ExchangeUtils.getPercentChange(
  //   data[data.length-1].totalValueLocked,
  //   data[data.length-2].totalValueLocked
  // )
  return [data, weeklyData] //,TVLChange]
}
interface APIPros {
  [key: string]: any
}
/**
 * 获取全局数据
 */
export function useFetchGlobalDataCallback(): () => Promise<{
  statistics?: any
  chartData?: any
}> {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(async () => {
    const API: APIPros = api
    API?.farms?.getTVLChartData({
      data: {
        ProjectId: '1f79502b-4b6e-4d07-f9d3-39fb27eefe06',
        SnapshotKeys: 'SummarySnapshot_TotalValueLocked_USD'
      },
      success: (result: any) => {
        let TVLDATA = result?.snapshots?.SummarySnapshot_TotalValueLocked_USD ?? []
        TVLDATA = TVLDATA.map((item: any) => {
          return {
            date: parseInt(`${item?.timestamp / 1000}`),
            totalValueLocked: item?.value
          }
        })
        const len = TVLDATA.length;
        if(new Date(TVLDATA[len-1].date).getDate() === new Date(TVLDATA[len-2].date).getDate()) {
          TVLDATA.splice(len-2,1)
        }
        const TVLChange = ExchangeUtils.getPercentChange(
          TVLDATA[TVLDATA.length - 1].totalValueLocked,
          TVLDATA[TVLDATA.length - 2].totalValueLocked
        )
        dispatch(initialTVLChartData([TVLDATA, TVLChange]))
      }
    })

    const ethPriceMap = await getEthPrice()

    const time: number = getTimeframe(ChartPeriod.ALL_TIME)
    if (!ethPriceMap) return {}

    const [statistics, chartData] = await Promise.all([
      getGlobalData(ethPriceMap.now, ethPriceMap.old),
      getGlobalChartData(time)
    ])

    dispatch(initialGlobalStatisticsData(statistics))
    dispatch(initialGlobalChartData(chartData))

    return {
      statistics: statistics,
      chartData: chartData
    }
  }, [dispatch])
}

/**
 * 全局统计数据
 */
export function useGlobalStatisticsData(): any {
  const statistics = useSelector((state: AppState) => state.global.statistics)
  return useMemo(() => {
    return statistics
  }, [statistics])
}

/**
 * 全局图表数据
 */
export function useGlobalChartData(): any[] {
  const chart = useSelector((state: AppState) => state.global.chartData)
  return useMemo(() => {
    return chart
  }, [chart])
}
/**
 * 获取TVL图表
 */
export function useTVLChartData(): any[] {
  const chart = useSelector((state: AppState) => state.global.TVLChartData)
  return useMemo(() => {
    return chart
  }, [chart])
}
/**
 * 获取一天之前的区块号
 */
async function getBlockNumberOfOneDay(): Promise<{
  number: number
  timestamp: string
}> {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix()
  const [blockNumber] = await getBlocksFromTimestamps([utcOneDayBack])
  return blockNumber
}

/**
 * 投资收益
 */
export function useVaultIncomeOf24HByContract(): BigNumber {
  const [income, setIncome] = useState<BigNumber>(new BigNumber(0))

  // 获取合约
  const vaultContract: Contract | null = useInvestmentContract()

  // 获取一天之前的区块
  useEffect(() => {
    async function queryIncome() {
      if (!vaultContract) return

      const block = await vaultContract.provider.getBlockNumber()
      const oneDayAgoBlock = block - Math.floor((24 * 60 * 60) / 14) // 大概每天产生的块

      const event = await vaultContract.interface.getEvent('Harvested')
      const topics: string = vaultContract.interface.getEventTopic(event)
      const historyEvents = await vaultContract.queryFilter(
        {
          address: vaultContract.address,
          topics: [topics]
        },
        oneDayAgoBlock
      )

      let incomeResult: BigNumber = new BigNumber(0)
      historyEvents.forEach(event => {
        const log = vaultContract.interface.parseLog(event)

        if (log.args?.[2]) {
          incomeResult = incomeResult.plus(new BigNumber(log.args[2].toNumber()))
        }
      })
      setIncome(incomeResult)
    }

    queryIncome().catch(console.log)
  }, [vaultContract, setIncome])

  return income
}

export function usePairs(): PairData[] {
  const pairs = useSelector<AppState, AppState['global']['pairs']>(state => state.global.pairs)
  return useMemo(() => {
    return Object.values(pairs).sort((a, b) => {
      const aVolume: number = parseFloat(a.reserveUSD ?? '0') ?? 0
      const bVolume: number = parseFloat(b.reserveUSD ?? '0') ?? 0
      return aVolume < bVolume ? 1 : -1
    })
  }, [pairs])
}

export function usePairByAddress(pairAddress: string): PairData {
  return useSelector((state: AppState) => state.global.pairs[pairAddress])
}
